import { useColorMode } from 'native-base'
import { ColorType } from 'native-base/lib/typescript/components/types'

const reverseContrast = {
  '50': '900',
  '100': '800',
  '200': '700',
  '300': '600',
  '400': '500',
  '500': '400',
  '600': '300',
  '700': '200',
  '800': '100',
  '900': '50',
}

export default function useResponsiveColor(color: ColorType) {
  const { colorMode } = useColorMode()
  if (!color) return color

  const parts = (color as string).match(/^(\w+)(?:\.(\d+)(:alpha\.\d+)?)?(\$)?$/) || []
  let [_, scheme, contrast = '900', alpha = '', isResponsive] = parts

  if (colorMode === 'dark' /*&& isResponsive*/) {
    contrast = reverseContrast[contrast as keyof typeof reverseContrast]
  }
  return !!scheme ? `${scheme}.${contrast}${alpha}` : color
}
