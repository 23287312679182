import { Maybe } from "app/types"
import { useAuditResourceService } from "../audit/service"
import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { BankConnection, BankConnectionApi, GuestBankConnectionApi } from "./model"

export interface CabbageListener {
  token: Maybe<string>
  startConnection: () => void
  connectError: Maybe<{ code: string, message: string }>
  ready: boolean
}

export default function useCabbagePayListener(
  api: BankConnectionApi | GuestBankConnectionApi,
  bankConnection: Maybe<BankConnection>,
  onUpdate: (bankConnection: BankConnection) => void): CabbageListener {
  const [token, setToken] = useState<Maybe<string>>(null)
  const [publicToken, setPublicToken] = useState<Maybe<string>>(null)
  const [connectError, setConnectError] = useState<Maybe<{ code: string, message: string }>>(null)
  const auditResourceAPI = useAuditResourceService()
  const [version, setVersion] = useState(1)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  // Step 1 - Init Tender get Token & Init CabbagePay
  useEffect(() => {
    if (bankConnection) {
      api.initTender(bankConnection).then(res => {
        setToken(res.data.token)
        // if (bankConnection.has_token) {
        //   api.cabbagePay.initializeFixGrid(res.data.token)
        // } else {
        //   api.cabbagePay.initializeGrid(res.data.token)
        // }
        api.cabbagePay.initializeGrid(res.data.token)
        setReady(true)
      })
    }
  }, [bankConnection, version])

  // Step 2 - Start connection
  const startConnection = () => {
    if (ready) {
      // if (bankConnection?.has_token) {
      //   api.cabbagePay.openFixGrid(token as string)
      // } else {
      //   api.cabbagePay.openGrid(token as string)
      // }
      api.cabbagePay.openGrid(token as string)
    }
  }


  // Step 3 - Get result and save public token
  const onMessage = (event: MessageEvent<any>) => {
    try {
      const payload = JSON.parse(event.data)
      if (payload.message == 'success') {
        setPublicToken(payload.public_token)
        setReady(false)
        if (auditResourceAPI)
          auditResourceAPI.createLog("create public token", "Create a new public token")
      } else {
        setConnectError(payload)
      }
    } catch (err) {
    }
  }

  // Step 4 -> Save public token on backend or show connection error
  useEffect(() => {
    if (publicToken) {
      api.saveToken(bankConnection as BankConnection, publicToken).then((bc: AxiosResponse<BankConnection>) => {
        if (auditResourceAPI)
          auditResourceAPI.createLogWithObj("save public token", bc.data, bankConnection)
        if (bankConnection?.has_token)
          api.cabbagePay.closeFixGrid()
        else
          api.cabbagePay.closeGrid()
        setReady(false)
        onUpdate(bc.data)
        setVersion(version + 1)
      })
    }
    if (connectError) {
      // if (bankConnection?.has_token)
      //   api.cabbagePay.closeFixGrid()
      // else
      //   api.cabbagePay.closeGrid()
      api.cabbagePay.closeGrid()
      setReady(false)
      setVersion(version + 1)
    }
  }, [connectError, publicToken])

  return { token, startConnection, connectError, ready }
}

