import React, { useEffect, useRef } from 'react'
import { Button, IButtonProps as BaseProps } from 'native-base'
import { Link, useResolvedPath, useMatch, useNavigate } from '../../router'
import { View } from 'react-native'

type Props = BaseProps & {
  _active?: BaseProps
  onActivate?: (x: number, y: number, width: number, height: number, pageX: number, pageY: number) => void
  to: string
  exactMatch?: boolean
}

// TODO: Replace the hack using useNavigate and inspect more why Link > Button does not navigates.
export default function LinkButton({ to, _active = {}, onActivate, exactMatch = false, ...props }: Props): JSX.Element {
  const ref = useRef<View>()
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: exactMatch })
  const navigate = useNavigate()

  useEffect(() => {
    if (match && typeof onActivate === 'function' && ref.current) {
      ref.current.measure(onActivate)
    }
  }, [match])

  const activeProps = match ? _active : {}

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Button {...props} {...activeProps} ref={ref} onPress={() => navigate(to)} />
    </Link>
  )
}
