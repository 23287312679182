import { useBreakpointValue } from 'native-base'
import { Breakpoint } from '../types'
import useDimensions from './use-dimensions'

// export default function useBreakpoint(): Breakpoint {
//   const { width } = useDimensions()

//   return width < 768 ? 'phone' : width < 992 ? 'tablet' : 'desktop'
// }

export default function useBreakpoint(): Breakpoint {
  return useBreakpointValue({
    base: 'phone',
    md: 'tablet',
    lg: 'desktop',
  })
}
