import { VictoryThemeDefinition } from 'victory-core'
import { palette2, palette2 as palette } from './palette'

// *
// * Colors
// *
export const colors = [
  '#875FC0',
  '#FFB82C',
  '#EC4887',
  '#38B2AC',
  '#ff6e54',
  '#ffa600',
  palette.teal['500'],
  palette.cyan['700'],
  palette.orange['600'],
  palette.emerald['600'],
  palette.red['600'],
  palette.pink['600'],
  '#875FC0',
  '#FFB82C',
  '#EC4887',
  '#38B2AC',
  '#ff6e54',
  '#ffa600',
]
const blueGrey50 = '#ECEFF1'
const blueGrey300 = '#90A4AE'
const blueGrey700 = '#455A64'
const grey900 = '#212121'

const ghostStroke = {
  stroke: 'black',
  strokeWidth: 0.5,
  strokeOpacity: 0.3,
}
// *
// * Typography
// *
const sansSerif = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`
const letterSpacing = 'normal'
const fontSize = 14
// *
// * Layout
// *
const padding = 8
const baseProps = {
  width: 350,
  height: 350,
  padding: 50,
}
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding,
  stroke: 'transparent',
  strokeWidth: 0,
  fill: palette.blueGray['500'],
}

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: 'middle' }
// *
// * Strokes
// *
const strokeDasharray = '10, 5'
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export const getVictoryTheme = (dark: boolean): VictoryThemeDefinition => {
  return {
    area: {
      ...baseProps,
      style: {
        data: {
          fill: grey900,
        },
        labels: baseLabelStyles,
      },
    },
    axis: {
      ...baseProps,
      style: {
        axis: {
          fill: 'transparent',
          stroke: palette.blueGray['400'],
          strokeWidth: 2,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: {
          ...centeredLabelStyles,
          padding,
          stroke: 'transparent',
        },
        grid: {
          fill: 'none',
          stroke: palette.blueGray['200'],
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted',
        },
        ticks: {
          fill: 'transparent',
          size: 5,
          stroke: blueGrey300,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        tickLabels: {
          ...baseLabelStyles,
          fill: palette.blueGray['500'],
          fontSize: 13,
        },
      },
    },
    polarDependentAxis: {
      style: {
        ticks: {
          fill: 'transparent',
          size: 1,
          stroke: 'transparent',
        },
      },
    },
    bar: {
      ...baseProps,
      style: {
        data: {
          fill: colors[0],
          padding,
          ...ghostStroke,
        },
        labels: baseLabelStyles,
      },
    },
    boxplot: {
      ...baseProps,
      style: {
        max: { padding, stroke: blueGrey700, strokeWidth: 1 },
        maxLabels: { ...baseLabelStyles, padding: 3 },
        median: { padding, stroke: blueGrey700, strokeWidth: 1 },
        medianLabels: { ...baseLabelStyles, padding: 3 },
        min: { padding, stroke: blueGrey700, strokeWidth: 1 },
        minLabels: { ...baseLabelStyles, padding: 3 },
        q1: { padding, fill: blueGrey700 },
        q1Labels: { ...baseLabelStyles, padding: 3 },
        q3: { padding, fill: blueGrey700 },
        q3Labels: { ...baseLabelStyles, padding: 3 },
      },
      boxWidth: 20,
    },
    candlestick: {
      ...baseProps,
      style: {
        data: {
          stroke: blueGrey700,
        },
        labels: { ...baseLabelStyles, padding: 5 },
      },
      candleColors: {
        positive: '#ffffff',
        negative: blueGrey700,
      },
    },
    chart: baseProps,
    errorbar: {
      ...baseProps,
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    group: {
      ...baseProps,
      colorScale: colors,
    },
    histogram: {
      ...baseProps,
      style: {
        data: {
          fill: blueGrey700,
          stroke: grey900,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    legend: {
      colorScale: colors,
      gutter: 10,
      orientation: 'vertical',
      titleOrientation: 'top',
      style: {
        data: {
          type: 'circle',
        },
        labels: baseLabelStyles,
        title: { ...baseLabelStyles, padding: 5 },
      },
    },
    line: {
      ...baseProps,
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    pie: {
      ...baseProps,
      colorScale: colors,
      style: {
        data: {
          padding,
          ...ghostStroke,
        },
        labels: { ...baseLabelStyles, padding: 20 },
      },
    },
    scatter: {
      ...baseProps,
      style: {
        data: {
          fill: blueGrey700,
          opacity: 1,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    stack: {
      ...baseProps,
      colorScale: colors,
    },
    tooltip: {
      style: {
        ...baseLabelStyles,
        fill: dark ? palette.coolGray['800'] : palette.coolGray['50'],
        fontSize: 13,
        // padding: 0,
        pointerEvents: 'none',
      },
      flyoutStyle: {
        fill: dark ? palette.coolGray['50'] : palette.coolGray['700'],
        strokeWidth: 0,
        stroke: 'none',
        pointerEvents: 'none',
      },
      flyoutPadding: 5,
      cornerRadius: 3,
      pointerLength: 7,
    },
    voronoi: {
      ...baseProps,
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: {
          ...baseLabelStyles,
          padding: 5,
          pointerEvents: 'none',
        },
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none',
        },
      },
    },
  }
}

const theme = getVictoryTheme(false)

export default theme
