import { InterfaceBadgeProps } from 'native-base/lib/typescript/components/composites/Badge/types'
import { InterfaceButtonProps } from 'native-base/src/components/primitives/Button/types'
import { getColorScheme } from 'native-base/src/theme/tools'

export const Button = {
  baseStyle: {
    _web: {
      transition: 'all .1s ease-in-out',
    },
  },
  variants: {
    clean: ({ colorScheme }: InterfaceButtonProps) => {
      return {
        bg: `transparent`,
        _text: {
          color: `${colorScheme}.700`,
          fontWeight: 'semibold',
        },
        _icon: {
          color: `${colorScheme}.500`,
        },
        _spinner: {
          color: `${colorScheme}.800`,
        },
        _hover: {
          bg: `${colorScheme}.50`,
        },
        _pressed: {
          bg: `${colorScheme}.100`,
        },

        _dark: {
          bg: `transparent`,
          _text: {
            color: `${colorScheme}.300`,
          },
          _icon: {
            color: `${colorScheme}.400`,
          },
          _hover: {
            bg: `${colorScheme}.900`,
          },
          _pressed: {
            bg: `${colorScheme}.700`,
          },
        },
      }
    },
    subtle: ({ colorScheme }: InterfaceButtonProps) => ({
      bg: `${colorScheme}.100`,
      _text: {
        color: `${colorScheme}.900`,
      },
      _icon: {
        color: `${colorScheme}.900`,
      },
      _spinner: {
        color: `${colorScheme}.900`,
      },
      _hover: {
        bg: `${colorScheme}.200`,
      },
      _pressed: {
        bg: `${colorScheme}.300`,
      },

      _dark: {
        bg: `${colorScheme}.700`,
        _text: {
          color: `${colorScheme}.50`,
        },
        _icon: {
          color: `${colorScheme}.50`,
        },
        _hover: {
          bg: `${colorScheme}.500`,
        },
        _pressed: {
          bg: `${colorScheme}.600`,
        },
      },
    }),
  },
}

export const Badge = {
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'base',
    size: 'md',
  },
  variants: {
    subtle: (props: InterfaceBadgeProps & { colorScheme: any }) => {
      const colorScheme = getColorScheme(props)
      return {
        _text: { color: `${colorScheme}.900` },
        _icon: { color: `${colorScheme}.900` },
        bg: `${colorScheme}.100`,
        _dark: { bg: `${colorScheme}.600`, _text: { color: `${colorScheme}.50` } },
        borderWidth: '1',
        borderRadius: '2',
        borderColor: 'transparent',
      }
    },
  },
}
