import { PresenceTransition } from 'native-base'
import React, { useEffect } from 'react'
import { Job, useTaskRunnerApi } from './provider'
import useTaskGenerator from './use-task-generator'

import { Box, Text } from 'ui/components/responsive-primitives'

export function TaskRunners() {
  const { jobs } = useTaskRunnerApi()

  const blockUI = jobs.some((j) => !!j.blockUI)

  return (
    <>
      {blockUI && (
        <Box
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(255,255,255,.3)',
            backdropFilter: 'blur(1px)',
          }}></Box>
      )}
      <PresenceTransition
        visible={jobs.length > 0}
        initial={{
          translateX: 204,
        }}
        animate={{
          translateX: 0,
          transition: {
            type: 'spring',
            velocity: 15,
            mass: 0.4,
          },
        }}>
        <Box
          bg="info.700"
          borderRadius="xs"
          minH={10}
          style={{ position: 'fixed', zIndex: 1000, bottom: 0, width: 200, right: 4 }}
          pl={1}>
          {jobs.map((j) => (
            <Runner key={j.name} job={j} />
          ))}
        </Box>
      </PresenceTransition>
    </>
  )
}

function Runner({ job }: { job: Job }) {
  const state = useTaskGenerator(job.task, { autoRun: true })

  const taskRunnerApi = useTaskRunnerApi()

  useEffect(() => {
    if (!state.loading) {
      taskRunnerApi.removeJob(job)
    }
  }, [state])

  return (
    <Box bg="info.700" px={1} my={1} borderLeftStyle="solid" borderLeftColor="info.400" borderLeftWidth={4}>
      <Text color="info.100" fontSize="xs" fontWeight="semibold">
        {job.name}
      </Text>
      <Text color="info.50" fontSize="xs">
        {state.data}
      </Text>
    </Box>
  )
}
