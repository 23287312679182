import React from 'react'
import {
  VictoryLegend,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryScatter,
  VictoryTooltip,
} from 'victory-native'
import victoryTheme from 'ui/theme/victory'
import { useToken, useColorModeValue, useBreakpointValue, Text, Box, useMediaQuery } from 'native-base'
import { Svg, Text as TextSVG } from 'react-native-svg'

interface ChartProps {
  [key: string]: any
}

const BarChartGrouped = ({
  data,
  width,
  yAxisLabelPrefix,
  yAxisLabelSuffix,
  yAxisAccessor,
  xAxisAccessor,
  xAxisLabel,
  yAxisLabel,
  legendInfo,
  ...rest
}: ChartProps) => {
  const [
    barColorOnAction,
    xAxisTickLabelColor,
    yAxisTickLabelColor,
    gridColor,
    toolTipBorderColor,
    toolTipBgColor,
    toolTipTextColor,
    axisLabelColor,
    axisColor,
  ] = useToken('colors', [
    useColorModeValue('primary.500', 'primary.600'),
    useColorModeValue('base.700', 'base.200'),
    useColorModeValue('base.700', 'base.200'),
    useColorModeValue('base.500', 'base.500'),
    useColorModeValue('primary.800', 'primary.200'),
    useColorModeValue('base.100', 'base.200'),
    useColorModeValue('primary.600', 'primary.600'),
    useColorModeValue('primary.600', 'primary.600'),
    useColorModeValue('base.400', 'base.500'),
  ])

  // const colorScale = useToken("colors",
  //   [
  //     'rose.600', 'fuchsia.900', 'emerald.800', 'purple.700', 'cyan.900',
  //     'yellow.800', 'red.600', 'warmGray.700', 'teal.800', 'darkBlue.700'
  //   ]
  // )

  const [isLargeScreen] = useMediaQuery({
    minWidth: 800,
  })

  function onHoverBar(): any {
    return [
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ]
  }

  function onHoverOutBar(): any {
    return [
      {
        target: 'labels',
        mutation: () => ({ active: false }),
      },
    ]
  }

  function onClickBar(): any {
    return [
      {
        eventKey: 'all',
        target: 'labels',
        mutation: () => ({ active: false }),
      },
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ]
  }

  function getYAxisTickLabel(text: string): string {
    text = yAxisLabelPrefix ? yAxisLabelPrefix + text : text
    text = yAxisLabelSuffix ? text + yAxisLabelSuffix : text
    return text
  }

  const chartCofing: any = {
    theme: victoryTheme,
    width: width ? width : 600,
    animate: {
      duration: 400,
      onLoad: { duration: 400 },
    },
    domainPadding: 50,
    padding: { top: 50, bottom: 50, left: 70, right: 20 },
  }

  const XAxisConfig: any = {
    crossAxis: true,
    axisLabelComponent: <VictoryLabel dy={25} style={{ fontSize: 15, fill: axisLabelColor }} />,
    label: xAxisLabel,
    style: {
      grid: { stroke: 'none' },
      tickLabels: { fill: xAxisTickLabelColor, fontSize: 13 },
      axis: { stroke: axisColor },
    },
  }

  const YAxisConfig: any = {
    dependentAxis: true,
    crossAxis: false,
    axisLabelComponent: <VictoryLabel dy={-45} style={{ fontSize: 15, fill: axisLabelColor }} />,
    label: yAxisLabel,
    style: {
      grid: { stroke: 'none' },
      tickLabels: { fill: yAxisTickLabelColor, fontSize: 13 },
      axis: { stroke: axisColor },
    },
    tickFormat: (t: any) => getYAxisTickLabel(t),
  }

  const barConfig: any = {
    labelComponent: (
      <VictoryTooltip
        flyoutStyle={{
          stroke: toolTipBorderColor,
          strokeWidth: 0.5,
          fill: toolTipBgColor,
        }}
        flyoutPadding={8}
        style={{ fill: toolTipTextColor }}
      />
    ),
    alignment: 'middle',
    x: xAxisAccessor,
    y: yAxisAccessor,
  }

  function getLegendData() {
    const legendDataArr = []
    legendInfo.data.forEach((dt) => {
      const dtObj = { name: dt, type: 'square' }
      legendDataArr.push(dtObj)
    })
    return legendDataArr
  }

  return (
    <VictoryChart {...chartCofing}>
      <VictoryAxis {...XAxisConfig} />
      <VictoryAxis {...YAxisConfig} />
      <VictoryLegend
        x={200}
        title={legendInfo.title}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ border: { stroke: '#c3cbd6' }, title: { fontSize: 20 } }}
        data={getLegendData()}
        colorScale={'qualitative'}
      />
      <VictoryGroup offset={20} colorScale={'qualitative'}>
        {data.map((dt, index) => {
          return (
            <VictoryBar
              key={index}
              data={dt}
              // style={{
              //   data: { fill: colorScale[index] }
              // }}
              labels={(d) => {
                const lData = d.datum
                return `${lData[xAxisAccessor]} - ${lData[yAxisAccessor]}`
              }}
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseOver: () => (isLargeScreen ? onHoverBar() : null),
                    onMouseOut: () => (isLargeScreen ? onHoverOutBar() : null),
                    onClick: () => (!isLargeScreen ? onClickBar() : null),
                  },
                },
              ]}
              {...barConfig}
              {...rest}
            />
          )
        })}
      </VictoryGroup>
    </VictoryChart>
  )
}

export default BarChartGrouped
