import { InterfaceInputProps } from 'native-base/lib/typescript/components/primitives/Input/types'

export const FormControlLabel = {
  baseStyle: () => {
    return {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      _text: {
        fontSize: 15,
        fontWeight: 'semibold',
        color: 'base.700',
      },
      my: '1',
      _astrick: {
        color: 'error.600',
      },
      _dark: {
        _text: {
          color: 'base.300',
        },
        _astrick: {
          color: 'error.500',
        },
      },
    }
  },
}

export const Input = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: (props: InterfaceInputProps & { theme: any }) => {
    const { primary, error } = props.theme.colors

    return {
      _input: {
        bg: 'base.50',
      },
      bg: 'base.50',
      placeholderTextColor: 'base.400',
      color: 'base.900',
      borderColor: 'base.300',
      _hover: {
        borderColor: 'primary.600',
      },
      _invalid: {
        borderColor: 'error.600',
        _hover: { borderColor: 'error.600' },
      },
      _focus: {
        borderColor: 'primary.600',
        bg: 'base.50',
        _hover: { borderColor: 'primary.600' },
        _invalid: {
          borderColor: 'error.600',
          _hover: { borderColor: 'error.600' },
        },
        _ios: {
          selectionColor: 'coolGray.800',
        },
        _android: {
          selectionColor: 'coolGray.800',
        },
        _disabled: {
          placeholderTextColor: 'muted.700',
          _hover: {
            borderColor: 'muted.300',
          },
        },
      },
      _dark: {
        placeholderTextColor: 'base.400',
        color: 'base.50',
        borderColor: 'base.600',
        bg: 'base.700',
        _input: {
          bg: 'base.700',
        },
        _hover: {
          borderColor: 'primary.500',
        },
        _focus: {
          borderColor: 'primary.500',
          bg: 'base.700',
          _hover: { borderColor: 'primary.500' },
        },
        _invalid: {
          borderColor: 'error.500',
          _hover: { borderColor: 'error.500' },
        },
        _ios: {
          selectionColor: 'warmGray.50',
        },
        _android: {
          selectionColor: 'warmGray.50',
        },
        _disabled: {
          placeholderTextColor: 'text.50',
          _hover: {
            borderColor: 'base.700',
          },
        },
      },
    }
  },
  variants: {
    quickAdd: (props) => {
      const { theme, colorMode } = props
      return {
        borderWidth: '1',
        _focus: {
          bg: colorMode === 'light' ? '#E8E8F8' : '#171D3A',
        },
      }
    },
  },
}

export const Radio = {
  defaultProps: {
    size: 'sm',
  },
  baseStyle: (props: Record<string, any>) => {
    const { colorScheme: c, theme } = props
    const { colors } = theme
    return {
      borderWidth: 1,
      borderRadius: 'full',
      p: 1,

      bg: 'base.50',
      borderColor: 'base.400',

      _checked: {
        borderColor: `${c}.600`,
        _icon: {
          color: `${c}.600`,
        },
        _hover: {
          borderColor: `${c}.700`,
          _icon: { color: `${c}.700` },
          _disabled: {
            borderColor: `${c}.600`,
            _icon: {
              color: `${c}.600`,
            },
          },
        },
        _pressed: {
          borderColor: `${c}.800`,
          _icon: { color: `${c}.800` },
        },
      },

      _hover: {
        borderColor: 'muted.500',
        _disabled: {
          borderColor: 'muted.400',
        },
        _checked: { borderColor: `${c}.600` },
      },

      _pressed: {
        borderColor: 'muted.600',
      },

      _invalid: {
        borderColor: 'error.600',
      },

      _dark: {
        bg: 'base.700',
        borderColor: 'base.600',

        _checked: {
          borderColor: `${c}.400`,
          _icon: {
            color: `${c}.400`,
          },
          _hover: {
            borderColor: `${c}.400`,
            _icon: { color: `${c}.400` },
            _disabled: {
              borderColor: `${c}.500`,
              _icon: {
                color: `${c}.500`,
              },
            },
          },
          _pressed: {
            borderColor: `${c}.300`,
            _icon: { color: `${c}.300` },
          },
        },

        _hover: {
          borderColor: 'base.400',
          _disabled: {
            borderColor: 'base.500',
          },
          _checked: { borderColor: `${c}.300` },
        },

        _pressed: {
          borderColor: 'muted.300',
        },

        _invalid: {
          borderColor: 'error.500',
        },
      },

      _focusVisible: {
        _web: {
          style: {
            outlineWidth: '2px',
            outlineColor: colors[c][400],
            outlineStyle: 'solid',
          },
        },
      },
    }
  },
}

export const Checkbox = {
  defaultProps: {
    size: 'sm',
    _text: {
      color: 'darkText',
      ml: 0,
    },
    _dark: {
      _text: {
        color: 'lightText',
      },
    },
  },
  baseStyle: (props: Record<string, any>) => {
    const { colorScheme: c, theme } = props
    const { colors } = theme
    return {
      borderWidth: 1,
      bg: 'base.50',

      borderColor: 'base.400',
      _text: {
        color: 'darkText',
      },
      _icon: {
        color: `muted.50`,
      },
      _checked: {
        borderColor: `${c}.600`,
        bg: `${c}.600`,
        _hover: {
          borderColor: `${c}.700`,
          bg: `${c}.700`,
          _disabled: {
            borderColor: `${c}.600`,
            bg: `${c}.600`,
          },
        },
        _pressed: {
          borderColor: `${c}.800`,
          bg: `${c}.800`,
        },
      },
      _hover: {
        borderColor: 'muted.500',
        _disabled: {
          borderColor: 'muted.400',
        },
      },
      _pressed: {
        borderColor: 'muted.600',
      },
      _invalid: {
        borderColor: 'error.600',
      },

      _dark: {
        bg: 'base.700',
        borderColor: 'base.600',
        _text: {
          color: 'lightText',
        },
        _icon: {
          color: `${c}.50`,
        },
        _checked: {
          borderColor: `${c}.500`,
          bg: `${c}.500`,
          _hover: {
            borderColor: `${c}.400`,
            bg: `${c}.400`,
            _disabled: {
              borderColor: `${c}.500`,
              bg: `${c}.500`,
            },
          },
          _pressed: {
            borderColor: `${c}.300`,
            bg: `${c}.300`,
          },
        },
        _hover: {
          borderColor: 'muted.400',
          _disabled: {
            borderColor: 'muted.500',
          },
        },
        _pressed: {
          borderColor: 'muted.300',
        },
        _invalid: {
          borderColor: 'error.500',
        },
      },

      _stack: {
        direction: 'row',
        alignItems: 'center',
        space: 2,
        _web: {
          cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        },
      },

      _focusVisible: {
        _web: {
          style: {
            outlineWidth: '2px',
            outlineColor: colors[c][400],
            outlineStyle: 'solid',
          },
        },
      },

      _disabled: {
        _web: {
          cursor: 'not-allowed',
        },
        opacity: 0.4,
      },
    }
  },
}
