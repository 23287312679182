import MaterialIcons from '@expo/vector-icons/build/MaterialIcons'
import { IBoxProps, Pressable } from 'native-base'
import * as R from 'ramda'
import React, { useContext } from 'react'
import { Box, HStack, Icon, Stack, Text } from './responsive-primitives'

const GroupCtx = React.createContext(false)

const getWrapperStyle = (completed: boolean, isGroup: boolean) => ({
  default: {
    _light: {
      bg: 'primary.100',
      borderColor: 'primary.600',
    },
    _dark: {
      bg: 'primary.900',
      borderColor: 'primary.600',
    },
    borderWidth: 2,
  },
  completed:
    completed && isGroup
      ? {}
      : {
          _light: {
            bg: 'success.100',
            borderColor: 'success.600',
          },
          _dark: {
            bg: 'success.900',
            borderColor: 'success.600',
          },
          borderWidth: 2,
        },
})

const iconColors = {
  default: {
    _light: { bg: 'primary.600' },
    _dark: { bg: 'primary.500' },
  },
  completed: {
    _light: { bg: 'success.600' },
    _dark: { bg: 'success.500' },
  },
} as const

const icons = {
  default: 'arrow-forward',
  completed: 'check',
} as const

interface TaskProps {
  title: string
  description?: string
  completed?: boolean
  disabled?: boolean
  index?: number
  onPress: () => void
  active?: boolean
}

function Task({
  title,
  description,
  completed = false,
  disabled = false,
  index,
  onPress,
  active,
  ...rest
}: TaskProps & IBoxProps) {
  const type = completed ? 'completed' : 'default'
  const isGroup = useContext(GroupCtx)
  const showLine = isGroup && !!index && index > 0

  return (
    <Box {...rest}>
      {showLine && (
        <Box
          ml={6}
          w={1}
          h={3}
          _light={{ borderLeftColor: 'success.600' }}
          _dark={{ borderLeftColor: 'success.600' }}
          borderLeftStyle={completed ? 'solid' : 'dashed'}
          borderLeftWidth={2}
          opacity={disabled ? 0.6 : 1}></Box>
      )}
      <Pressable onPress={onPress}>
        {({ isFocused, isHovered, isPressed }) => (
          <Box
            style={{ userSelect: 'none' }}
            {...getWrapperStyle(completed, isGroup)[type]}
            borderStyle="solid"
            px={isGroup ? 2 : 3}
            py={completed && isGroup ? 0 : isGroup ? 2 : 3}
            borderRadius="full"
            opacity={disabled ? 0.6 : 1}
            testID="self-onboarding-task">
            <HStack space={3} alignItems="center">
              <Stack
                borderRadius="full"
                alignItems="center"
                justifyContent="center"
                position="relative"
                style={{
                  width: 34,
                  height: 34,
                  transition: 'all .1s ease-in-out',
                  transform: [
                    {
                      scale: isPressed ? 0.9 : isHovered ? 1.05 : 1,
                    },
                  ],
                }}
                {...iconColors[type]}>
                {active && (
                  <Box
                    size={2}
                    bg={completed ? 'success.600' : 'primary.600'}
                    rounded="full"
                    style={{ position: 'absolute', top: 13, left: -20 }}></Box>
                )}
                <Icon as={MaterialIcons} name={icons[type]} color="base.50$" size={6} />
              </Stack>
              <Box flex={1}>
                <Text
                  fontSize={isGroup ? 'sm' : 'md'}
                  fontWeight="bold"
                  color={completed && isGroup ? 'success.800$' : 'base.900$'}
                  lineHeight={'sm'}>
                  {title}
                </Text>
                {description && (
                  <Text color="base.600$" lineHeight={'sm'}>
                    {description}
                  </Text>
                )}
              </Box>
            </HStack>
          </Box>
        )}
      </Pressable>
    </Box>
  )
}

Task.displayName = 'Task'

Task.Group = ({ children }: any) => (
  <GroupCtx.Provider value={true}>
    {React.Children.map(children, (child: React.ReactElement<TaskProps>, i: number) => {
      if (!!child && R.path(['type', 'displayName'], child) === 'Task') {
        return React.cloneElement(child, {
          index: i,
        })
      } else return child
    })}
  </GroupCtx.Provider>
)

export default Task
