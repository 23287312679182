import React, { useEffect, useMemo, useState } from 'react'

import { Route, Routes, useNavigate } from './router'
import { Breakpoint, useWindowDimensions, Box } from './ui'
import { Text as RText, Box as RBox } from 'ui/components/responsive-primitives'
import { useBreakpointValue } from 'native-base'
import { NavBox, NavLink } from './views/root-navigation'

import { AppModule, getAppModules } from './app/modules'
import WelcomeScreen from './welcome/index'
import { useLocation } from './router'
import { GuardedRoute, useAuthAPI } from './authentication'
import GuestWelcome from './authentication/guest'
import { GuestAction, LoggedInUser, LoginMethod } from './authentication/model'
import GuestBankConnectionApp from './bank-connection/guest-connect'
import Registry from 'app/registry'
import { PortalHost } from '@gorhom/portal'

export default function AppModules() {
  const authApi = useAuthAPI()
  const { height } = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const [loginMethod, setLoginMethod] = useState<LoginMethod | null>(null)

  const onLogin = (user: LoggedInUser, method: LoginMethod) => {
    setLoginMethod(method)
    authApi.onLogin(user)
  }

  // console.log('UserInfo', authApi.userInfo)

  const modules = useMemo(() => getAppModules(authApi), [authApi.userInfo])

  // Kind of hack to redirect to some module that is mounted after the initial login
  useEffect(() => {
    if (authApi.isLoggedIn() && loginMethod != null) {
      onPostLogin(loginMethod, location)
      setLoginMethod(null)
    }
  }, [authApi.isLoggedIn()])

  const onPostLogin = (method: LoginMethod, loc: any) => {
    // console.log('onPostLogin', method, loc, modules)
    const origin = loc.state?.from?.pathname || '/'
    if (authApi.isLoggedIn()) {
      switch (method) {
        // case LoginMethod.SignIn:
        case LoginMethod.SignUp:
          // case LoginMethod.AcceptInvite:
          navigate('/onboarding/welcome', { replace: true })
          break
        default: {
          navigate(origin)
        }
      }
    }
  }
  // End redirect to welcome

  const modulesComp = useMemo(() => {
    return modules.map((m, i) => (
      <Route
        key={i}
        path={m.path}
        element={
          <GuardedRoute roles={m.roles}>
            <m.component />
          </GuardedRoute>
        }
      />
    ))
  }, [authApi])

  return (
    <Box flex={1} flexDirection="row" style={{ height, flexBasis: height, position: 'relative', overflow: 'hidden' }}>
      <Breakpoint size={['phone', 'tablet']}>
        <Routes>
          <Route
            path={'/'}
            element={
              <GuardedRoute>
                <ModuleLinks modules={modules} sidebar={false} />
              </GuardedRoute>
            }
          />
          {modulesComp}
          <Route
            path="/accept-invite"
            element={<GuestWelcome onLoginInSuccess={onLogin} action={GuestAction.accept_invite} />}
          />
          <Route path="/connect-bank/:uuid" element={<GuestBankConnectionApp />} />
          <Route
            path="/auth/sign-in"
            element={<GuestWelcome onLoginInSuccess={onLogin} action={GuestAction.sign_in} />}
          />
          <Route
            path="/redeem/gc/:giftCardCode?"
            element={
              <GuardedRoute>
                <Registry.RedeemGC />
              </GuardedRoute>
            }
          />
        </Routes>
        {location.pathname !== '/' && <ModuleLinks modules={modules} sidebar={true} />}
      </Breakpoint>
      <Breakpoint size={'desktop'}>
        <ModuleLinks modules={modules} sidebar={false} />
        <Routes>
          <Route
            path="/"
            element={
              <GuardedRoute>
                <WelcomeScreen />
              </GuardedRoute>
            }
          />
          <Route
            path="/accept-invite"
            element={<GuestWelcome onLoginInSuccess={onLogin} action={GuestAction.accept_invite} />}
          />
          <Route path="/connect-bank/:uuid" element={<GuestBankConnectionApp />} />
          <Route
            path="/auth/sign-in"
            element={<GuestWelcome onLoginInSuccess={onLogin} action={GuestAction.sign_in} />}
          />
          {modulesComp}
          <Route
            path="/redeem/gc/:giftCardCode?"
            element={
              <GuardedRoute>
                <Registry.RedeemGC />
              </GuardedRoute>
            }
          />
        </Routes>
      </Breakpoint>
      {/* <UiDebug /> */}
    </Box>
  )
}

function ModuleLinks(props: { modules: AppModule[]; sidebar: boolean }): JSX.Element {
  const authApi = useAuthAPI()
  return authApi.isLoggedIn() ? (
    <NavBox sidebar={props.sidebar}>
      {props.modules
        .filter(({ enabled }) => !!enabled)
        .map((m, i) => (
          <Box key={i}>
            <NavLink to={m.url} label={m.name} icon={m.icon} iconFamily={m.iconFamily} exactMatch={m.exactMatch} />
            <PortalHost name={`navlinks${m.url}`} />
          </Box>
        ))}
    </NavBox>
  ) : (
    <></>
  )
}

function UiDebug() {
  if (process.env.NODE_ENV !== 'development') return null
  const bp = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
  })
  return (
    <RBox position="absolute" bottom={0} right={10} px={2} py={0} bg="base.700$" borderTopRadius="sm">
      <RText color="base.200$">{bp}</RText>
    </RBox>
  )
}
