import React, { FC } from 'react'
import { createText } from '@shopify/restyle'
import { Theme } from '../theme'

export const TextCtx = React.createContext({})

const REText = createText<Theme>()
export type TextProps = React.ComponentProps<typeof REText>

const Text = ({ style, ...rest }: TextProps) => {
  return (
    <TextCtx.Consumer>
      {(ctxStyle) => {
        return <REText style={[ctxStyle, style]} maxFontSizeMultiplier={1.2} {...rest} />
      }}
    </TextCtx.Consumer>
  )
}

export const StyleText: FC<TextProps> = ({ style, children }: any) => (
  <TextCtx.Consumer>
    {(ctxStyle) => <TextCtx.Provider value={{ ...ctxStyle, ...style }}>{children}</TextCtx.Provider>}
  </TextCtx.Consumer>
)

Text.defaultProps = {
  color: 'base900',
}

export default Text
