import { Apps, getAPIURL } from '../../api_url'
import axios from 'axios'
import formatBackendErrors from '../../common/util'
import { AcceptInviteParams, LoggedInUser, SignUpParams, SignUpResposne } from '../model'

const BASE_URL = getAPIURL(Apps.USERS_API) + '/v5'

const signIn = (params: { email: string; password: string }): Promise<LoggedInUser> => {
  return axios
    .post(BASE_URL + '/get_auth_token', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const acceptInvite = (params: AcceptInviteParams): Promise<any> => {
  return axios
    .post(BASE_URL + '/access_group/user/accept_invite', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const signUp = (params: SignUpParams): Promise<SignUpResposne> => {
  return axios
    .post(BASE_URL + '/signup', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const confirmAccount = (params: { id: number; token: string; company: string }): Promise<any> => {
  return axios
    .post(BASE_URL + '/confirm_account', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const forget_password = (params: { email: string }): Promise<any> => {
  return axios
    .post(BASE_URL + '/forget_password', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const reset_password = (params: { email: string; token: string; password: string }) => {
  return axios
    .post(BASE_URL + '/reset_password', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

export const AuthenticationApis = {
  signIn,
  acceptInvite,
  signUp,
  confirmAccount,
  forget_password,
  reset_password,
}
