import React, { PropsWithChildren, useContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeBaseProvider, extendTheme, StorageManager, ColorMode, useTheme } from 'native-base'
import DimensionsProvider from './theme/dimensions'
import { palette2 } from './theme/palette'
import {
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
  PopoverFooter,
  PopoverCloseButton,
  PopoverContent,
} from './theme/popover'
import { Modal, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from './theme/modal'
import { FormControlLabel, Input, Radio, Checkbox } from './theme/forms'
import { Button, Badge } from './theme/buttons'
import { IHybridContextProps } from 'native-base/src/core/hybrid-overlay'
import { HybridContext } from 'native-base/lib/module/core/hybrid-overlay'
import InjectCSSVars from './theme/inject-css-vars'

const colorModeManager: StorageManager = {
  get: async () => {
    try {
      const val = await AsyncStorage.getItem('@color-mode')
      return val === 'dark' ? 'dark' : 'light'
    } catch (e) {
      return 'light'
    }
  },
  set: async (value: ColorMode) => {
    try {
      await AsyncStorage.setItem('@color-mode', value as string)
    } catch (e) {
      console.log(e)
    }
  },
}

export default function UIProvider({ children }: PropsWithChildren<any>) {
  const theme = extendTheme({
    components: {
      Button,
      Badge,
      Modal,
      ModalContent,
      ModalHeader,
      ModalCloseButton,
      ModalFooter,
      PopoverBody,
      PopoverArrow,
      PopoverHeader,
      PopoverFooter,
      PopoverCloseButton,
      PopoverContent,
      FormControlLabel,
      Input,
      Radio,
      Checkbox,
    },
    colors: {
      base: palette2.coolGray,
      primary: palette2.indigo,
      secondary: palette2.coolGray,
      info: palette2.blue,
    },
    shadows: {
      10: {
        shadowColor: '#0E1017',
        shadowOffset: {
          width: 0,
          height: 18,
        },
        shadowOpacity: 0.25,
        shadowRadius: 16,
        elevation: 17,
      },
    },
  })
  // console.log('APP THEME:', theme)

  return (
    <NativeBaseProvider theme={theme} config={{ strictMode: 'off' }} colorModeManager={colorModeManager}>
      <DimensionsProvider>{children}</DimensionsProvider>
      <InjectCSSVars />
    </NativeBaseProvider>
  )
}

export function ForceColorMode({ children, mode }: PropsWithChildren<{ mode: ColorMode }>) {
  const api = useContext<IHybridContextProps>(HybridContext)
  const newApi = {
    colorMode: {
      ...api.colorMode,
      colorMode: 'dark',
    },
  } as IHybridContextProps

  return <HybridContext.Provider value={newApi}>{children}</HybridContext.Provider>
}
