import { Alert, IconButton, CloseIcon, IBoxProps, ITextProps } from 'native-base'
import {
  Box,
  VStack,
  HStack,
  Text,
  CheckCircleIcon,
  InfoIcon,
  WarningIcon,
  WarningTwoIcon,
} from './responsive-primitives'
import React from 'react'

interface AlertProps {
  status: 'info' | 'warning' | 'success' | 'error'
  title?: string | React.ReactElement
  description?: string | React.ReactElement
  onClose?: () => void
}

const icons = {
  info: InfoIcon,
  warning: WarningIcon,
  error: WarningTwoIcon,
  success: CheckCircleIcon,
}

export const Error = (props: ITextProps) => (
  <HStack alignItems="flex-start">
    <Box>
      <WarningTwoIcon color={`error.600$`} style={{ marginTop: 3, marginRight: 6 }} />
    </Box>
    <Box flex={1}>
      <Text fontSize="sm" fontWeight="medium" color="error.600$" {...props} />
    </Box>
  </HStack>
)
export const Warning = (props: ITextProps) => (
  <HStack space={1} alignItems="flex-start">
    <Box>
      <WarningIcon color={`warning.600$`} style={{ marginTop: 3, marginRight: 6 }} />
    </Box>
    <Box flex={1}>
      <Text fontSize="sm" fontWeight="medium" color="warning.600$" {...props} />
    </Box>
  </HStack>
)
export const Info = (props: ITextProps) => (
  <HStack space={1} alignItems="flex-start">
    <Box>
      <InfoIcon color={`info.600$`} style={{ marginTop: 3, marginRight: 6 }} />
    </Box>
    <Box flex={1}>
      <Text fontSize="sm" fontWeight="medium" color="info.600$" {...props} />
    </Box>
  </HStack>
)
export const Success = (props: ITextProps) => (
  <HStack space={1} alignItems="flex-start">
    <Box>
      <CheckCircleIcon color={`success.600$`} style={{ marginTop: 3, marginRight: 6 }} />
    </Box>
    <Box flex={1}>
      <Text fontSize="sm" fontWeight="medium" color="success.600$" {...props} />
    </Box>
  </HStack>
)

export default function UIAlert({ status, title, description, onClose, children, ...rest }: AlertProps & IBoxProps) {
  const Icon = icons[status]
  return (
    <Box bg={`${status}.100$`} px={3} py={2} borderRadius="sm" shadow={2} maxW={420} {...rest}>
      <VStack flexShrink={1} w="100%">
        <HStack flexShrink={1} space={1} alignItems="center" justifyContent="space-between">
          <HStack flexShrink={1} space={2} alignItems="center">
            <Box w={4}>
              <Icon color={`${status}.600$`} />
            </Box>
            <Text fontSize="sm" fontWeight="bold" color={`${status}.900$`}>
              {title}
            </Text>
          </HStack>
          {!!onClose && (
            <IconButton
              p={0}
              variant="unstyled"
              _focus={{
                borderWidth: 0,
              }}
              icon={<CloseIcon size="3" color="darkText" _dark={{ color: 'lightText' }} />}
              onPress={onClose}
            />
          )}
        </HStack>
        <Box pl="6" alignItems="flex-start">
          {description && <Text color={`${status}.900$`}>{description}</Text>}
          {children}
        </Box>
      </VStack>
    </Box>
  )
}
