export default function loadScript<ImportType>(src: string, importName: string, async = true, type = 'text/javascript'): Promise<ImportType> {
  if (!window[importName]) {
    return new Promise((resolve, reject) => {
      try {
        const el = document.createElement('script')
        const container = document.head || document.body

        el.type = type
        el.async = async
        el.src = src

        el.addEventListener('load', () => {
          resolve(window[importName] as ImportType)
        })

        el.addEventListener('error', () => {
          reject({
            status: false,
            message: `Failed to load the script ${src}`
          })
        })

        container.appendChild(el)
      } catch (err) {
        reject(err)
      }
    })
  } else {
    return Promise.resolve(window[importName])
  }
}
