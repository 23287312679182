import React from 'react'
import { Input } from 'ui/components/forms/fields'
import { onlyNumbers as onlyNumbersMasked } from '../../../menu-editor/utils/prices'

export default function InputNumber({ rules, onlyNumbers, ...props }: React.ComponentProps<typeof Input>) {
  return (
    <Input
      {...props}
      toModel={(ev) => (onlyNumbers ? onlyNumbersMasked(ev.target.value) : ev.target.value)}
      keyboardType="decimal-pad"
      rules={{
        validateAsNumber: true,
        pattern: {
          value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
          message: 'Please enter a number',
        },
        ...rules,
      }}
    />
  )
}
