import * as R from 'ramda'
import React, { FC, useContext } from 'react'
import { Box, HStack, Stack, Text, VStack, IBoxProps, Pressable } from 'native-base'

const TableCtx = React.createContext({})

const alignItems: { [key: string]: string } = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

type Maybe<T> = T | null
type Size = number | string | undefined
type Align = 'left' | 'center' | 'right' | undefined

type TdProps = IBoxProps & {
  index?: number
  style?: {}
  [key: string]: any
}

const getSizeStyle = (size: Size) => {
  if (typeof size === 'number') {
    return { flexGrow: 0, flexShrink: 0, flexBasis: size }
  }
  return { flex: 1 }
}

export const Td: FC<TdProps> = ({ children, index = 0, ...rest }) => {
  const { dimensions, alignment, _td } = useContext<TableProps>(TableCtx)
  const size = dimensions[index]
  const align: Align = alignment[index]

  const alignStyle = !!align ? alignItems[align] : 'flex-start'

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={alignStyle}
      testID="table-cell"
      {...getSizeStyle(size)}
      {..._td}
      {...rest}>
      {children}
    </Box>
  )
}
Td.displayName = 'Td'

type TrProps = IBoxProps & {
  index?: number
  onPress?: () => void
  selected?: boolean
  children?: React.ReactNode
}

export const Tr: FC<TrProps> = ({ children, onPress, index = 0, selected = false, ...rest }) => {
  const { stripped } = useContext<Partial<TableProps>>(TableCtx)

  const isOdd = stripped && index % 2 !== 0

  let i = 0
  const Row = (
    <Box
      aria-label="Tr"
      display="flex"
      flexDirection="row"
      _light={{ bg: selected ? 'warning.100' : isOdd ? 'base.200:alpha.20' : 'transparent' }}
      _dark={{ bg: selected ? 'warning.700' : isOdd ? 'base.500:alpha.10' : 'transparent' }}
      testID="table-row"
      {...rest}>
      {React.Children.map(children, (child: React.ReactElement<TdProps>) => {
        if (!!child && R.path(['type', 'displayName'], child) === 'Td') {
          return React.cloneElement(child, {
            index: i++,
          })
        } else return child
      })}
    </Box>
  )

  if (typeof onPress === 'function') {
    return (
      <Pressable
        {...{ onPress }}
        _hover={{ _light: { bg: 'warning.100' }, _dark: { bg: 'warning.700' } }}
        testID="table-row-button">
        {Row}
      </Pressable>
    )
  }
  return Row
}
Tr.displayName = 'Tr'

export type TableProps = IBoxProps & {
  dimensions: Size[]
  alignment: Align[]
  stripped?: boolean
  _td?: {}
  style?: {}
}

const nonBool = (el: any) => typeof el === 'string' || typeof el === 'number'

export const Table: FC<TableProps> = ({
  dimensions = [],
  alignment = [],
  stripped = false,
  _td,
  children,
  ...rest
}) => {
  let index = 0
  return (
    <TableCtx.Provider
      value={{
        dimensions: dimensions.filter(nonBool),
        alignment: alignment.filter(nonBool),
        stripped,
        _td,
      }}>
      <Box display="flex" flexDirection="column" {...rest}>
        {React.Children.map(children, (child: React.ReactElement<TrProps>) => {
          if (!!child && R.path(['type', 'displayName'], child) === 'Tr') {
            return React.cloneElement(child, {
              index: index++,
            })
          } else return child
        })}
      </Box>
    </TableCtx.Provider>
  )
}
Table.displayName = 'Table'
