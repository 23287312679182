export const AG_ROLES = {
  ACCESS_GROUPS_VIEW_ROLE: 'access_groups',
  ACCESS_GROUPS_ADMIN_ROLE: 'access_groups_admin',
  AVAILABILITIES_VIEW_ROLE: 'availabilities',
  AVAILABILITIES_ADMIN_ROLE: 'availabilities_admin',
  BILLING_ROLE: 'billing',
  BILLING_ADMIN_ROLE: 'billing_admin',
  CHANNELS_VIEW_ROLE: 'channels',
  CHANNELS_ADMIN_ROLE: 'channels_admin',
  DELIVERY_MARKETS_VIEW_ROLE: 'delivery_markets',
  DELIVERY_MARKETS_ADMIN_ROLE: 'delivery_markets_admin',
  DISCOUNTS_VIEW_ROLE: 'discounts',
  DISCOUNTS_ADMIN_ROLE: 'discounts_admin',
  LOYALTY_PLANS_VIEW_ROLE: 'loyalty_plans',
  LOYALTY_PLANS_ADMIN_ROLE: 'loyalty_plans_admin',
  ORDER_WORKFLOWS_VIEW_ROLE: 'order_workflows',
  ORDER_WORKFLOWS_ADMIN_ROLE: 'order_workflows_admin',
  PICTURE_COLLECTIONS_VIEW_ROLE: 'picture_collections',
  PICTURE_COLLECTIONS_ADMIN_ROLE: 'picture_collections_admin',
  RESTAURANTS_VIEW_ROLE: 'restaurants',
  RESTAURANTS_ADMIN_ROLE: 'restaurants_admin',
  SERVICE_PROVIDERS_VIEW_ROLE: 'service_providers',
  SERVICE_PROVIDERS_ADMIN_ROLE: 'service_providers_admin',
  SUPPORT_SUPERVISOR_ROLE: 'support_supervisor',
  PUBLISHER_ROLE: 'publisher',
  TEXT_TEMPLATES_ROLE: 'text_templates',
  MAILER_TEMPLATES_ROLE: 'mailer_templates',
  CHAINS_ROLE: 'chains',
  BLOCK_CUSTOMERS_ROLE: 'block_customers',
  CARD_DEFINITIONS_VIEW_ROLE: 'gift_card_definitions',
  CARD_DEFINITIONS_ADMIN_ROLE: 'gift_card_definitions_admin',
  BANK_CONNECTIONS: 'bank_connections',
  BANK_CONNECTIONS_ADMIN: 'bank_connections_admin',
  GROWTH_SERVICES: 'growth_services',
  GROWTH_SERVICES_ADMIN: 'growth_services_admin',
  CUSTOMERS_VIEW_ROLE: 'customers',
  CUSTOMERS_ADMIN_ROLE: 'customers_admin',
  NATIVE_APPS_VIEW_ROLE: 'native_apps',
  NATIVE_APPS_ADMIN_ROLE: 'native_apps_admin',
  DEVICES_VIEW_ROLE: 'devices',
  DEVICES_ADMIN_ROLE: 'devices_admin',
} as const
