import React from 'react'
import { Button, Pressable, Stack, Text } from 'native-base'
import { Link } from '../../router'

interface Item {
  onPress?: () => void
  label: string
  isActive?: boolean
}

type Props = React.ComponentProps<typeof Stack>

export function Item({ onPress = () => {}, label, isActive = false }: Item) {
  return (
    <Pressable
      p="1"
      px={{ base: 2, md: 2, lg: 4 }}
      mb="-px"
      onPress={onPress}
      borderBottomWidth="2"
      _light={{ borderBottomColor: isActive ? 'primary.500' : 'transparent' }}
      _dark={{ borderBottomColor: isActive ? 'primary.300' : 'transparent' }}
      _hover={{ borderBottomColor: isActive ? 'primary.500' : 'base.300' }}
      testID="tab-button">
      <Text
        fontWeight="medium"
        fontSize={'sm'}
        _light={{ color: isActive ? 'primary.700' : 'base.600' }}
        _dark={{ color: isActive ? 'primary.300' : 'base.400' }}>
        {label}
      </Text>
    </Pressable>
  )
}

export default function TabList(props: Props): JSX.Element {
  return (
    <Stack
      _light={{ bg: 'base.50', borderBottomColor: 'base.300' }}
      _dark={{ bg: 'base.800', borderBottomColor: 'base.700' }}
      pt="2"
      px="4"
      direction="row"
      alignItems={'center'}
      justifyContent="center"
      borderBottomWidth="1"
      {...props}
    />
  )
}

TabList.Item = Item
