import React, { createContext, useContext, useEffect, useState } from 'react'
import usePersistentState from 'ui/hooks/use-persistent-state'
import { useToast, Button, CloseIcon } from 'native-base'
import { Alert, VStack, HStack, Text, IconButton } from 'ui'

interface HintOptions {
  key: string
  message: string | React.ReactElement
  title?: string | React.ReactElement
  timeout?: number
}

interface AppHintsApi {
  showHint: (options: HintOptions) => void
  resetHints: () => void
}

interface HintsStorage {
  [key: string]: { active: boolean; lastRun: Date }
}

const AppHintsCtx = createContext<AppHintsApi>({ showHint: () => {}, resetHints: () => {} })

export function AppHintsProvider({ children }: React.PropsWithChildren<any>) {
  const [appHints, setAppHints] = usePersistentState<HintsStorage>('appHints', {})
  const toast = useToast()

  const showHint = (options: HintOptions) => {
    const hint = appHints[options.key]
    const now = new Date()

    // Show the hint if it's not marked as inactive within the last 24 hours
    if (
      !hint ||
      hint.active ||
      (hint.lastRun && now.getTime() - new Date(hint.lastRun).getTime() > 24 * 60 * 60 * 1000)
    ) {
      toast.show({
        duration: options.timeout || 10000,
        // placement: 'top',
        render: ({ id }) => {
          return (
            <Alert status="info" title={options.title} description={options.message} onClose={() => toast.close(id)}>
              <Button
                size="sm"
                variant="link"
                px={0}
                _dark={{ _text: { color: 'info.200' } }}
                onPress={() => {
                  setAppHints({
                    ...appHints,
                    [options.key]: { active: false, lastRun: new Date() },
                  })
                  toast.close(id)
                }}>
                Don't show this again today
              </Button>
            </Alert>
          )
        },
      })

      // Update the last run time only when showing the hint
      if (!hint || hint.active) {
        setAppHints({
          ...appHints,
          [options.key]: { active: true, lastRun: new Date() },
        })
      }
    }
  }

  const resetHints = () => {
    setAppHints({})
  }

  return <AppHintsCtx.Provider value={{ showHint, resetHints }}>{children}</AppHintsCtx.Provider>
}

export function useAppHintsApi() {
  const api = useContext(AppHintsCtx)
  return api
}
