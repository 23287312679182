import { Box, Center, HStack, Stack, Text, VStack } from 'native-base'
import { Image } from 'react-native'
import React from 'react'
import badge from 'assets/zuppler-chef-badge.svg'

export default function WelcomeScreen() {
  return (
    <Center flex="1" bg="base.800">
      <VStack>
        <Text color="base.400" fontSize="md">
          welcome to
        </Text>
        <HStack alignItems="flex-end">
          <Text color="base.100" fontSize="xl" fontWeight="bold">
            Zuppler
          </Text>
          <Image source={badge} style={{ width: 60, height: 55 }} />
          <Text color="base.100" fontSize="xl" fontWeight="bold">
            Chef
          </Text>
        </HStack>
      </VStack>
    </Center>
  )
}
