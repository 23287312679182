import { UISecurityCtx } from 'app/context'
import { UISecurity } from 'app/types'
import React, { useContext, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import './styles.css'

interface SecurityProps {
  canChange?: (securityKey?: string) => boolean
}

export function UISecurityProvider(props: React.PropsWithChildren<SecurityProps>) {
  const canChange = typeof props.canChange === 'function' ? props.canChange : () => true
  return <UISecurityCtx.Provider value={{ canChange }}>{props.children}</UISecurityCtx.Provider>
}

export function useUISecurity(): UISecurity {
  const ctx = useContext(UISecurityCtx)
  return ctx
}

export function ViewGuard({ children, skey }: React.PropsWithChildren<{ skey?: string }>): JSX.Element {
  const uiSecurity = useUISecurity()

  const canChange = !skey || uiSecurity.canChange(skey)

  return <GuardDebug skey={skey}>{canChange ? children : (null as unknown as React.ReactElement)}</GuardDebug>
}

export function GuardDebug({ children, skey }: React.PropsWithChildren<{ skey?: string }>): JSX.Element {
  const uiSecurity = useUISecurity()
  const debugMode = window?.location?.search.includes('uidebug')

  if (!debugMode) return children as React.ReactElement

  const canChange = uiSecurity.canChange(skey)

  return (
    <div className={`uidebug ${canChange ? '' : 'err'}`}>
      <div className={`uidebug-label ${canChange ? '' : 'err'}`}>{skey || 'no skey'}</div>
      {children}
    </div>
  )
}
