import * as R from 'ramda'
import React from 'react'
import { View as RNView, ViewProps } from 'react-native'
import {
  createBox,
  BoxProps as _BoxProps,
  TextProps as _TextProps,
  createRestyleComponent,
  textRestyleFunctions,
  boxRestyleFunctions,
} from '@shopify/restyle'
import { Theme } from '../theme'
import { StyleText } from './text'

type REProps = React.PropsWithChildren<ViewProps> & _BoxProps<Theme> & _TextProps<Theme>

const TextStyleProps = ['color', 'fontSize', 'fontWeight', 'fontFamily', 'textTransform', 'textAlign', 'lineHeight']

const typeIs = (t: string) => R.compose(R.equals(t), R.type)

const sanitizeStyles = (picker: any, obj: any) => {
  const ret = R.ifElse(
    typeIs('Array'),
    R.compose(R.map(picker), R.filter(typeIs('Object')) as any, R.flatten),
    R.ifElse(typeIs('Object'), picker, R.identity)
  )(obj)
  return ret
}

const View: React.FC = React.forwardRef( ({ style, ...rest }: any, ref) => {
  const textStyles = R.mergeAll(sanitizeStyles(R.pick(TextStyleProps), style))
  const viewStyles = sanitizeStyles(R.omit(TextStyleProps), style)

  if (R.isEmpty(textStyles)) {
    return <RNView style={viewStyles} {...rest} ref={ref} />
  }
  return (
    <StyleText style={textStyles}>
      <RNView style={viewStyles} {...rest} ref={ref} />
    </StyleText>
  )
})

const Box = createRestyleComponent<REProps, Theme>([...boxRestyleFunctions, ...textRestyleFunctions] as any, View)

export type BoxProps = React.ComponentProps<typeof Box>

export default Box
