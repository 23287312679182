import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { equals } from 'ramda'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Divider, HStack, MarkDown, Modal, Text, VStack, Button, Box } from 'ui'
import { auditObjDesc } from '../audit/service'
import hash from 'object-hash'

import { PhoenixSocketContext } from './phoenix-channel'

const ACTION = 'shout'

export default function QueryFormSync<T>({
  queryKey,
  makeDefaultValues,
}: {
  queryKey: QueryKey
  makeDefaultValues: (updatedData: T) => FieldValues
}) {
  const { channel } = useContext(PhoenixSocketContext)
  const queryClient = useQueryClient()
  const canUpdateRef = useRef(false)
  const form = useFormContext()

  const [nextFormValues, setNextFormValues] = useState<FieldValues>({})
  const [changes, setChanges] = useState<string>('')
  const [showModal, setShowModal] = useState(false)

  const onResetForm = useCallback(() => {
    form.reset(nextFormValues)
    setShowModal(false)
  }, [form, nextFormValues])

  useEffect(() => {
    if (!channel) return

    channel.on(ACTION, (payload) => {
      if (payload.invalidateKeys && payload.invalidateKeys.find((key: QueryKey) => equals(key, queryKey))) {
        // console.log(`Query [${queryKey}] invalidated`)
        canUpdateRef.current = true
      }
    })

    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (event.type === 'updated' && equals(event.query.queryKey, queryKey)) {
        if (canUpdateRef.current) {
          const newValues = makeDefaultValues(event.query.state.data)
          const formValues = form.getValues()
          const changed = hash(formValues) !== hash(newValues)
          canUpdateRef.current = false

          if (changed) {
            setNextFormValues(newValues)
            setChanges(auditObjDesc(formValues, newValues))
            setShowModal(true)
          }
        }
      }
    })

    return () => {
      unsubscribe()
    }
  }, [channel, queryClient, queryKey])

  if (!showModal) return null

  return (
    <Modal
      isOpen={true}
      onClose={() => setShowModal(false)}
      size="lg"
      closeOnOverlayClick={false}
      isKeyboardDismissable={false}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Object updated</Modal.Header>
        <Modal.Body>
          <VStack space={2}>
            <Text>This object was recently updated with the following changes:</Text>
            <Box p={2} bg="base.100">
              <MarkDown>{changes}</MarkDown>
            </Box>

            <Text>Would you like to update your form to reflect these changes?</Text>
            <HStack space={2} mt={2} justifyContent="center">
              <Button colorScheme="base" variant="outline" onPress={() => setShowModal(false)}>
                Ignore
              </Button>
              <Button onPress={onResetForm}>Update my form</Button>
            </HStack>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
