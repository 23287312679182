import { Apps, getAPIURL } from '../api_url'
import axios, { AxiosResponse } from "axios"
import React, { PropsWithChildren, useContext, useEffect, useState } from "react"
import { BankConnection, CabbagePay, GuestBankConnectionApi } from "./model"
import loadScript from '../utils/external-lib'
import { Maybe } from 'app/types'

const BankConnectionCtx = React.createContext<GuestBankConnectionApi | null>(null)
export function BankConnectionSvc(props: PropsWithChildren<{}>) {
  const [cabbagePay, setCabbagePay] = useState<Maybe<CabbagePay>>(null)
  const [error, setError] = useState<Maybe<string>>(null)

  useEffect(() => {
    loadScript(getAPIURL(Apps.CABBAGE_JS_API), 'cabbage').then(setCabbagePay).catch(() => setError("Could not load CabbagePay API"))
  }, [])

  const api = {
    loadBankConnection: (uuid: string): Promise<AxiosResponse<BankConnection>> =>
      axios.get(getAPIURL(Apps.PUBLIC_BANK_CONNECTION_API) + `/${uuid}`),
    initTender: (bankConnection: BankConnection): Promise<AxiosResponse<{ token: string }>> =>
      axios.post(getAPIURL(Apps.PUBLIC_BANK_CONNECTION_API) + `/${bankConnection.id}/init`),
    saveToken: (bc: BankConnection, token: string): Promise<AxiosResponse<BankConnection>> =>
      axios.post(getAPIURL(Apps.PUBLIC_BANK_CONNECTION_API) + `/${bc.id}/set_token`, { token }),
    cabbagePay,
    error,
  }
  return <BankConnectionCtx.Provider value={api}>{props.children}</BankConnectionCtx.Provider>
}

export function useBankConnectionAPI(): GuestBankConnectionApi {
  const context = useContext(BankConnectionCtx) as GuestBankConnectionApi
  return context
}

