import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export default function FormErrorsScroller() {
  const { formState } = useFormContext()

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      const el = document.querySelector('.field-error-message')
      if (!!el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }
    }
  }, [formState.submitCount])

  return null
}
