import { Maybe, PaginationResponse } from 'app/types'
import { AxiosResponse } from 'axios'

export const ROLE_VIEW = 'bank_connections'
export const ROLE_ADMIN = 'bank_connections_admin'

export type BankConnectionResponse = PaginationResponse<BankConnection>

// export interface Organizable {
//   parent_access_group_id: number
// }
export type Organizable<T> = T & { parent_access_group_id: number }
export interface BankConnectionApi {
  save(bc: Partial<BankConnection>, parent_access_group_id?: number): Promise<AxiosResponse<BankConnection>>
  loadPage: (pageNumber: number, name: string | null) => Promise<AxiosResponse<BankConnectionResponse>>
  search: (name: string, pageNumber?: number) => Promise<AxiosResponse<BankConnectionResponse>>
  loadBankConnection: (id: number) => Promise<AxiosResponse<BankConnection>>
  initTender: (bc: BankConnection) => Promise<AxiosResponse<{ token: string }>>
  saveToken: (bc: BankConnection, token: string) => Promise<AxiosResponse<BankConnection>>
  delete: (bankConnection: BankConnection) => Promise<void>
  cabbagePay: CabbagePay
  error: string
}

export interface GuestBankConnectionApi {
  loadBankConnection: (uuid: string) => Promise<AxiosResponse<BankConnection>>
  initTender: (bc: BankConnection) => Promise<AxiosResponse<{ token: string }>>
  saveToken: (bc: BankConnection, token: string) => Promise<AxiosResponse<BankConnection>>
  cabbagePay: CabbagePay
  error: string
}

export interface BankConnection {
  id: number
  name: string
  uuid: string
  contact_first_name: string
  contact_last_name: string
  contact_email: string
  contact_phone: string
  address: string
  bank_account_last_four: Maybe<string>
  bank_name: Maybe<string>
  description: Maybe<string>
  error_code: Maybe<string>
  error_message: Maybe<string>
  has_token: boolean
  healthy: boolean
  limited_access_token: boolean
  routing_number: string
  account_number: string
}

export type BankConnectionForm = Pick<
  BankConnection,
  | 'id'
  | 'name'
  | 'description'
  | 'contact_first_name'
  | 'contact_last_name'
  | 'contact_email'
  | 'contact_phone'
  | 'address'
  | 'routing_number'
  | 'account_number'
>

export interface CabbagePay {
  initializeGrid: (link_token: string) => void
  openGrid: (link_token: string) => void
  closeGrid: () => void
  initializeFixGrid: (bank_token: string) => void
  openFixGrid: (bank_token: string) => void
  closeFixGrid: () => void
}

export function isValidRoutingNumber(routingNumber: string): boolean {
  // Check if the routing number is exactly 9 digits long
  if (routingNumber.length !== 9 || !/^\d{9}$/.test(routingNumber)) {
    return false // Must be exactly 9 digits
  }

  const weights = [3, 7, 1] // Weight pattern
  let sum = 0

  // Apply weights to the first 8 digits
  for (let i = 0; i < 8; i++) {
    sum += parseInt(routingNumber[i]) * weights[i % 3]
  }

  // Calculate the checksum (sum + 9th digit) and check if it's divisible by 10
  return (sum + parseInt(routingNumber[8])) % 10 === 0
}

export function usesManualConnection(c: Maybe<BankConnection>): boolean {
  return !!c && !!c.routing_number && !!c.account_number
}
