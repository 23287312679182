import React, { useState, useRef, useEffect, useCallback, useLayoutEffect, createRef } from 'react'
import CalendarPicker from 'react-native-calendar-picker'
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons'
import { Box, Icon, useToken, useColorModeValue, IButtonProps, Popover, Pressable, Text } from 'native-base'
import { Button } from 'ui'

interface DateProps {
  [key: string]: any
}

type Props = IButtonProps & DateProps

const DatePickerSingleView = ({ buttonProps, popoverProps, calendarCustomProps, onYearChange, onMonthChange, ...props }: Props) => {
  useEffect(() => {
    document.addEventListener('click', handleClickOnPage, true);
    return () => {
      document.removeEventListener('click', handleClickOnPage, true);
    };
  }, [])

  const { calendarView } = calendarCustomProps

  const calendarRef = useRef()
  const [showPopover, setShowPopover] = useState(false)

  const calendarRefCb = useCallback((node) => {
    calendarRef.current = node;
    if (calendarRef.current) {
      changeCalendarView(calendarView)
    }
  }, [])

  const [textColor, disableDatesTextColor, navIconColor] = useToken('colors', [
    useColorModeValue('base.800', 'base.50'),
    useColorModeValue('base.400', 'base.500'),
    useColorModeValue('base.800', 'base.50'),
  ])

  const baseProps = {
    allowRangeSelection: false,
    previousComponent: <Icon size={[4, 8]} as={MaterialIcons} name="navigate-before" color={navIconColor} />,
    nextComponent: <Icon size={[4, 8]} as={MaterialIcons} name="navigate-next" color={navIconColor} />,
    width: 300,
    textStyle: {
      color: textColor,
    },
    disabledDatesTextStyle: {
      color: disableDatesTextColor,
    }
  }

  const allProps = { ...baseProps, ...props }

  // need to refactor, find a way to handle click/press on calendar
  function handleClickOnPage(event: Event) {
    setTimeout(() => {
      if (calendarRef && calendarRef.current) {
        if (calendarRef.current.state.currentView == "days") {
          if (calendarView == 'year') {
            onYearChange(calendarRef.current.state.currentYear)
          } else if (calendarView == 'month') {
            onMonthChange(calendarRef.current.state.currentMonth)
          }
          setShowPopover(false)
        }
      }
    }, 100)
  }

  function changeCalendarView(calendarView) {
    if (calendarView == 'month') {
      calendarRef.current.handleOnPressMonth()
    } else if (calendarView == 'year') {
      calendarRef.current.handleOnPressYear()
    }
  }

  return (
    <Popover
      isOpen={showPopover}
      onClose={setShowPopover}
      {...popoverProps}
      trigger={(triggerProps) => {
        return (
          <Button {...triggerProps}
            leftIcon={<Icon as={FontAwesome5} name="calendar-alt" size={4} />}
            onPress={() => setShowPopover(!showPopover)}
            {...buttonProps}
          >
          </Button>
        )
      }}>
      <Popover.Content w={80}>
        <Popover.Arrow />
        <Popover.Body>
          <CalendarPicker
            {...allProps}
            ref={calendarRefCb}
          />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  )
}

export default DatePickerSingleView