import Registry from './registry'
import { ButtonProps } from 'ui/components/button'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AuthApi } from '../authentication/model'
import { FontAwesome } from '@expo/vector-icons'
import { AG_ROLES } from '../access_groups/constants/ag-roles'
export interface AppModule {
  url: string
  enabled: boolean
  path: string
  name: string
  icon: ButtonProps['icon']
  iconFamily?: any
  /**
   * If `true`, the module button goes in active state only if the button `url` matches the entire URL pathname.
   */
  exactMatch?: boolean
  component: React.FC<{}>
  roles?: string[]
}

export function getAppModules(authApi: AuthApi): AppModule[] {
  return [
    {
      url: '/uidemo',
      path: '/uidemo/*',
      name: 'UI Demo',
      icon: 'palette',
      component: Registry.UIDemo,
      enabled: process.env.NODE_ENV == 'development',
    },
    {
      url: '/onboarding',
      path: '/onboarding/*',
      name: 'Restaurants',
      icon: 'restaurant',
      exactMatch: true,
      component: Registry.Onboarding,
      enabled: authApi.hasAgRole(AG_ROLES.RESTAURANTS_VIEW_ROLE),
      roles: [AG_ROLES.RESTAURANTS_VIEW_ROLE],
    },
    {
      url: '/orders',
      path: '/orders/*',
      name: 'Orders',
      icon: 'shopping-cart',
      component: Registry.Orders,
      enabled: false, //authApi.hasAgRole('order'),
    },
    {
      url: '/reports',
      path: '/reports/*',
      name: 'Reports',
      icon: 'insert-chart-outlined',
      component: Registry.Reports,
      enabled: authApi.hasAgRole(AG_ROLES.RESTAURANTS_VIEW_ROLE) || authApi.hasAgRole(AG_ROLES.CHANNELS_VIEW_ROLE),
      roles: [AG_ROLES.RESTAURANTS_VIEW_ROLE, AG_ROLES.CHANNELS_VIEW_ROLE],
    },
    {
      url: '/access_groups',
      path: '/access_groups/*',
      name: 'Access Groups',
      icon: 'groups',
      component: Registry.AccessGroups,
      enabled: authApi.hasAgRole(AG_ROLES.ACCESS_GROUPS_VIEW_ROLE),
      roles: [AG_ROLES.ACCESS_GROUPS_VIEW_ROLE],
    },
    {
      url: '/restaurants',
      path: '/restaurants/*',
      name: 'Restaurants',
      icon: 'restaurant',
      component: Registry.Restaurants,
      enabled: false, //authApi.hasAgRole(AG_ROLES.RESTAURANTS_VIEW_ROLE),
      roles: [AG_ROLES.RESTAURANTS_VIEW_ROLE],
    },
    {
      url: '/channels',
      path: '/channels/*',
      name: 'Channels',
      icon: 'leaf',
      iconFamily: MaterialCommunityIcons,
      component: Registry.Channels,
      enabled: authApi.hasAgRole(AG_ROLES.CHANNELS_VIEW_ROLE),
      roles: [AG_ROLES.CHANNELS_VIEW_ROLE],
    },
    {
      url: '/collections',
      path: '/collections/*',
      name: 'Collections',
      icon: 'collections',
      component: Registry.Collections,
      enabled: authApi.hasAgRole(AG_ROLES.PICTURE_COLLECTIONS_VIEW_ROLE),
      roles: [AG_ROLES.PICTURE_COLLECTIONS_VIEW_ROLE],
    },
    {
      url: '/gift_cards',
      path: '/gift_cards/*',
      name: 'Gift Cards',
      icon: 'credit-card',
      component: Registry.CardDefinitions,
      enabled: authApi.hasAgRole(AG_ROLES.CARD_DEFINITIONS_VIEW_ROLE),
      roles: [AG_ROLES.CARD_DEFINITIONS_VIEW_ROLE],
    },
    {
      url: '/bank-connections',
      path: '/bank-connections/*',
      name: 'My Bank',
      icon: 'bank',
      iconFamily: FontAwesome,
      component: Registry.BankConnections,
      enabled: authApi.hasAgRole(AG_ROLES.BANK_CONNECTIONS),
      roles: [AG_ROLES.BANK_CONNECTIONS],
    },
    {
      url: '/growth_services',
      path: '/growth_services/*',
      name: 'Growth Services',
      icon: 'scatter-plot',
      component: Registry.GrowthServices,
      enabled: false, //authApi.hasAgRole(AG_ROLES.GROWTH_SERVICES), Until further notice
      roles: [AG_ROLES.GROWTH_SERVICES],
    },
    {
      url: '/customers',
      path: '/customers/*',
      name: 'Customers',
      icon: 'groups',
      component: Registry.Customers,
      enabled: authApi.hasAgRole(AG_ROLES.CUSTOMERS_VIEW_ROLE) || authApi.hasAgRole(AG_ROLES.CUSTOMERS_ADMIN_ROLE),
      roles: [AG_ROLES.CUSTOMERS_VIEW_ROLE],
    },
    {
      url: '/native-apps',
      path: '/native-apps/*',
      name: 'Apps Manager',
      icon: 'phone-iphone',
      component: Registry.NativeApps,
      enabled: authApi.hasAgRole(AG_ROLES.NATIVE_APPS_VIEW_ROLE) || authApi.hasAgRole(AG_ROLES.NATIVE_APPS_ADMIN_ROLE),
      roles: [AG_ROLES.NATIVE_APPS_VIEW_ROLE],
    },
    {
      url: '/devices',
      path: '/devices/*',
      name: 'Devices',
      icon: 'devices',
      component: Registry.Devices,
      enabled: authApi.hasAgRole(AG_ROLES.DEVICES_VIEW_ROLE) || authApi.hasAgRole(AG_ROLES.DEVICES_ADMIN_ROLE),
      roles: [AG_ROLES.DEVICES_VIEW_ROLE],
    },
    {
      url: '/discounts',
      path: '/discounts/*',
      name: 'Discounts',
      icon: 'local-offer',
      component: Registry.Discounts,
      enabled: authApi.hasAgRole(AG_ROLES.DISCOUNTS_VIEW_ROLE) || authApi.hasAgRole(AG_ROLES.DISCOUNTS_ADMIN_ROLE),
      roles: [AG_ROLES.DISCOUNTS_VIEW_ROLE, AG_ROLES.DISCOUNTS_ADMIN_ROLE],
    },
  ]
}
