import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { AuthApi, LoggedInUser, LoginMethod } from './model'
import { addAuthorization, addContentType, addHeadersToAxios, compose } from './utils'
import { Navigate, useLocation } from 'react-router'

export function Service(props: PropsWithChildren<{}>): JSX.Element {
  const [me, setMe] = useState<LoggedInUser | null>(null)
  const [loading, setLoading] = useState(true)

  const onLogin = (user: LoggedInUser) => {
    setMe(user)
    localStorage.setItem('me', JSON.stringify(user))
    addHeadersToAxios(user.oauth_token)
  }

  const onLogout = () => {
    setMe(null)
    localStorage.removeItem('me')
    window.location.reload()
  }

  useEffect(() => {
    if (me && me.token_expires_at) {
      const timeout = setTimeout(() => onLogout, me?.token_expires_at * 1000 - Date.now())
      return () => clearTimeout(timeout)
    }
  }, [me])

  const isSessionActive = useMemo(() => me && me?.token_expires_at > Math.floor(Date.now() / 1000), [me])

  const api: AuthApi = {
    hasAgRole: (role: string): boolean => !!me && me.ag_roles.includes(role),
    authorize: (data: any) => compose([addAuthorization(me?.oauth_token || ''), addContentType], data),
    agentInfo: () => `${me.name} <${me.email}>`,
    onLogin,
    onLogout,
    isLoggedIn: () => isSessionActive || false,
    userInfo: me as LoggedInUser,
  }

  const apiUnauth: AuthApi = {
    hasAgRole: (_role: string): boolean => false,
    authorize: (_data: any) => {},
    agentInfo: () => `Guest User <nobody@zuppler.com>`,
    onLogin,
    onLogout,
    isLoggedIn: () => isSessionActive || false,
    userInfo: me as LoggedInUser,
  }

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('me') as string)
    if (savedUser) {
      setMe(savedUser)
      addHeadersToAxios(savedUser.oauth_token)
    }
    setLoading(false)
  }, [])

  // console.log('Security Service', isSessionActive ? api : apiUnauth)
  return loading ? <></> : <Ctx.Provider value={isSessionActive ? api : apiUnauth}>{props.children}</Ctx.Provider>
}

export function GuardedRoute(props: PropsWithChildren<{ roles?: string[] }>): JSX.Element {
  const api = useAuthAPI()
  const location = useLocation()

  if (api.isLoggedIn()) {
    if (!props.roles || !!props.roles.find(api.hasAgRole)) {
      return <>{props.children}</>
    }
  } else {
    return <Navigate to="/auth/sign-in" replace state={{ from: location }} />
  }
}

const Ctx = createContext<AuthApi | null>(null)

export function useAuthAPI(): AuthApi {
  const api = useContext(Ctx)
  return api as AuthApi
}

export function Restrict(props: PropsWithChildren<{ role?: string; hasAnyRole?: string[] }>): JSX.Element {
  const api = useAuthAPI()
  if (props.role) {
    if (!!props.role && api.hasAgRole(props.role)) {
      return <>{props.children}</>
    } else {
      return <></>
    }
  } else if (props.hasAnyRole) {
    return props.hasAnyRole.filter(api.hasAgRole).length > 0 ? <>{props.children}</> : <></>
  }
  return <></>
}
