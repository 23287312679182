import React from 'react'

import { HStack, Pressable, Icon } from 'ui'
import { Box, Text } from 'ui/components/responsive-primitives'
import { MaterialIcons } from '@expo/vector-icons'
import * as SVGIcon from 'ui/chef/icons'

export type TreeButtonType =
  | 'Root'
  | 'ItemOption'
  | 'ItemModifier'
  | 'ItemSize'
  | 'Item'
  | 'MenuItem'
  | 'MenuItemPivot'
  | 'Category'
  | 'Menu'
  | 'ModifierWithSizes'
  | 'Link'
  | 'POS'
  | 'Library'

const TreeIcon = {
  Root: SVGIcon.Home,
  ItemOption: SVGIcon.ModifierIngredient,
  ItemModifier: SVGIcon.ModifierGroup,
  ItemSize: SVGIcon.ItemSize,
  Item: SVGIcon.Item,
  MenuItem: SVGIcon.Item,
  MenuItemPivot: SVGIcon.Item,
  Category: SVGIcon.Category,
  Menu: SVGIcon.Menu,
  ModifierWithSizes: SVGIcon.ModifierGroup,
  Link: SVGIcon.Link,
  POS: SVGIcon.POS,
  Library: SVGIcon.ModifierGroup,
}

interface TreeButtonProps {
  label: string
  type: TreeButtonType
  level?: number
  active?: boolean
  isExpanded?: boolean
  isLast?: boolean
  hasChildren?: boolean
  isHovered?: boolean
  onToggle: () => void
  isVisible?: boolean
  isDynamic?: boolean
  variant?: 'base' | 'dependent' | 'library' | 'dynamic'
}

const iconColor = {
  base: 'base.500',
  dependent: 'info.600',
  library: 'purple.600',
  dynamic: 'cyan.600',
}
const textColor = {
  base: 'base.800',
  dependent: 'info.700',
  library: 'purple.800',
  dynamic: 'cyan.700',
}

const TreeButton = ({
  label,
  type,
  level = 0,
  active = false,
  isExpanded = true,
  isLast = false,
  hasChildren = true,
  isHovered = false,
  isVisible = true,
  isDynamic,
  onToggle,
  variant = 'base',
}: TreeButtonProps) => {
  // console.log('BSDBG : type:', label, type)
  const Indicator = isExpanded ? Expanded : Collapsed
  const BtnIcon = TreeIcon[variant === 'dependent' ? 'Link' : type]

  return (
    <HStack
      py={1}
      pr={2}
      alignItems="center"
      position="relative"
      style={{
        // @ts-ignore
        userSelect: 'none',
        height: 30,
        paddingLeft: level * 10 + 8,
      }}
      _light={{ bg: active ? 'base.200' : isHovered ? 'base.100' : 'transparent' }}
      _dark={{ bg: active ? 'base.700' : isHovered ? 'base.800' : 'transparent' }}>
      {(type === 'ItemModifier' || type === 'ItemOption' || type === 'ModifierWithSizes') && <Branch isLast={isLast} />}
      <Box style={{ width: 7, height: 7 }}>
        <Pressable style={{ width: 7, height: 7 }} onPress={onToggle}>
          {hasChildren && <Indicator />}
        </Pressable>
      </Box>
      <Box color={iconColor[variant]}>
        <BtnIcon />
      </Box>

      <Text fontWeight={active ? 'bold' : 'normal'} color={textColor[variant]} isTruncated flex={1}>
        {label}
      </Text>
      {!isVisible && <SVGIcon.PausedIcon width={12} height={12} />}
      {isDynamic && <Icon as={MaterialIcons} size="4" name="auto-awesome" color="cyan.600" />}
      <div className={active ? 'active-menu-comp' : ''} />
    </HStack>
  )
}

const Branch = ({ isLast }: { isLast: boolean }) => (
  <svg width="13" height="30" viewBox="0 0 13 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="3" y1="0" x2="3" y2={isLast ? '15' : '30'} stroke="#D1D5DB" />
    <line x1="3" y1="14.5" x2="10" y2="14.5" stroke="#D1D5DB" />
  </svg>
)

const Collapsed = () => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 3.50001L2 0V7L6 3.50001Z" fill="#9CA3AF" />
  </svg>
)
const Expanded = () => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.49999 6L7 2L0 2L3.49999 6Z" fill="#9CA3AF" />
  </svg>
)

export default TreeButton
