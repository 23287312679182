import React, { useEffect, useRef, useState } from 'react'
import { Button, HStack, VStack, Text, Link, Divider, FormControl, IconButton, Icon, Hidden, Input } from 'native-base'

import { MaterialIcons } from '@expo/vector-icons'

import { Controller, useForm } from 'react-hook-form'
import { AuthenticationApis } from '../api/service'
import { LoggedInUser, LoginMethod } from '../model'
import { Ionicons } from '@expo/vector-icons'
import { AnimateAndRender } from './index'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuthAPI } from '../../authentication'

export default function AcceptInviteForm(props: {
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
}) {
  const [showPass, setShowPass] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const formMethods = useForm()

  const password = useRef({})
  password.current = formMethods.watch('password', undefined)

  const authApi = useAuthAPI()

  const onSubmit = (params: any) => {
    setLoading(true)
    AuthenticationApis.acceptInvite(params)
      .then((u) => props.onLoginInSuccess(u, LoginMethod.AcceptInvite))
      // .then(() => window.navigation.navigate('/'))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    formMethods.setValue('email', searchParams.get('email'))
    formMethods.setValue('token', searchParams.get('token'))
  }, [])

  const validation = {
    name: {
      required: 'Name is required',
      minLength: { value: 3, message: 'Too few characters' },
    },
    password: {
      required: 'Password is required',
      minLength: { value: 6, message: 'Minimun 6 characters' },
    },
    confirm_password: {
      validate: (value: string) => {
        if (!value && !password.current) return null
        return value === password.current || 'Passwords do not match'
      },
    },
    phone: {
      required: 'Contact number is required',
      pattern: {
        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        message: 'must be a valid phone number',
      },
    },
  }

  if (authApi.isLoggedIn()) {
    return <Navigate to="/onboarding/welcome" />
  }

  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '40px', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '40px', md: '0' }}>
      <AnimateAndRender>
        <VStack space="7">
          <Hidden till="md">
            <Text bold _light={{ color: 'coolGray.800' }} _dark={{ color: 'primary.100' }}>
              Welcome, Continue Registration To Accept Invitation!
            </Text>
          </Hidden>
          <VStack>
            <VStack space="3">
              <VStack space={{ base: '7', md: '4' }}>
                <FormControl isInvalid={'email' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        isReadOnly
                        placeholder="Email"
                        _dark={{
                          borderColor: 'coolGray.700',
                        }}
                        InputLeftElement={
                          <Icon as={<MaterialIcons name="email" />} size={5} mx="2" color="coolGray.400" />
                        }
                        _light={{
                          borderColor: 'primary.200',
                          bgColor: 'coolGray.100',
                        }}
                      />
                    )}
                    name="email"
                  />
                  <FormControl.ErrorMessage>{formMethods.formState.errors.email?.message}</FormControl.ErrorMessage>
                </FormControl>

                <HStack justifyContent="space-between" display="flex">
                  <FormControl isRequired isInvalid={'name' in formMethods.formState.errors} w="48%">
                    <Controller
                      control={formMethods.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          size="md"
                          placeholder="Name"
                          autoFocus
                          InputLeftElement={
                            <Icon as={<MaterialIcons name="person" />} size={4} mx="2" color="coolGray.400" />
                          }
                          _dark={{
                            borderColor: 'coolGray.700',
                          }}
                          _light={{
                            borderColor: 'coolGray.300',
                          }}
                        />
                      )}
                      name="name"
                      rules={validation.name}
                    />
                    <FormControl.ErrorMessage>{formMethods.formState.errors.name?.message}</FormControl.ErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={'phone' in formMethods.formState.errors} w="48%">
                    <Controller
                      control={formMethods.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          size="md"
                          placeholder="Phone"
                          InputLeftElement={
                            <Icon as={<MaterialIcons name="phone" />} size={4} mx="2" color="coolGray.400" />
                          }
                          _dark={{
                            borderColor: 'coolGray.700',
                          }}
                          _light={{
                            borderColor: 'coolGray.300',
                          }}
                        />
                      )}
                      name="phone"
                      rules={validation.phone}
                    />
                    <FormControl.ErrorMessage>{formMethods.formState.errors.phone?.message}</FormControl.ErrorMessage>
                  </FormControl>
                </HStack>

                <FormControl isRequired isInvalid={'password' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Password"
                        InputRightElement={
                          <IconButton
                            variant="unstyled"
                            icon={
                              <Icon
                                size="4"
                                color="coolGray.400"
                                as={MaterialIcons}
                                name={showPass ? 'visibility-off' : 'visibility'}
                              />
                            }
                            onPress={() => {
                              setShowPass(!showPass)
                            }}
                          />
                        }
                        _dark={{
                          borderColor: 'coolGray.700',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="password"
                    rules={validation.password}
                  />
                  <FormControl.ErrorMessage>{formMethods.formState.errors.password?.message}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={'confirm_password' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        _dark={{
                          borderColor: 'coolGray.700',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="confirm_password"
                    rules={validation.confirm_password as any}
                  />
                  <FormControl.ErrorMessage>
                    {formMethods.formState.errors.confirm_password?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
              {/* <Link
                ml="auto"
                _text={{
                  fontSize: 'xs',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}
                _light={{
                  _text: {
                    color: 'primary.700',
                  },
                }}
                _dark={{
                  _text: {
                    color: 'primary.400',
                  },
                }}>
                Forgot password?
              </Link> */}

              {error && (
                <Text fontWeight="bold" color="error.700">
                  <Icon as={Ionicons} size="md" name={'warning'} color="error.700" />
                  {error}
                </Text>
              )}

              <Button
                mt="5"
                size="md"
                borderRadius="4"
                isLoading={loading}
                isLoadingText="Signing In"
                _text={{
                  fontWeight: 'medium',
                }}
                onPress={formMethods.handleSubmit(onSubmit)}>
                REGISTER
              </Button>
              <HStack mt="5" space="2" mb={{ base: 6, md: 7 }} alignItems="center" justifyContent="center">
                {/* <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                <Text fontWeight="medium" _light={{ color: 'coolGray.300' }} _dark={{ color: 'coolGray.500' }}>
                  or
                </Text>
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider> */}
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <HStack
          mb="4"
          space="1"
          safeAreaBottom
          alignItems="center"
          justifyContent="center"
          mt={{ base: 'auto', md: '8' }}>
          {/* <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
            Don't have an account?
          </Text>
          <Link
            _text={{
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            _light={{
              _text: {
                color: 'primary.900',
              },
            }}
            _dark={{
              _text: {
                color: 'primary.500',
              },
            }}
            onPress={() => {}}>
            Sign up
          </Link> */}
        </HStack>
      </AnimateAndRender>
    </VStack>
  )
}

// function useQueryParams() {
//   const urlSearchParams = useSearchParams()
//   console.log(urlSearchParams.ge)
//   const params = Object.fromEntries(urlSearchParams.entries())
//   return params
// }
