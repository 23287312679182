import React, { useEffect, useState } from 'react'

interface GeneratorApi<T> {
  data?: T
  loading: boolean
  error?: any
  start: () => void
}

interface Options {
  autoRun?: boolean
}

export default function useTaskGenerator<T>(
  generatorFn: () => IterableIterator<Promise<T>> | AsyncGenerator<T>,
  options: Options = {}
) {
  const [state, setState] = useState<GeneratorApi<T>>({ loading: true, start: () => {} })

  useEffect(() => {
    async function iterate(gen: IterableIterator<Promise<T>> | AsyncGenerator<T>) {
      try {
        const { value, done } = (await gen.next()) as { value: T; done?: boolean }
        if (!done) {
          setState((prev) => ({ ...prev, loading: true, data: value }))
          iterate(gen)
        } else {
          setState((prev) => ({ ...prev, loading: false, data: value }))
        }
      } catch (error) {
        setState((prev) => ({ ...prev, loading: false, error }))
      }
    }
    function start() {
      setState((prev) => ({ ...prev, loading: true }))
      iterate(generatorFn())
    }
    if (options.autoRun) iterate(generatorFn())
    setState((prev) => ({ ...prev, start }))
  }, [])

  return state
}
