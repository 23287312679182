import { Apps, getAPIURL } from '../api_url'
import axios, { AxiosResponse } from 'axios'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { BankConnection, BankConnectionApi, BankConnectionResponse, CabbagePay } from './model'
import loadScript from '../utils/external-lib'
import { Maybe } from 'app/types'

const BankConnectionCtx = React.createContext<BankConnectionApi | null>(null)
export function BankConnectionSvc(props: PropsWithChildren<{}>) {
  const [cabbagePay, setCabbagePay] = useState<Maybe<CabbagePay>>(null)
  const [error, setError] = useState<Maybe<string>>(null)

  useEffect(() => {
    loadScript(getAPIURL(Apps.CABBAGE_JS_API), 'cabbage')
      .then(setCabbagePay)
      .catch(() => setError('Could not load CabbagePay API'))
  }, [])

  const api = {
    loadPage: (pageNumber: number, name = null): Promise<AxiosResponse<BankConnectionResponse>> =>
      axios.post(getAPIURL(Apps.BANK_CONNECTION_API) + '/search', { page: pageNumber, per_page: 20, name }),
    search: (name: string, pageNumber = 1): Promise<AxiosResponse<BankConnectionResponse>> =>
      axios.post(getAPIURL(Apps.BANK_CONNECTION_API) + '/search', { page: pageNumber, per_page: 20, name }),
    save: (bc: Partial<BankConnection>, parent_access_group_id?: number): Promise<AxiosResponse<BankConnection>> => {
      const url = getAPIURL(Apps.BANK_CONNECTION_API)
      return bc.id
        ? axios.put(`${url}/${bc.id}`, { bank_connection: bc, parent_access_group_id })
        : axios.post(`${url}`, { bank_connection: bc, parent_access_group_id })
    },
    loadBankConnection: (id: number): Promise<AxiosResponse<BankConnection>> =>
      axios.get(getAPIURL(Apps.BANK_CONNECTION_API) + `/${id}`),
    initTender: (bankConnection: BankConnection): Promise<AxiosResponse<{ token: string }>> =>
      axios.post(getAPIURL(Apps.BANK_CONNECTION_API) + `/${bankConnection.id}/init`),
    saveToken: (bc: BankConnection, token: string): Promise<AxiosResponse<BankConnection>> =>
      axios.post(getAPIURL(Apps.BANK_CONNECTION_API) + `/${bc.id}/set_token`, { token }),
    delete: (bc: BankConnection): Promise<void> => axios.delete(getAPIURL(Apps.BANK_CONNECTION_API) + `/${bc.id}`),
    cabbagePay,
    error,
  }
  return <BankConnectionCtx.Provider value={api}>{props.children}</BankConnectionCtx.Provider>
}

export function useBankConnectionAPI(): BankConnectionApi {
  const context = useContext(BankConnectionCtx) as BankConnectionApi
  return context
}
