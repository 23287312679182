import React, { useState, useEffect } from 'react'
import { useToken, useColorModeValue, VStack, useMediaQuery } from 'native-base'
import { Breakpoint } from 'ui'
import { Center, HStack, Icon, Box, Text } from 'native-base'
import { VictoryPie, VictoryTooltip, VictoryLabel } from "victory-native"
import { FontAwesome } from '@expo/vector-icons'

interface ChartProps {
	[key: string]: any
}

interface LabelProps {
	datum?: any
	x?: number
	y?: number
}

const PieChartSimple = ({ data, accesor, width, ...rest }: ChartProps) => {
	// for animation
	const [chartData, setChartData] = useState([])
	const [endAngle, setEndAngle] = useState(0)

	const [isLargeScreen] = useMediaQuery({
		minWidth: 800
	})

	useEffect(() => {
		setTimeout(() => {
			setChartData(data);
			setEndAngle(360);
		}, 100);
	}, [])

	const colorScale = useToken("colors",
		[
			'rose.600', 'fuchsia.900', 'emerald.800', 'purple.700', 'cyan.900',
			'yellow.800', 'red.600', 'warmGray.700', 'teal.800', 'darkBlue.700'
		]
	)

	const [
		toolTipBorderColor, toolTipBgColor, toolTipTextColor
	] = useToken("colors",
		[
			useColorModeValue('primary.800', 'primary.200'),
			useColorModeValue('base.100', 'base.200'),
			useColorModeValue('primary.600', 'primary.600'),
		]
	)

	function getItemAmountPercentange(itemAmount: number) {
		const totalAmount = data.map(item => item[accesor]).reduce((prev, curr) => prev + curr, 0)
		return Math.ceil((itemAmount / totalAmount) * 100)
	}

	const ItemInfoLabel = (props) => (
		<Text
			fontSize={16}
			_light={{ color: 'base.800' }}
			_dark={{ color: 'base.200' }}
			{...props}
		/>
	)

	const ItemInfo = () => {
		return (
			<Box>
				{data.map((dt, index) => {
					return (
						<HStack space={3} alignItems="center" key={index}>
							<Icon
								size="4"
								color={colorScale[index]}
								as={FontAwesome}
								name="square"
							/>
							<ItemInfoLabel>{dt.item}</ItemInfoLabel>
						</HStack>
					)
				})}
			</Box>
		)
	}


	const CustomToolTip = (props: LabelProps) => {
		const { datum } = props
		const tooltipLabel = datum.tooltip_label
		return (
			<g>
				<VictoryLabel {...props} />
				<VictoryTooltip
					{...props}
					text={tooltipLabel}
					flyoutStyle={{
						stroke: toolTipBorderColor,
						strokeWidth: 0.5,
						fill: toolTipBgColor,

					}}
					flyoutPadding={8}
					style={{ fill: toolTipTextColor }}
				/>
			</g>
		)
	}

	// set tooltip default events (hoverin/hoverout) only for Desktop
	if (isLargeScreen) {
		CustomToolTip.defaultEvents = VictoryTooltip.defaultEvents
	}

	const chartConfig: any = {
		animate: {
			duration: 2000,
		},
		endAngle: endAngle,
		colorScale: colorScale,
		innerRadius: 20,
		labelRadius: 100,
		style: { labels: { fill: "white", fontWeight: "bold" } },
		y: accesor,
	}

	return (
		<Box>
			<Breakpoint size={'desktop'}>
				<HStack space={4}>
					<Box w={width}>
						<VictoryPie
							data={chartData}
							labels={({ datum }) => `${getItemAmountPercentange(datum[accesor])}%`}
							labelComponent={<CustomToolTip />}
							{...chartConfig}
						/>
					</Box>
					<Center>
						<ItemInfo />
					</Center>
				</HStack>
			</Breakpoint>
			<Breakpoint size={['phone', 'tablet']}>
				<VStack space={0}>
					<Box w={width}>
						<VictoryPie
							data={chartData}
							labels={({ datum }) => `${getItemAmountPercentange(datum[accesor])}%`}
							labelComponent={<CustomToolTip />}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: () => {
											return [
												{
													// reset all labels active value to false
													eventKey: "all",
													target: 'labels',
													mutation: () => ({ active: false }),
												},
												{
													// set respected label active value to true
													target: 'labels',
													mutation: () => ({ active: true }),
												}
											];
										},
									},
								},
							]}
							{...chartConfig}
						/>
					</Box>
					<Center>
						<ItemInfo />
					</Center>
				</VStack>
			</Breakpoint>
		</Box>
	)
}

export default PieChartSimple