export function onlyNumbers(input: string) {
  return input.replace(/[^0-9\.]/g, '')
}

export function onlyNumbersSeparatedBySlashes(input: string | number) {
  const str = String(input)
  const matches = str.match(/(\d+(\.\d*)?)(?=\/|$)/g)

  let result = matches ? matches.join('/') : ''

  if (str.endsWith('/')) {
    result += '/'
  }

  return result
}

export function onlyNumbersAndMinusesSeparatedBySlashes(input: string | number) {
  const str = String(input)
  const matches = str.match(/(\d+(\.\d*)?|-)(?=\/|$)/g)

  let result = matches ? matches.join('/') : ''

  if (str.endsWith('/')) {
    result += '/'
  }

  return result
}

export function pricesStringToArray(input: string | number) {
  return String(input)
    .split('/')
    .filter((s) => s.length > 0)
    .map((p) => (p.trim() === '-' ? '-' : !isNaN(Number(p)) ? Number(p) : 0))
}
