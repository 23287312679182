import React, { lazy, Suspense } from 'react'
import { Box, Text } from '../ui'

const UIDemo = lazy(() => import('../ui-demo'))
// const SignIn = lazy(() => import('../authentication/sign-in'))
const Orders = lazy(() => import('../orders'))
const Reports = lazy(() => import('../reports'))
const AccessGroups = lazy(() => import('../access_groups'))
const Restaurants = lazy(() => import('../restaurants'))
const Channels = lazy(() => import('../channels'))
const Collections = lazy(() => import('../collections'))
const BankConnections = lazy(() => import('../bank-connection'))
const CardDefinitions = lazy(() => import('../gift-cards'))
const GrowthServices = lazy(() => import('../growth_service'))
const Onboarding = lazy(() => import('../self-onboarding'))
const RedeemGC = lazy(() => import('../gift-cards/redeem'))
const Customers = lazy(() => import('../customers'))
const NativeApps = lazy(() => import('../native-apps'))
const Devices = lazy(() => import('../devices'))
const Discounts = lazy(() => import('../zuppler_discounts'))

const loadComp = (Comp: React.FC) => () => (
  <Suspense
    fallback={
      <Box>
        <Text>Loading...</Text>
      </Box>
    }
  >
    <Comp />
  </Suspense>
)

export default {
  UIDemo: loadComp(UIDemo),
  // SignIn: loadComp(SignIn),
  Orders: loadComp(Orders),
  Reports: loadComp(Reports),
  AccessGroups: loadComp(AccessGroups),
  Restaurants: loadComp(Restaurants),
  Channels: loadComp(Channels),
  Collections: loadComp(Collections),
  BankConnections: loadComp(BankConnections),
  CardDefinitions: loadComp(CardDefinitions),
  GrowthServices: loadComp(GrowthServices),
  Onboarding: loadComp(Onboarding),
  RedeemGC: loadComp(RedeemGC),
  Customers: loadComp(Customers),
  NativeApps: loadComp(NativeApps),
  Devices: loadComp(Devices),
  Discounts: loadComp(Discounts),
}
