import React, { createContext, useEffect, useState } from 'react'
import { Channel, Socket } from 'phoenix'
import { Apps, getAPIURL } from '../api_url'
import { useAuthAPI } from '../authentication'

export const PhoenixSocketContext = createContext<{ socket: Socket | null; channel: Channel | null }>({
  socket: null,
  channel: null,
})

const consoleLogger = (kind: string, msg: string, data: any) => {
  console.log(`ZSOCKET: ${kind}: ${msg}`, data)
}
const noLogger = () => {}

export function PhoenixChannelProvider({
  children,
  channelName = 'ZupplerChef',
}: React.PropsWithChildren<{ channelName: string }>) {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [channel, setChannel] = useState<Channel | null>(null)

  const authApi = useAuthAPI()
  const isLoggedIn = authApi.isLoggedIn()

  useEffect(() => {
    if (isLoggedIn && authApi.userInfo) {
      const url = getAPIURL(Apps.SYNC_SERVICE_SOCKET)
      const _socket = new Socket(url, {
        params: { token: authApi.userInfo.oauth_token },
        logger: noLogger, //consoleLogger,
        timeout: 30000,
        rejoinAfterMs: () => 5000,
      })

      const phoenixChannel = _socket.channel(`sync:${channelName}`)

      const onOpenRef = _socket.onOpen(() => {
        phoenixChannel
          .join()
          .receive('ok', (resp) => {
            console.log('WS: Channel joined', resp)
            setChannel(phoenixChannel)
          })
          .receive('error', (resp) => {
            console.log('WS: Unable to join', resp)
          })

        _socket.off([onOpenRef, onErrorRef])
      })

      const onErrorRef = _socket.onError(() => {
        console.log('WS: Could not connect to socket')
      })

      _socket.connect()
      setSocket(_socket)

      return () => {
        phoenixChannel.leave()
        _socket.disconnect()
      }
    }
  }, [isLoggedIn])

  return <PhoenixSocketContext.Provider value={{ socket, channel }}>{children}</PhoenixSocketContext.Provider>
}
