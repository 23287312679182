import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { Platform } from 'react-native'
import { RestaurantsGraphqlClient } from './src/graphql_clients'
import { PortalProvider } from '@gorhom/portal'
import { TaskRunnerProvider, TaskRunners } from './src/app/task-runner'
import { AppHintsProvider } from './src/app/hints'

import AppModules from './src/index'
import { Router } from './src/router'

import { KeyboardAvoidingView } from 'native-base'
import { UIProvider } from './src/ui'

import * as App from './src/app/service'

import 'dotenv/config'
import { NavProvider } from './src/views/root-navigation'
import * as Security from './src/authentication/index'
import { AuditService } from './src/audit/service'
import { UISecurityProvider } from './src/ui/ui-security'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PhoenixChannelProvider } from './src/sockets-sync/phoenix-channel'
import { QuerySyncService } from './src/sockets-sync/query-sync-service'
import QueryAuditLogger from './src/audit/query-audit-logger'

import * as Sentry from '@sentry/react'

import { version } from './package.json'

if (process.env.ENV) {
  Sentry.init({
    dsn: 'https://c365042931df98dfa4fd9e73b9c55004@sentry.zuppler.com/3',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/chef\.zuppler\.test/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: false,
    release: version,
    environment: process.env.ENV || 'development',
  })
}

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     staleTime: 60 * 1000, // 60 seconds
  //   },
  // },
})

export default function AppRoot() {
  return (
    <App.ConfigService services={App.buildFromEnv()}>
      <Security.Service>
        <AuditService appId="chef">
          <QueryClientProvider client={queryClient}>
            <PhoenixChannelProvider channelName="ZupplerChef">
              <QuerySyncService queryClient={queryClient}>
                <QueryAuditLogger queryClient={queryClient} />
                <RestaurantsGraphqlClient>
                  <Router>
                    <PortalProvider>
                      <UIProvider>
                        <UISecurityProvider>
                          <NavProvider>
                            <StatusBar style={'dark'} animated={true} />
                            <KeyboardAvoidingView
                              behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                              keyboardVerticalOffset={0}
                              style={{ flex: 1 }}
                              overflow="hidden">
                              <TaskRunnerProvider>
                                <AppHintsProvider>
                                  <AppModules />
                                </AppHintsProvider>
                                <TaskRunners />
                              </TaskRunnerProvider>
                            </KeyboardAvoidingView>
                          </NavProvider>
                        </UISecurityProvider>
                      </UIProvider>
                    </PortalProvider>
                  </Router>
                </RestaurantsGraphqlClient>
              </QuerySyncService>
            </PhoenixChannelProvider>
          </QueryClientProvider>
        </AuditService>
      </Security.Service>
    </App.ConfigService>
  )
}
