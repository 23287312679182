import React from 'react'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip, VictoryLabel } from 'victory-native'
import victoryTheme from 'ui/theme/victory'
import { useToken, useColorModeValue, useBreakpointValue, Text, Box, useMediaQuery } from 'native-base'

interface ChartProps {
  [key: string]: any
}

const BarChartSimple = ({
  data,
  width,
  yAxisLabelPrefix,
  yAxisLabelSuffix,
  yAxisAccessor,
  xAxisAccessor,
  xAxisLabel,
  yAxisLabel,
  ...rest
}: ChartProps) => {
  const [
    barColorDefault,
    barColorOnAction,
    xAxisTickLabelColor,
    yAxisTickLabelColor,
    gridColor,
    toolTipBorderColor,
    toolTipBgColor,
    toolTipTextColor,
    axisLabelColor,
    axisColor,
  ] = useToken('colors', [
    useColorModeValue('green.500', 'green.600'),
    useColorModeValue('primary.500', 'primary.600'),
    useColorModeValue('base.700', 'base.200'),
    useColorModeValue('base.700', 'base.200'),
    useColorModeValue('base.500', 'base.500'),
    useColorModeValue('primary.800', 'primary.200'),
    useColorModeValue('base.100', 'base.200'),
    useColorModeValue('primary.600', 'primary.600'),
    useColorModeValue('primary.600', 'primary.600'),
    useColorModeValue('base.400', 'base.500'),
  ])

  const [isLargeScreen] = useMediaQuery({
    minWidth: 800,
  })

  function onHoverBar(): any {
    return [
      {
        target: 'data',
        mutation: () => ({ style: { fill: barColorOnAction } }),
      },
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ]
  }

  function onHoverOutBar(): any {
    return [
      {
        target: 'data',
        mutation: () => {},
      },
      {
        target: 'labels',
        mutation: () => ({ active: false }),
      },
    ]
  }

  function onClickBar(): any {
    return [
      {
        eventKey: 'all',
        target: 'data',
        mutation: () => ({ style: { fill: barColorDefault } }),
      },
      {
        target: 'data',
        mutation: () => ({ style: { fill: barColorOnAction } }),
      },
      {
        eventKey: 'all',
        target: 'labels',
        mutation: () => ({ active: false }),
      },
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ]
  }

  function getYAxisTickLabel(text: string): string {
    text = yAxisLabelPrefix ? yAxisLabelPrefix + text : text
    text = yAxisLabelSuffix ? text + yAxisLabelSuffix : text
    return text
  }

  const chartCofing: any = {
    theme: victoryTheme,
    width,
    animate: {
      duration: 400,
      onLoad: { duration: 400 },
    },
    domainPadding: 25,
    padding: { top: 50, bottom: 50, left: 70, right: 20 },
  }

  const XAxisConfig: any = {
    crossAxis: true,
    axisLabelComponent: <VictoryLabel dy={25} style={{ fontSize: 15, fill: axisLabelColor }} />,
    label: xAxisLabel,
    style: {
      grid: { stroke: gridColor, strokeDasharray: '2 7', opacity: 0.3 },
      tickLabels: { fill: xAxisTickLabelColor, fontSize: 13 },
      axis: { stroke: axisColor },
    },
  }

  const YAxisConfig: any = {
    dependentAxis: true,
    crossAxis: false,
    axisLabelComponent: <VictoryLabel dy={-45} style={{ fontSize: 15, fill: axisLabelColor }} />,
    label: yAxisLabel,
    style: {
      grid: { stroke: gridColor, strokeDasharray: '2 7', opacity: 0.3 },
      tickLabels: { fill: yAxisTickLabelColor, fontSize: 13 },
      axis: { stroke: axisColor },
    },
    tickFormat: (t: any) => getYAxisTickLabel(t),
  }

  const barConfig: any = {
    labelComponent: (
      <VictoryTooltip
        flyoutStyle={{
          stroke: toolTipBorderColor,
          strokeWidth: 0.5,
          fill: toolTipBgColor,
        }}
        flyoutPadding={8}
        style={{ fill: toolTipTextColor }}
      />
    ),
    style: {
      data: { fill: barColorDefault },
    },
    alignment: 'middle',
    x: xAxisAccessor,
    y: yAxisAccessor,
  }

  return (
    <VictoryChart {...chartCofing}>
      <VictoryAxis {...XAxisConfig} />
      <VictoryAxis {...YAxisConfig} />
      <VictoryBar
        data={data}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => (isLargeScreen ? onHoverBar() : null),
              onMouseOut: () => (isLargeScreen ? onHoverOutBar() : null),
              onClick: () => (!isLargeScreen ? onClickBar() : null),
            },
          },
        ]}
        {...barConfig}
        {...rest}
      />
    </VictoryChart>
  )
}

export default BarChartSimple
