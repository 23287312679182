import React, { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { Animated, Image } from 'react-native'
import {
  Box,
  Button,
  Text,
  Icon,
  useColorMode,
  useColorModeValue,
  Pressable,
  Center,
  HStack,
  Avatar,
  PresenceTransition,
  useDisclose,
} from 'native-base'
import { MaterialIcons } from '@expo/vector-icons'
import logo from 'assets/zuppler-chef.svg'
import badge from 'assets/zuppler-chef-badge.svg'
import { Feather } from '@expo/vector-icons'
import { useLocation } from '../router'

import useBreakpoint from 'ui/hooks/use-breakpoint'
import { LinkButton, ScrollView, Menu } from 'ui'
import usePrevious from 'ui/hooks/use-previous'
import { useAuthAPI } from '../authentication'
import { useAppHintsApi } from 'app/hints'

type NavLinkProps = {
  to: string
  label: string
  icon: string
  iconFamily: any
  exactMatch?: boolean
}

export interface NavAPI {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
}
export const NavCtx = createContext<NavAPI | null>(null)

export const NavProvider: React.FC = ({ children }: React.PropsWithChildren<any>) => {
  const breakpoint = useBreakpoint()
  const prevBreakpoint = usePrevious(breakpoint)
  const api: NavAPI = useDisclose(breakpoint === 'desktop' ? true : false)
  const location = useLocation()

  useEffect(() => {
    if (api.isOpen && prevBreakpoint === 'desktop' && breakpoint !== 'desktop') {
      api.onClose()
    }
  }, [prevBreakpoint, breakpoint])

  useEffect(() => {
    if (breakpoint !== 'desktop') {
      api.onClose()
    }
  }, [location])

  return <NavCtx.Provider value={api}>{children}</NavCtx.Provider>
}

export function NavLink({ to, label, icon, iconFamily, exactMatch = false }: NavLinkProps): JSX.Element {
  const breakpoint = useBreakpoint()
  const { isOpen } = useContext(NavCtx) as NavAPI

  const showLabel = isOpen || breakpoint !== 'desktop'

  return (
    <LinkButton
      to={to}
      // mx={2}
      my={0.5}
      px={2}
      py={2}
      colorScheme="base"
      bg="transparent"
      size="md"
      overflow="hidden"
      exactMatch={exactMatch}
      leftIcon={
        <IconTransition visible={showLabel}>
          <Icon as={iconFamily || MaterialIcons} name={icon} color="base.500" size="6" />
        </IconTransition>
      }
      justifyContent="flex-start"
      _active={{
        bg: 'base.700',
        leftIcon: (
          <IconTransition visible={showLabel}>
            <Icon as={iconFamily || MaterialIcons} name={icon} color="base.100" size="6" />
          </IconTransition>
        ),
      }}
      testID="main-nav-button">
      <PresenceTransition
        visible={showLabel}
        initial={{
          opacity: 0,
          translateX: -10,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 250,
            delay: 50,
          },
        }}>
        <Text color="base.300">{label}</Text>
      </PresenceTransition>
    </LinkButton>
  )
}

export const NavBox: React.FC<{ sidebar: boolean; children: React.ReactElement[] }> = ({ sidebar, children }) => {
  const { isOpen, onOpen, onClose, onToggle } = useContext(NavCtx) as NavAPI
  const toggleAnim = useRef(new Animated.Value(isOpen ? 1 : 0)).current
  const breakpoint = useBreakpoint()
  const { toggleColorMode } = useColorMode()

  useEffect(() => {
    Animated.spring(toggleAnim, {
      toValue: isOpen ? 1 : 0,
      tension: 60,
      friction: 20,
      useNativeDriver: true,
    }).start()
  }, [isOpen])

  const style = sidebar
    ? {
        position: 'absolute',
        zIndex: 10,
        width: 200,
        top: 0,
        bottom: 0,
        right: -200,
        transform: [
          {
            translateX: toggleAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0, -200],
            }),
          },
        ],
      }
    : {
        position: 'relative',
        zIndex: 10,
        width:
          breakpoint === 'desktop'
            ? toggleAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [80, 200],
              })
            : '100%',
      }
  const backdropStyle = {
    flex: 1,
    opacity: toggleAnim,
  }

  const expandedUI = isOpen || breakpoint !== 'desktop'

  return (
    <>
      {sidebar && isOpen && (
        <Pressable onPress={onClose} style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
          <Animated.View style={backdropStyle}>
            <Box flex={1} bg="base.900" opacity={0.7} />
          </Animated.View>
        </Pressable>
      )}
      <Animated.View style={style as any}>
        <Box
          flexDirection="column"
          // alignItems="center"
          bg="base.900"
          _dark={{ borderRightWidth: 1, borderRightColor: 'base.800', borderRightStyle: 'solid' }}
          pt={{ base: '3' }}
          pb="lg"
          flex={1}>
          {breakpoint === 'desktop' && (
            <Button
              leftIcon={<Icon as={MaterialIcons} name={isOpen ? 'chevron-left' : 'chevron-right'} size="xs" />}
              size="xs"
              variant="solid"
              backgroundColor="base.900"
              onPress={onToggle}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              position="absolute"
              top={2.5}
              right={-5}
            />
          )}
          {breakpoint !== 'desktop' && (
            <Button
              leftIcon={
                <Icon
                  as={MaterialIcons}
                  name={isOpen ? 'clear' : 'menu'}
                  size="md"
                  color={isOpen ? 'base.200' : 'base.400'}
                />
              }
              size="sm"
              variant="unstyled"
              onPress={onToggle}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              position="absolute"
              top={5}
              left={-36}
            />
          )}
          <Image
            source={expandedUI ? logo : badge}
            style={
              expandedUI
                ? { width: 145, height: 88, alignSelf: 'center' }
                : { width: 60, height: 55, alignSelf: 'center' }
            }
          />
          <ScrollView style={{ flex: 1 }}>
            <Box flex={1} px={3} mb="auto" maxWidth="100%" style={{ paddingTop: expandedUI ? 12 : 45 }}>
              {children}
            </Box>
          </ScrollView>
          <Pressable onPress={toggleColorMode}>
            <Center mx="2" p="2">
              <Icon as={Feather} size={6} color="base.500" name={useColorModeValue('sun', 'moon')} />
            </Center>
          </Pressable>
          <UserButton large={expandedUI} />
        </Box>
      </Animated.View>
    </>
  )
}

function UserButton({ large, onPress }: { large: boolean; onPress?: () => void }) {
  const authApi = useAuthAPI()
  const appHintsApi = useAppHintsApi()
  const username = authApi.userInfo.name
  const avatar = username
    ?.split(' ')
    .slice(0, 2)
    .map((name) => name.trim().charAt(0).toUpperCase())
    .join('')

  return (
    <Box py="4" px="6" justifyContent="center" alignItems="center">
      <Menu
        trigger={(triggerProps) => {
          return (
            <Pressable accessibilityLabel="User options" {...triggerProps} mt="1.5">
              <HStack>
                <Avatar bg="cyan.500" alignSelf="center" size="sm" mr={large ? 2 : 0}>
                  {avatar}
                </Avatar>
                {large && (
                  <PresenceTransition
                    visible={true}
                    initial={{
                      opacity: 0,
                      translateX: -10,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 250,
                        delay: 100,
                      },
                    }}>
                    <Text color="base.500" numberOfLines={1} maxWidth={90}>
                      {username}
                    </Text>
                  </PresenceTransition>
                )}
              </HStack>
            </Pressable>
          )
        }}>
        <Menu.Item onPress={() => appHintsApi.resetHints()}>Show All Hints</Menu.Item>
        <Menu.Item onPress={() => authApi.onLogout()}>Log out</Menu.Item>
      </Menu>
    </Box>
  )
}

const IconTransition = ({ visible, children }: React.PropsWithChildren<{ visible: boolean }>) => (
  <PresenceTransition
    visible={visible}
    initial={{
      translateX: 7,
    }}
    animate={{
      translateX: 0,
      transition: {
        duration: 250,
      },
    }}>
    {children}
  </PresenceTransition>
)
