import React, { useState } from 'react'
import { PanResponder, View } from 'react-native'

interface Props {
  direction: 'horizontal' | 'vertical'
  initialSize: number
  minSize: number
  maxSize: number
}

export default function ResizablePane(props: React.PropsWithChildren<Props>): JSX.Element {
  const { direction, initialSize, minSize, maxSize, children } = props
  const [active, setActive] = useState(false)
  const [size, setSize] = useState<number>(initialSize)

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderGrant: () => setActive(true),
    onPanResponderMove: (e, { dx, dy }) => {
      e.preventDefault()
      const d = direction === 'horizontal' ? dx : dy
      const newSize = size + d
      if (newSize <= maxSize && newSize >= minSize) {
        setSize(newSize)
      }
    },
    onPanResponderRelease: () => setActive(false),
  })

  return (
    <View
      style={[
        // @ts-ignore
        { userSelect: 'none', position: 'relative' },
        direction === 'horizontal' ? { width: size } : { height: size },
      ]}>
      {children}
      <View
        style={[
          {
            // @ts-ignore
            cursor: direction === 'horizontal' ? 'col-resize' : 'row-resize',
            position: 'absolute',
            backgroundColor: active ? '#9CA3AF' : 'transparent',
          },
          direction === 'horizontal' ? { width: 7, height: '100%', right: 0 } : { height: 7, width: '100%', bottom: 0 },
        ]}
        {...panResponder.panHandlers}></View>
    </View>
  )
}
