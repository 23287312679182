import moment, { Moment } from 'moment'
import React from 'react'

import { Restaurant, Channel, UISecurity } from './types'

export const RestaurantCtx = React.createContext<null | Restaurant>(null)

export const ChannelCtx = React.createContext<null | Channel>(null)

export const DateCtx = React.createContext<Moment>(moment())

export const UISecurityCtx = React.createContext<UISecurity>({ canChange: () => true })
