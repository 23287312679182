import React, { useEffect, useState } from 'react'
import { Button, FormControl, Icon, Input, Modal, Radio, Select, Stack, Text } from 'native-base'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { BankConnection, BankConnectionForm, isValidRoutingNumber, Organizable, usesManualConnection } from './model'
import { Maybe } from 'app/types'
import { MaterialCommunityIcons, MaterialIcons, FontAwesome, Entypo } from '@expo/vector-icons'

import { AccessGroup } from 'access_groups/api/model'
import { AccessGroupApis } from '../access_groups/api/access_group/service'
import { AsyncSelect } from 'ui'
import { Restrict, useAuthAPI } from '../authentication'
import { AG_ROLES } from '../access_groups/constants/ag-roles'
import { auditObjDesc, useAuditService } from '../audit/service'
import { RadioGroup } from 'ui'

export default function BankConnectionEditor(props: {
  bankConnection: Maybe<BankConnection>
  onClose: (result: Maybe<Partial<Organizable<BankConnection>>>) => Maybe<Promise<BankConnection>>
}): JSX.Element {
  const securityAPI = useAuthAPI()
  const auditAPI = useAuditService()
  const name = securityAPI.userInfo?.name
  const email = securityAPI.userInfo?.email
  const phone = securityAPI.userInfo?.phone

  const [firstName, ...lastName] = name.split(/\s+/)

  const form = useForm<Organizable<BankConnectionForm & { connectionType: 'MANUAL' | 'AUTOMATIC' }>>({
    defaultValues: {
      contact_email: email,
      contact_phone: phone,
      connectionType: usesManualConnection(props.bankConnection) ? 'MANUAL' : 'AUTOMATIC',
      ...props.bankConnection,
    },
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Maybe<string>>(null)

  const manualConnection = 'MANUAL' == form.watch('connectionType')

  const onSubmit = (data: Partial<Organizable<BankConnectionForm>>) => {
    setBusy(true)
    const res = props.onClose(data)
    if (res)
      res
        .then((o) => {
          setBusy(false)
          auditAPI.createLog(
            'bank_connection',
            String(o.id),
            props.bankConnection && props.bankConnection.id ? 'update' : 'create',
            auditObjDesc(props.bankConnection || {}, o)
          )
        })
        .catch(({ message }) => setError(message))
  }

  return (
    <Restrict role={AG_ROLES.BANK_CONNECTIONS_ADMIN}>
      <Modal isOpen={true} onClose={() => props.onClose(null)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Connect to your bank account</Modal.Header>
          <Modal.Body>
            <FormProvider {...form}>
              <Stack space="4" alignItems="center">
                <FormControl isRequired isInvalid={'name' in errors}>
                  <FormControl.Label>Name</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="restaurant or company name for easy identification"
                        InputRightElement={
                          <Icon
                            as={<MaterialCommunityIcons name="bank-transfer-in" />}
                            size={5}
                            mr="2"
                            color="muted.400"
                          />
                        }
                      />
                    )}
                    name="name"
                    rules={{ required: 'Name is required' }}
                  />

                  <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>
                </FormControl>

                <RadioGroup
                  rules={{ required: { value: true, message: 'Please specify how do you want to connect' } }}
                  name="connectionType"
                  label="Connecting to the bank"
                  accessibilityLabel="How do you want to connect to your bank?"
                >
                  <Radio my={1} value="MANUAL">
                    Provide account and routing numbers
                  </Radio>
                  <Radio my={1} value="AUTOMATIC">
                    Securely connect to your bank in next screen
                  </Radio>
                </RadioGroup>

                {manualConnection && (
                  <FormControl isRequired isInvalid={'routing_number' in errors}>
                    <FormControl.Label>Routing number</FormControl.Label>

                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="bank account routing number"
                          InputRightElement={
                            <Icon as={<FontAwesome name="hashtag" />} size={4} mr="2" color="muted.400" />
                          }
                        />
                      )}
                      name="routing_number"
                      rules={{
                        required: 'routing number is required',
                        validate: (v, _bc) => (isValidRoutingNumber(v) ? undefined : 'The routing number is invalid'),
                      }}
                    />

                    <FormControl.ErrorMessage>{errors.routing_number?.message}</FormControl.ErrorMessage>
                  </FormControl>
                )}

                {manualConnection && (
                  <FormControl isRequired isInvalid={'account_number' in errors}>
                    <FormControl.Label>Account number</FormControl.Label>

                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="bank account number"
                          InputRightElement={
                            <Icon as={<FontAwesome name="hashtag" />} size={4} mr="2" color="muted.400" />
                          }
                        />
                      )}
                      name="account_number"
                      rules={{
                        required: 'account number is required',
                      }}
                    />

                    <FormControl.ErrorMessage>{errors.account_number?.message}</FormControl.ErrorMessage>
                  </FormControl>
                )}

                <FormControl isRequired isInvalid={'contact_first_name' in errors}>
                  <FormControl.Label>Contact First Name</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="bank account contact first name"
                        isReadOnly={!!props.bankConnection?.id}
                        InputRightElement={
                          <Icon as={<MaterialCommunityIcons name="account" />} size={5} mr="2" color="muted.400" />
                        }
                      />
                    )}
                    name="contact_first_name"
                    rules={{ required: 'Contact first name is required' }}
                  />

                  <FormControl.ErrorMessage>{errors.contact_first_name?.message}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={'contact_last_name' in errors}>
                  <FormControl.Label>Contact last Name</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="bank account contact last name address"
                        isReadOnly={!!props.bankConnection?.id}
                        InputRightElement={
                          <Icon as={<MaterialCommunityIcons name="account" />} size={5} mr="2" color="muted.400" />
                        }
                      />
                    )}
                    name="contact_last_name"
                    rules={{ required: 'Contact last name is required' }}
                  />

                  <FormControl.ErrorMessage>{errors.contact_last_name?.message}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={'contact_email' in errors}>
                  <FormControl.Label>Contact Email</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="bank account contact email address"
                        isReadOnly={!!props.bankConnection?.id}
                        InputRightElement={
                          <Icon as={<MaterialCommunityIcons name="email" />} size={5} mr="2" color="muted.400" />
                        }
                      />
                    )}
                    name="contact_email"
                    rules={{ required: 'Name is required' }}
                  />

                  <FormControl.ErrorMessage>{errors.contact_email?.message}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={'contact_phone' in errors}>
                  <FormControl.Label>Contact Phone</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="bank account phone number (10 digits)"
                        isReadOnly={!!props.bankConnection?.id}
                        InputRightElement={
                          <Icon as={<MaterialCommunityIcons name="phone" />} size={5} mr="2" color="muted.400" />
                        }
                      />
                    )}
                    name="contact_phone"
                    rules={{
                      required: 'Contact phone number is required',
                      minLength: { value: 10, message: 'Phone number must be exact 10 digits' },
                      maxLength: { value: 10, message: 'Phone number must be exact 10 digits' },
                    }}
                  />

                  <FormControl.ErrorMessage>{errors.contact_phone?.message}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={'address' in errors}>
                  <FormControl.Label>Address</FormControl.Label>

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Address of the bank account"
                        isReadOnly={false}
                        InputRightElement={<Icon as={<Entypo name="address" />} size={5} mr="2" color="muted.400" />}
                      />
                    )}
                    name="address"
                    rules={{
                      required: 'Address is required',
                    }}
                  />

                  <FormControl.ErrorMessage>{errors.address?.message}</FormControl.ErrorMessage>
                </FormControl>

                {!props.bankConnection?.id && <ResourceOrganizationSelector role="bank_connections_admin" />}

                {!!error && <FormError label={error} />}

                <Button w="100%" isLoading={busy} isLoadingText="Submitting" onPress={handleSubmit(onSubmit)}>
                  {props.bankConnection ? 'Update Bank Account Connection' : 'Create Bank Account Connection'}
                </Button>
              </Stack>
            </FormProvider>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Restrict>
  )
}

// TODO: Refactor. Extract this for forms general error
export const FormError = (props: { label: string }): JSX.Element => (
  <Text fontWeight="400" fontSize="md" _light={{ color: 'danger.700' }} _dark={{ color: 'danger.700' }}>
    {props.label}
  </Text>
)

export interface ResourceOrganizationSelectorProps {
  role: string
}

// TODO: Refactor. Extract this as standard component for selecting organization
export function ResourceOrganizationSelector(props: ResourceOrganizationSelectorProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const [accessGroups, setAccessGroups] = useState<Maybe<AccessGroup[]>>(null)

  useEffect(() => {
    AccessGroupApis.search(undefined, undefined, props.role).then((res) => setAccessGroups(res.data))
  }, [])

  if (accessGroups == null) {
    return (
      <FormControl isRequired isInvalid={'parent_access_group_id' in errors}>
        <FormControl.Label>Organization:</FormControl.Label>
        <Text>Loading....</Text>
      </FormControl>
    )
  }

  return (
    <FormControl isRequired isInvalid={'parent_access_group_id' in errors}>
      <FormControl.Label>Organization:</FormControl.Label>
      <Controller
        control={control}
        render={({ field }) => (
          <AsyncSelect
            loadOptions={(name: string) =>
              AccessGroupApis.search(undefined, name).then((res) => res.data.filter((i) => !!i.parent_id))
            }
            getOptionLabel={(a: AccessGroup) => a.name}
            defaultOptions
            placeholder={'Search Organization...'}
            value={field.value?.id}
            onChange={(i) => field.onChange(String(i?.id))}
            required
            cacheOptions
          />
        )}
        name="parent_access_group_id"
        rules={{ required: 'Field is required' }}
        defaultValue={accessGroups.length == 1 ? String(accessGroups[0].id) : ''}
      />
      <FormControl.ErrorMessage>{errors.parent_access_group_id?.message}</FormControl.ErrorMessage>
    </FormControl>
  )
}
