import hash from 'object-hash'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { auditObjDesc } from '../../../audit/service'

export default function SyncFormHandler({ defaultValues }: { defaultValues: any }) {
  const form = useFormContext()

  useEffect(() => {
    // get current form values
    const formValues = form.getValues()
    const changed = hash(formValues) !== hash(defaultValues)
    if (changed) {
      const changes = auditObjDesc(formValues, defaultValues)
      if (
        confirm(
          `This object was recently updated with the following changes: ${changes}. Would you like to update your form to reflect these changes?`
        )
      ) {
        form.reset(defaultValues)
      }
    }
  }, [defaultValues])

  return null
}
