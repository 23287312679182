import * as R from 'ramda'
import { color, ColorProps, useRestyle } from '@shopify/restyle'
import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { Theme } from '../theme'
import { TextCtx } from './text'
import * as VectorIcons from '@expo/vector-icons'

interface Props {
  name: string
  size?: number
  style?: StyleProp<TextStyle>
  type?:
    | 'MaterialIcons'
    | 'Feather'
    | 'MaterialCommunityIcons'
    | 'FontAwesome'
    | 'Entypo'
    | 'Ionicons'
    | 'Octicons'
    | 'Zocial'
    | 'SimpleLineIcons'
    | 'EvilIcons'
    | 'Foundation'
    | 'AntDesign'
}

export type IconProps = Props & ColorProps<Theme>

const Icon: React.FC<IconProps> = ({ size = 16, type = 'MaterialIcons', ...rest }) => {
  const props = useRestyle([color], rest)
  const IconProvider = VectorIcons[type]

  const genIcon = (ctxStyle: any) => {
    // const c = R.defaultTo("#000000", R.defaultTo(ctxStyle.color, R.prop('color', style)))
    const c = ctxStyle.color
    return <IconProvider size={size} color={c} {...props} />
  }

  return <TextCtx.Consumer>{genIcon}</TextCtx.Consumer>
}

export default Icon
