import React, { useCallback, useEffect, useState } from 'react'
import { Dimensions, ScaledSize } from 'react-native'

export default function useWindowDimensions() {
  const [_window, setWindow] = useState<ScaledSize>(Dimensions.get('window'))

  const onChange = ({ window, screen }: { window: ScaledSize; screen: ScaledSize }) => {
    setWindow(window)
  }

  useEffect(() => {
    const subscribtion = Dimensions.addEventListener('change', onChange)
    return () => {
      subscribtion?.remove()
    }
  })

  return _window
}

// function useWindowDimensions2() {
//   const getViewport = () =>
//     window.visualViewport
//       ? { width: window.visualViewport.width, height: window.visualViewport.height }
//       : { height: window.innerHeight, width: window.innerWidth }

//   const [viewport, setViewport] = useState(getViewport())

//   useEffect(() => {
//     const handleResize = (e: Event) => {
//       console.log('resized', getViewport())
//       setViewport(getViewport())
//     }

//     handleResize()

//     window.addEventListener('resize', handleResize)
//     window.addEventListener('orientationchange', handleResize)
//     window.visualViewport?.addEventListener('resize', handleResize)

//     return () => {
//       window.removeEventListener('resize', handleResize)
//       window.removeEventListener('orientationchange', handleResize)
//       window.visualViewport?.removeEventListener('resize', handleResize)
//     }
//   }, [])

//   return viewport
// }
