import { Maybe } from 'app/types'
import { Box, Button, Card, Center, Icon, ScrollView, Spinner, Stack, Text } from 'native-base'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CardTitle, CardContent, Grid } from 'ui'
import PageHeader from 'ui/components/page-header'
import { BankConnectionSvc, useBankConnectionAPI } from './guest-service'
import { BankConnection } from './model'
import useCabbagePayListener from './cabbage-gateway'
import { Label, Value } from './view'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export default function App() {
  return (
    <BankConnectionSvc>
      <BankConnectionUI />
    </BankConnectionSvc>
  )
}

export function BankConnectionUI(): JSX.Element {
  const params = useParams<{ uuid: string }>()
  const api = useBankConnectionAPI()
  const [bankConnection, setBankConnection] = useState<Maybe<BankConnection>>(null)
  useEffect(() => {
    if (params.uuid) {
      api
        .loadBankConnection(params.uuid)
        .then((res) => setBankConnection(res.data))
    }
  }, [params.uuid])
  console.log("Bank Connection", bankConnection)


  if (!bankConnection) {
    return <Center flex={1}>
      <Spinner accessibilityLabel="Loading..." />
    </Center>
  }

  return (
    <Box flex={1}>
      <Box>
        <PageHeader title={bankConnection.name} />
      </Box>
      <Stack>
        <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="handled">
          <Overview />
        </ScrollView>
      </Stack>
    </Box>)
}
export function Overview(): JSX.Element {
  const params = useParams<{ uuid: string }>()
  const api = useBankConnectionAPI()
  const [bankConnection, setBankConnection] = useState<Maybe<BankConnection>>(null)

  useEffect(() => {
    if (params.uuid) {
      api
        .loadBankConnection(params.uuid)
        .then((res) => setBankConnection(res.data))
    }
  }, [params.uuid])

  const cbl = useCabbagePayListener(api, bankConnection, setBankConnection)

  if (!bankConnection) {
    return <Center flex={1}>
      <Spinner accessibilityLabel="Loading..." />
    </Center>
  }

  const getConnectMessage = (bc: BankConnection) => {
    if (!bc.has_token) {
      return "Please connect to your bank account"
    }
    if (!bc.healthy) {
      return "There is a problem with your bank account! Please reconnect!"
    }
    return "Everything is good. We will deposit all the checks into your bank account!"
  }

  return <Box>
    <Card colorScheme="primary" mb="4">
      <CardTitle>
        <Text variant="2" fontWeight="800">
          {bankConnection.name} ({bankConnection.uuid})
        </Text>
      </CardTitle>
      <CardContent>
        <Grid cols={2} m="4">
          <Label>Contact Name</Label>
          <Value>{bankConnection.contact_first_name} {bankConnection.contact_last_name}</Value>
          <Label>Contact Email</Label>
          <Value>{bankConnection.contact_email}</Value>
          <Label>Contact Phone</Label>
          <Value>{bankConnection.contact_phone}</Value>
          {bankConnection.has_token &&
            <>
              <Label>Bank Name</Label>
              <Value>{bankConnection.bank_name}</Value>
              <Label>Bank Account Last 4 digits</Label>
              <Value>{bankConnection.bank_account_last_four}</Value>
            </>
          }
          {(bankConnection.error_message || bankConnection.error_code) &&
            <>
              <Label>Error</Label>
              <Value>{bankConnection.error_code || 'n/a'} - {bankConnection.error_message || 'n/a'}</Value>
            </>
          }
        </Grid>
        <Center>
          <Box>
            <Text fontSize={"xl"}>{getConnectMessage(bankConnection)}</Text>
          </Box>
          {(!bankConnection.healthy || !bankConnection.has_token) && <Button
            onPress={cbl.startConnection}
            leftIcon={<Icon as={MaterialCommunityIcons} name="bank" />}>{bankConnection.has_token ? "Reconnect" : "Connect"}</Button>}
        </Center>
      </CardContent>
    </Card>
  </Box>
}
