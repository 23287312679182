export enum Apps {
  REPORTS,
  REPORTS_V5,
  USERS,
  USERS_API,
  RESTAURANTS,
  RESTAURANTS_API,
  FEEDBACK,
  ORDERS,
  ORDERS_V5,
  RDSAAS,
  API,
  PAYMENTS,
  PRESENCE,
  LOYALTY,
  LOYALTY_API,
  POS,
  CUSTOMER_SERVICE,
  ACCOUNTS,
  RDS_API,
  DISCOUNTS_API,
  BANK_CONNECTION_API,
  PUBLIC_BANK_CONNECTION_API,
  CABBAGE_JS_API,
  RESTAURANTS_PREVIEW,
  GIFT_CARDS_API,
  BILLING_ACCOUNTS_API,
  BILLING_RULES_API,
  APPS_MANAGER_API,
  PUBLISH_RESTAURANT_API,
  SYNC_SERVICE_SOCKET,
  CHARGES_API,
}
const domain = process.env.DOMAIN
const env = process.env.NODE_ENV

enum AppDomains {
  Production = 'zuppler.com',
  Staging = 'biznettechnologies.com',
  Local = 'zuppler.test',
}

export const getAPIURL = (app: Apps, context: { restaurant_id?: number } = {}): string => {
  switch (app) {
    case Apps.REPORTS_V5:
      return `//report-engine.${domain}`
    case Apps.REPORTS:
      return `//reports.${domain}`
    case Apps.USERS:
      return `//users.${domain}`
    case Apps.USERS_API:
      return `//users-api5.${domain}`
    case Apps.RESTAURANTS_API:
      return `//restaurants-api5.${domain}`
    case Apps.RESTAURANTS:
      return `//restaurants.${domain}`
    case Apps.ORDERS:
      return `//orders-api.${domain}`
    case Apps.ORDERS_V5:
      return `//orders-api5.${domain}`
    case Apps.FEEDBACK:
      return `//feedback2.${domain}`
    case Apps.RDSAAS:
      return `//redsaas.${domain}`
    case Apps.PAYMENTS:
      return `//payments-api.${domain}`
    case Apps.API:
      return `//api.${domain}`
    case Apps.LOYALTY:
      return `//loyalty-api5.${domain}`
    case Apps.LOYALTY_API:
      return `//zuppler-loyalty-api.${domain}`
    case Apps.POS:
      return `//posaas.${domain}`
    case Apps.PRESENCE:
      return `wss://zuppler-presence.herokuapp.com`
    case Apps.CUSTOMER_SERVICE:
      return `//customer-service.${domain}`
    case Apps.API:
      return `//api.${domain}`
    case Apps.ACCOUNTS:
      return `//accounts.${domain}`
    case Apps.RDS_API:
      return `//rds-api.${domain}`
    case Apps.DISCOUNTS_API:
      return `//zuppler-discounts-api.${domain}`
    case Apps.BANK_CONNECTION_API:
      return `//payments-api.${domain}/v5/bank_connections`
    case Apps.PUBLIC_BANK_CONNECTION_API:
      return `//payments-api.${domain}/v5/public/bank_connections`
    case Apps.CABBAGE_JS_API:
      return domain == AppDomains.Production
        ? 'https://cabbagepay.com/js/production/cabbage.js'
        : 'https://cabbagepay.com/js/sandbox/cabbage.js'
    case Apps.GIFT_CARDS_API:
      return `//giftcards-api.${domain}`
    case Apps.RESTAURANTS_PREVIEW:
      if (domain == AppDomains.Production) {
        return 'https://web5.zuppler.com/preview.html#/restaurants'
      }
      return 'https://order.biznettechnologies.com/preview.html#/restaurants'
    case Apps.BILLING_ACCOUNTS_API:
      return `${getAPIURL(Apps.PAYMENTS)}/v5/accounts`
    case Apps.BILLING_RULES_API:
      return `${getAPIURL(Apps.PAYMENTS)}/v5/billing_rules`
    case Apps.APPS_MANAGER_API:
      return `//restaurants-api5.${domain}/v6/native_apps`
    case Apps.PUBLISH_RESTAURANT_API:
      return `${getAPIURL(Apps.RESTAURANTS_API)}/v6/publish_restaurant`
    case Apps.SYNC_SERVICE_SOCKET:
      return `wss://orders-api5.${domain}/orders/socket`
    case Apps.CHARGES_API:
      return `${getAPIURL(Apps.RESTAURANTS_API)}/v6/restaurants/${context.restaurant_id}/charges`
    default:
      return ``
  }
}
