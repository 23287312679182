import React, { useEffect } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { useAuditService, auditObjDesc } from './service'

type KeyExtractor<T> = (t: T) => string
type Auditable = { id: number }
export interface AuditLoggerMutationOptions<T = Auditable> {
  resourceType: string // singular
  resourceId?: string | KeyExtractor<T>
  action?: string | KeyExtractor<T>
  object?: T
}

export default function QueryAuditLogger({ queryClient }: { queryClient: QueryClient }) {
  const auditAPI = useAuditService()

  useEffect(() => {
    const mutationCache = queryClient.getMutationCache()

    const unsubscribeFromMutationCache = mutationCache.subscribe((event) => {
      if (event.type === 'updated' && event.mutation?.state.status === 'success') {
        if (event.mutation.options.meta?.audit) {
          const newObject = event.mutation.state.data
          const auditOptions = event.mutation.options.meta.audit as AuditLoggerMutationOptions<Auditable>

          if (!auditOptions.resourceType) {
            console.error('Invalid audit options: resourceType is required')
            return
          }

          const oldObject = auditOptions.object

          try {
            const action = auditOptions.action
              ? extractAction(auditOptions.action, oldObject)
              : determineAction(oldObject, newObject, auditOptions.resourceId)

            const resourceId = extractResourceId(auditOptions.resourceId, oldObject, newObject)

            return auditAPI.createLog(
              auditOptions.resourceType,
              resourceId,
              action,
              auditObjDesc(oldObject || {}, newObject || {})
            )
          } catch (e) {
            console.error('Error while creating audit log', e)
            return Promise.reject({ message: e })
          }
        }
      }
    })

    return () => {
      unsubscribeFromMutationCache()
    }
  }, [queryClient])

  return null
}

function extractAction<T extends Auditable>(action: string | KeyExtractor<any>, oldObject?: T): string {
  if (typeof action === 'string') {
    return action
  }
  if (typeof action === 'function') {
    return action(oldObject)
  }
  throw new Error('Cannot extract action')
}

function extractResourceId<T extends Auditable>(
  resourceId?: string | KeyExtractor<T>,
  oldObject?: T,
  newObject?: T
): string {
  if (!resourceId && !!oldObject && !!oldObject.id) {
    return String(oldObject.id)
  }
  if (!resourceId && !!newObject && !!newObject.id) {
    return String(newObject.id)
  }
  if (typeof resourceId === 'string') {
    return resourceId
  }
  if (typeof resourceId === 'function') {
    return resourceId(oldObject)
  }
  throw new Error('Cannot extract resource id')
}

function determineAction<T extends Auditable>(
  oldObject?: T,
  newObject?: T,
  resourceId?: string | KeyExtractor<T>
): string {
  if (!oldObject) {
    return 'create'
  }
  if (!newObject) {
    return 'delete'
  }
  return !!extractResourceId(resourceId, oldObject, newObject) ? 'update' : 'create'
}
