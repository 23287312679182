import useBreakpoint from '../hooks/use-breakpoint'

import { Breakpoint as BreakpointT } from '../types'

interface Props {
  size: BreakpointT | BreakpointT[]
  children: any
}

const Breakpoint = ({size, children}: Props) => {
  const breakpoint = useBreakpoint()

  if(
    (typeof size === 'string' && size === breakpoint) ||
    (Array.isArray(size) && size.includes(breakpoint))
  ) {
    return children
  }
  return null
}

export default Breakpoint