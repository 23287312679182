import React, { useState, useEffect } from 'react'
import { useToken, useColorModeValue, VStack, useMediaQuery } from 'native-base'
import { Breakpoint } from 'ui'
import { Center, HStack, Icon, Box, Text } from 'native-base'
import { VictoryPie, VictoryTooltip, VictoryLabel, VictoryLegend } from "victory-native"
import { FontAwesome } from '@expo/vector-icons'
import { Svg } from 'react-native-svg'

interface ChartProps {
  [key: string]: any
}

interface LabelProps {
  datum?: any
  x?: number
  y?: number
}

const PieChartIndicator = ({ data, xAccesor, yAccesor, width, height, legend, ...rest }: ChartProps) => {
  // for animation
  const [chartData, setChartData] = useState([])
  const [endAngle, setEndAngle] = useState(0)

  const [isLargeScreen] = useMediaQuery({
    minWidth: 800
  })

  useEffect(() => {
    setTimeout(() => {
      setChartData(data);
      setEndAngle(360);
    }, 100);
  }, [])

  const colorScale = useToken("colors",
    [
      'rose.600', 'fuchsia.900', 'emerald.800', 'purple.700', 'cyan.900',
      'yellow.800', 'red.600', 'warmGray.700', 'teal.800', 'darkBlue.700'
    ]
  )

  const [
    toolTipBorderColor, toolTipBgColor, toolTipTextColor
  ] = useToken("colors",
    [
      useColorModeValue('primary.800', 'primary.200'),
      useColorModeValue('base.100', 'base.200'),
      useColorModeValue('primary.600', 'primary.600'),
    ]
  )

  function getItemAmountPercentange(itemAmount: number) {
    const totalAmount = data.map(item => item[yAccesor]).reduce((prev, curr) => prev + curr, 0)
    return Math.ceil((itemAmount / totalAmount) * 100)
  }

  const ItemInfoLabel = (props) => (
    <Text
      fontSize={16}
      _light={{ color: 'base.800' }}
      _dark={{ color: 'base.200' }}
      {...props}
    />
  )

  const ItemInfo = () => {
    return (
      <Box>
        {data.map((dt, index) => {
          return (
            <HStack space={3} alignItems="center" key={index}>
              <Icon
                size="4"
                color={colorScale[index]}
                as={FontAwesome}
                name="square"
              />
              <ItemInfoLabel>{dt.item}</ItemInfoLabel>
            </HStack>
          )
        })}
      </Box>
    )
  }

  function getTooltipOrientation(startAngle, endAngle) {
    return startAngle >= 0 && endAngle <= 200 ? "right" : "left"
  }

  const CustomToolTip = (props: LabelProps) => {
    const { datum } = props
    const tooltipLabel = datum.tooltip_label
    // console.log("tooltip props : ", props)
    return (
      <g>
        <VictoryTooltip
          {...props}
          pointerLength={40}
          pointerWidth={2}
          orientation={getTooltipOrientation(datum.startAngle, datum.endAngle)}
          active={true}
          text={tooltipLabel}
          flyoutStyle={{
            stroke: toolTipBorderColor,
            strokeWidth: 0.5,
            fill: toolTipBgColor,

          }}
          flyoutPadding={{ top: 2, bottom: 2, left: 15, right: 15 }}
          style={{ fill: toolTipTextColor }}
        />
      </g>
    )
  }

  // set tooltip default events (hoverin/hoverout) only for Desktop
  // if (isLargeScreen) {
  //   CustomToolTip.defaultEvents = VictoryTooltip.defaultEvents
  // }

  const chartConfig: any = {
    animate: {
      duration: 2000,
    },
    startAngle: 0,
    endAngle: endAngle,
    colorScale: colorScale,
    innerRadius: 20,
    labelRadius: 140,
    style: { labels: { display: "none" } },
    x: xAccesor,
    y: yAccesor,
  }

  return (
    <Svg
      width={width} height={height}
      style={{ overflow: "visible" }}
      viewBox={`0 0 ${width} ${height}`}
    >
      <VictoryLegend
        x={135}
        standalone={false}
        title={legend}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ title: { fontSize: 20, stroke: "#7a7272" } }}
        data={[]}
      />
      <VictoryPie
        width={width}
        standalone={false}
        data={chartData}
        labelComponent={<CustomToolTip />}
        {...chartConfig}
      />
    </Svg>
  )
}

export default PieChartIndicator