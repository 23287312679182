import React from 'react'
import { GroupBase } from 'react-select'
import { default as AAsyncSelect, AsyncProps } from 'react-select/async'
import { useToken, useColorModeValue } from 'native-base'

const baseFont = {
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  fontSize: 14,
}

export function useAsyncSelectChefTheme<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(): AsyncProps<Option, IsMulti, Group> {
  const [
    primary,
    primary75,
    primary50,
    primary25,
    neutral0,
    neutral5,
    neutral10,
    neutral20,
    neutral30,
    neutral40,
    neutral50,
    neutral60,
    neutral70,
    neutral80,
    neutral90,
  ] = useToken('colors', [
    useColorModeValue('primary.600', 'primary.300'),
    useColorModeValue('primary.500', 'primary.400'),
    useColorModeValue('primary.400', 'primary.500'),
    useColorModeValue('primary.200', 'primary.700'),
    useColorModeValue('base.50', 'base.700'),
    useColorModeValue('base.100', 'base.800'),
    useColorModeValue('base.200', 'base.700'),
    useColorModeValue('base.300', 'base.600'),
    useColorModeValue('base.400', 'base.500'),
    useColorModeValue('base.500', 'base.400'),
    useColorModeValue('base.600', 'base.300'),
    useColorModeValue('base.700', 'base.200'),
    useColorModeValue('base.800', 'base.100'),
    useColorModeValue('base.900', 'base.50'),
    useColorModeValue('base.900', 'base.50'),
  ])

  return {
    menuPortalTarget: document.body,
    styles: {
      control: (base) => ({
        ...base,
        ...baseFont,
      }),
      menuPortal: (base) => {
        // console.log('base', base)
        return { ...base, zIndex: 9999 }
      },
      menu: (base) => ({
        ...base,
        ...baseFont,
        color: neutral90,
        backgroundColor: neutral5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: neutral30,
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: neutral20,
      }),
      multiValueRemove: (base) => ({
        ...base,
        color: neutral50,
      }),
    },
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary,
        primary75,
        primary50,
        primary25,
        neutral0,
        neutral5,
        neutral10,
        neutral20,
        neutral30,
        neutral40,
        neutral50,
        neutral60,
        neutral70,
        neutral80,
        neutral90,
      },
    }),
  }
}

export default function AsyncSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: AsyncProps<Option, IsMulti, Group>) {
  const chefTheme = useAsyncSelectChefTheme<Option, IsMulti, Group>()

  return <AAsyncSelect {...props} {...chefTheme} />
}
