export interface LoggedInUser {
  id: number
  name: string
  email: string
  phone: string
  oauth_token: string
  token_expires_at: number
  ag_roles: string[]
}

export interface AuthApi {
  agentInfo: () => string
  isLoggedIn: () => boolean // Returns true if logged in and session is not expired
  hasAgRole: (role: string) => boolean
  authorize: (headers?: any) => any
  onLogin: (user: LoggedInUser) => void
  onLogout: () => void
  userInfo: LoggedInUser
}

export enum LoginMethod {
  SignIn,
  SignUp,
  AcceptInvite,
}

export enum GuestAction {
  loading,
  sign_in,
  accept_invite,
  sign_up,
  forget_password,
  confirm_token,
}

export interface AcceptInviteParams {
  email: string
  name: string
  phone: string
  password: string
  confirm_password: string
  token: string
}

export type SignUpParams = Omit<AcceptInviteParams, 'token'>

export type SignUpResposne = Pick<AcceptInviteParams, 'name' | 'email' | 'phone'> & { readonly id: number }
