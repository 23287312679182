import React from 'react'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryLabel, VictoryVoronoiContainer, VictoryGroup, VictoryScatter, VictoryTooltip } from "victory-native"
import { useToken, useColorModeValue, useBreakpointValue, Text, Box, useMediaQuery } from 'native-base'
import { Svg, Text as TextSVG } from 'react-native-svg';

interface ChartProps {
	[key: string]: any
}

interface LabelProps {
	datum?: any
	x?: number
	y?: number
}

const LineChart = ({ data, width, height, xAccesor, yAccesor, lineType, yAxisLabelPrefix, yAxisLabelSuffix }: ChartProps) => {
	const [
		dotColorDefault, dotColorOnAction, xAxisTickLabelColor, yAxisTickLabelColor,
		gridColor, toolTipBorderColor, toolTipBgColor, toolTipTextColor,
		axisLabelColor, axisColor
	] = useToken("colors",
		[
			useColorModeValue('base.700', 'base.300'),
			useColorModeValue('green.500', 'green.300'),
			useColorModeValue('base.700', 'base.200'),
			useColorModeValue('base.700', 'base.200'),
			useColorModeValue('base.500', 'base.500'),
			useColorModeValue('primary.800', 'primary.200'),
			useColorModeValue('base.100', 'base.200'),
			useColorModeValue('primary.600', 'primary.600'),
			useColorModeValue('primary.600', 'primary.600'),
			useColorModeValue('base.400', 'base.500'),
		]
	)

	const [isLargeScreen] = useMediaQuery({
		minWidth: 800
	})

	const colorScale = useToken("colors",
		[
			'rose.600', 'fuchsia.900', 'emerald.800', 'purple.700', 'cyan.900',
			'yellow.800', 'red.600', 'warmGray.700', 'teal.800', 'darkBlue.700'
		]
	)

	const CustomToolTip = (props: LabelProps) => {
		const { datum } = props
		const label = [datum.restaurant, datum.month, datum.revenue]
		return (
			<VictoryTooltip
				{...props}
				text={label}
				flyoutStyle={{
					stroke: toolTipBorderColor,
					strokeWidth: 0.5,
					fill: toolTipBgColor,

				}}
				flyoutPadding={15}
				style={{ fill: toolTipTextColor }}
			/>
		)
	}

	// set tooltip default events (hoverin/hoverout) only for Desktop
	if (isLargeScreen) {
		CustomToolTip.defaultEvents = VictoryTooltip.defaultEvents
	}

	const XAxisConfig: any = {
		crossAxis: true,
		axisLabelComponent: <VictoryLabel dy={25} style={{ fontSize: 15, fill: axisLabelColor }} />,
		style: {
			grid: { stroke: gridColor, strokeDasharray: "2 7", opacity: 0.3 },
			tickLabels: { fill: xAxisTickLabelColor, fontSize: 13 },
			axis: { stroke: axisColor }
		}
	}

	const YAxisConfig: any = {
		dependentAxis: true,
		crossAxis: false,
		axisLabelComponent: <VictoryLabel dy={-45} style={{ fontSize: 15, fill: axisLabelColor }} />,
		style: {
			grid: { stroke: gridColor, strokeDasharray: "2 7", opacity: 0.3 },
			tickLabels: { fill: yAxisTickLabelColor, fontSize: 13 },
			axis: { stroke: axisColor }
		},
		tickFormat: (t: any) => getYAxisTickLabel(t),
	}

	function getYAxisTickLabel(text: string): string {
		text = yAxisLabelPrefix ? yAxisLabelPrefix + text : text
		text = yAxisLabelSuffix ? text + yAxisLabelSuffix : text
		return text
	}

	const chartConfig = {
		minDomain: { y: 0 },
		domainPadding: { y: 10 },
		width: width ? width : 600,
		height: height ? height : 400,
		containerComponent:
			<VictoryVoronoiContainer
				labels={d => {
					const lData = d.datum
					return `${lData[xAccesor]} - ${lData[yAccesor]}`
				}}
				labelComponent={<CustomToolTip />}
			/>

	}

	const lineConfig = {
		animate: {
			duration: 2000,
			onLoad: { duration: 1000 }
		},
		x: xAccesor,
		y: yAccesor,
		interpolation: lineType,
	}

	const scatterConfig = {
		x: xAccesor,
		y: yAccesor,
		style: { data: { fill: dotColorDefault } }

	}

	function onHoverDot(): any {
		return [
			{
				target: "data",
				mutation: () => ({ style: { fill: dotColorOnAction } })
			}, {
				target: "labels",
				mutation: () => ({ active: true })
			}
		]
	}

	function onHoverOutDot(): any {
		return [
			{
				target: "data",
				mutation: () => { }
			}, {
				target: "labels",
				mutation: () => ({ active: false })
			}
		]
	}

	function onClickDot(): any {
		return [
			{
				eventKey: "all",
				target: "data",
				mutation: () => ({ style: { fill: dotColorDefault } })
			},
			{
				target: "data",
				mutation: () => ({ style: { fill: dotColorOnAction } })
			},
			{
				eventKey: "all",
				target: 'labels',
				mutation: () => ({ active: false })
			},
			{
				target: "labels",
				mutation: () => ({ active: true })
			}
		]
	}


	return (
		<VictoryChart {...chartConfig}>
			<VictoryAxis {...XAxisConfig} />
			<VictoryAxis {...YAxisConfig} />
			{data.map((dt, index) => {
				return (
					<VictoryGroup key={index}>
						<VictoryLine
							key={index}
							data={dt}
							style={{
								data: { stroke: colorScale[index] },
								labels: { display: "none" }
							}}
							{...lineConfig}
						/>
						<VictoryScatter
							data={dt}
							events={[
								{
									target: "data",
									eventHandlers: {
										onMouseOver: () => isLargeScreen ? onHoverDot() : null,
										onMouseOut: () => isLargeScreen ? onHoverOutDot() : null,
										onClick: () => !isLargeScreen ? onClickDot() : null,
									}
								}
							]}
							{...scatterConfig}
						/>
					</VictoryGroup>
				)
			})}

		</VictoryChart>
	)
}

export default LineChart