import React, { useRef } from 'react'

import Icon from './icon'
import { Box, Text, Button } from 'native-base'

// import { Theme } from '../theme';
export type CardVariant = 'primary' | 'standard'

interface Props {
  label?: string
  colorScheme?: string
  variant?: CardVariant
}
type BoxProps = React.ComponentProps<typeof Box>

export type CardProps = BoxProps & Props

const Card = ({ label, colorScheme = 'base', variant = 'standard', children, ...rest }: CardProps) => {
  const ref = useRef()

  const scheme =
    variant === 'standard'
      ? {
          _light: {
            bg: `white`,
            color: `${colorScheme}.900`,
            shadow: 1,
            borderTopColor: `transparent`,
            borderTopStyle: 'solid',
            borderTopWidth: 0.5,
          },
          _dark: {
            bg: `${colorScheme}.600:alpha.20`,
            color: `${colorScheme}.200`,
            shadow: 3,
            borderTopColor: `${colorScheme}.600`,
            borderTopStyle: 'solid',
            borderTopWidth: 0.5,
          },
        }
      : {
          _light: {
            bg: `white`,
            color: `${colorScheme}.900`,
            shadow: 10,
          },
          _dark: {
            bg: `${colorScheme}.600`,
            color: `${colorScheme}.200`,

            shadow: 10,
          },
        }

  return (
    <Box borderRadius="lg" borderStyle="solid" overflow="hidden" {...scheme} {...rest} ref={ref}>
      {children}
    </Box>
  )
}

export default Card

export const CardTitle = (props: BoxProps) => (
  <Box
    p="4"
    borderBottomWidth={1}
    _light={{ borderColor: 'base.100', bg: 'base.50' }}
    _dark={{ borderColor: 'base.700', bg: 'base.800' }}
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    {...props}
  />
)
export const CardActions = (props: BoxProps) => (
  <Box
    px="4"
    py="2"
    borderTopWidth={1}
    borderColor="base.200"
    bg="base.100"
    _light={{ borderColor: 'base.100', bg: 'base.50' }}
    _dark={{ borderColor: 'base.700', bg: 'base.800' }}
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-end"
    {...props}
  />
)
export const CardContent = (props: BoxProps) => <Box p="4" {...props} />
