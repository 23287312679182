import { IBoxProps, useBreakpointValue } from 'native-base'
import { IScrollViewProps } from 'native-base/src/components/basic/ScrollView'
import React from 'react'

import { Box, ScrollView } from 'ui'

type BPScrollViewProps = {
  scrollOn: {
    base?: boolean
    sm?: boolean
    md?: boolean
    lg?: boolean
    xl?: boolean
    '2xl'?: boolean
  }
} & IScrollViewProps

export default function BPScrollView({ scrollOn, ...props }: BPScrollViewProps) {
  const shouldScroll: boolean = useBreakpointValue(scrollOn)
  if (shouldScroll) return <ScrollView {...props} />
  return <Box {...props} />
}
