import React, { useState } from 'react'
import { Factory, ISelectItemProps, ISelectProps, useTheme, ChevronDownIcon } from 'native-base'
import { ControlledField, ControlledFieldProps } from './fields'
import { Box } from 'ui'
import { transparentize } from 'native-base/src/theme/tools'

function BaseSelect({
  style,
  placeholder,
  children,
  dataSet,
  onValueChange,
  value,
  selectedValue,
  accessibilityLabel,
  defaultIsChecked,
  isInvalid,
  isDisabled,
  onChange,
  testID,
  ...props
}: any) {
  const _onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(ev.target.value)
  }
  const stateStyle = isDisabled ? { cursor: 'not-allowed' } : {}
  return (
    <select
      {...props}
      data-testid={testID}
      disabled={!!isDisabled}
      value={selectedValue === undefined ? '_placeholder_' : value}
      onChange={_onChange}
      style={{ ...style, ...stateStyle, WebkitAppearance: 'none', appearance: 'none', outline: 'none' }}>
      {placeholder && (
        <option disabled value="_placeholder_">
          {placeholder}
        </option>
      )}
      {children}
    </select>
  )
}

const NativeSelectFactory = Factory(BaseSelect, {
  baseStyle: {
    bg: 'transparent',
    p: 2,
    px: 3,
    h: 9,
    fontSize: 'sm',
    borderWidth: 0,
    borderColor: 'transparent',
    lineHeight: 'xs',
  },
})

const NativeSelect = (props: ISelectProps) => {
  const [focused, setFocused] = useState(false)
  const theme = useTheme()
  return (
    <Box
      // @ts-ignore
      style={{ boxShadow: focused ? `0 0 0 1px ${theme.colors.primary[600]}` : 'none' }}
      borderRadius="sm"
      _light={{
        borderColor: focused ? 'primary.600' : 'base.300',
        bg: focused ? '#F9FAFB' : '#F9FAFB',
      }}
      _dark={{
        borderColor: focused ? 'primary.500' : 'base.600',
        bg: focused ? 'base.700' : 'base.700',
      }}
      // bg={focused ? transparentize('primary.600', 0.1)(theme) : 'transparent'}
      borderStyle="solid"
      borderWidth={1}
      position="relative"
      pr={3}>
      <NativeSelectFactory
        _light={{ color: 'base.900' }}
        _dark={{ color: 'base.50' }}
        {...props}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
      />
      <ChevronDownIcon
        position="absolute"
        _light={{ color: 'base.400' }}
        _dark={{ color: 'base.500' }}
        size="xs"
        right={2}
        top={3}
        style={{ pointerEvents: 'none' }}
      />
    </Box>
  )
}

const SelectTemp = ControlledField(NativeSelect) as any
SelectTemp.Item = (props: ISelectItemProps) => {
  return (
    <option value={props.value} style={{ color: 'black' }}>
      {props.label}
    </option>
  )
}
const Select = SelectTemp as typeof NativeSelect &
  ((props: ISelectProps & ControlledFieldProps) => JSX.Element) & { Item: React.FC<ISelectItemProps> }

export default Select
