import React, { useState } from 'react'
import { VictoryChart, VictoryGroup, VictoryPie } from 'victory-native'
import { LayoutChangeEvent, LayoutRectangle, View } from 'react-native'
import { getVictoryTheme } from 'ui/theme/victory'
import Svg from 'react-native-svg'
import useDebounce from 'ui/hooks/use-debounce'
import { useColorMode } from 'native-base'

type ResponsiveChartProps = React.ComponentProps<typeof VictoryChart | typeof VictoryPie>

const createChart =
  <P extends ResponsiveChartProps>(Component: React.ComponentType<P>): React.FC<P> =>
  ({ height, ...rest }: P) => {
    const [size, setSize] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 })
    const lastSize = useDebounce<LayoutRectangle>(size, 30)
    const { colorMode } = useColorMode()
    const victoryTheme = getVictoryTheme(colorMode === 'dark')

    const measure = ({ nativeEvent: event }: LayoutChangeEvent) => {
      const { layout } = event

      if (!!layout) {
        setSize(layout)
      }
    }

    const width = lastSize.width > 0 ? lastSize.width : size.width

    return (
      <View onLayout={measure}>
        <Svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none" width="100%" style={{ overflow: 'visible' }}>
          <Component standalone={false} width={size.width} height={height} theme={victoryTheme} {...rest} />
        </Svg>
      </View>
    )
  }

export const ResponsiveChart = createChart(VictoryChart)
export const ResponsivePie = createChart(VictoryPie)
export const ResponsiveChartGroup = createChart(VictoryGroup)
