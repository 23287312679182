import axios from 'axios'
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { AuditAPI, AuditResourceAPI, GetLogs, LogEntry } from './model'
import { getAPIURL, Apps } from '../api_url'
import rdiff from 'recursive-diff'
import { useAuthAPI } from '../authentication'

const AUDIT_SVC = getAPIURL(Apps.RESTAURANTS_API) + `/v5/audit_logs`

const AuditCtx = createContext<AuditAPI | null>(null)
const AuditResourceCtx = createContext<AuditResourceAPI | null>(null)

export function useAuditService(): AuditAPI {
  const auditAPI = useContext(AuditCtx)
  return auditAPI as AuditAPI
}

export function useAuditResourceService(): AuditResourceAPI {
  const auditAPI = useContext(AuditResourceCtx)
  return auditAPI as AuditResourceAPI
}

export function AuditService(props: PropsWithChildren<{ appId: string }>): JSX.Element {
  const authApi = useAuthAPI()
  const createLog = (
    resourceType: string,
    resourceId: string,
    action: string,
    description: string
  ): Promise<LogEntry> => {
    const payload = {
      audit_log: {
        app: props.appId,
        resource: resourceType,
        resource_id: resourceId,
        action: action,
        description: description,
        agent: authApi.agentInfo(),
      },
    }

    return axios.post(AUDIT_SVC, payload).then((res) => res.data)
  }

  const getAllLogs = (resourceType: string, resourceId: string, page = 1, perPage = 20): Promise<GetLogs> => {
    const query = `?page=${page}&per_page=${perPage}&app=${encodeURIComponent(
      props.appId
    )}&resource=${encodeURIComponent(resourceType)}&resource_id=${encodeURIComponent(resourceId)}`

    return axios.get(AUDIT_SVC + query).then((res) => res.data)
  }

  const createLogWithObj = (
    resourceType: string,
    resourceId: string,
    action: string,
    oldObj: any,
    newObj: any
  ): Promise<LogEntry> => {
    return createLog(resourceType, resourceId, action, auditObjDesc(oldObj, newObj))
  }

  const api = {
    createLog,
    getAllLogs,
    createLogWithObj,
  }
  return <AuditCtx.Provider value={api}>{props.children}</AuditCtx.Provider>
}

export function AuditResource(props: PropsWithChildren<{ resourceType: string; resourceId: string }>): JSX.Element {
  const auditAPI = useAuditService()
  const createLog = (action: string, description: string): Promise<LogEntry> => {
    return auditAPI.createLog(props.resourceType, props.resourceId, action, description)
  }
  const createLogWithObj = (action: string, oldObj: any, newObj: any): Promise<LogEntry> => {
    return auditAPI.createLog(props.resourceType, props.resourceId, action, auditObjDesc(oldObj, newObj))
  }
  const getAllLogs = (page = 1, perPage = 20): Promise<GetLogs> => {
    return auditAPI.getAllLogs(props.resourceType, props.resourceId, page, perPage)
  }
  const api = {
    createLog,
    createLogWithObj,
    getAllLogs,
  }
  return <AuditResourceCtx.Provider value={api}>{props.children}</AuditResourceCtx.Provider>
}

export function auditObjDesc(prevVersion: any, newVersion: any): string {
  const { id: aid, inserted_at: ainserted, updated_at: aupdated, __typename: atypename, ...a } = prevVersion
  const { id: bid, inserted_at: binserted, updated_at: bupdated, __typename: btypename, ...b } = newVersion
  const res = rdiff
    .getDiff(a, b, true)
    .map((c: any): string => {
      switch (c.op) {
        case 'add':
          return `- +**${humanizePath(c.path)}** to _${typeof c.val == 'object' ? JSON.stringify(c.val) : c.val}_`
        case 'update':
          return `- ~**${humanizePath(c.path)}** to _${
            typeof c.val == 'object' ? JSON.stringify(c.val) : c.val
          }_ from _${typeof c.oldVal == 'object' ? JSON.stringify(c.oldVal) : c.oldVal}_`
        case 'delete':
          return `- -**${humanizePath(c.path)}**: _${typeof c.val == 'object' ? JSON.stringify(c.oldVal) : c.oldVal}_`
        default:
          return c.op
      }
    })
    .join('\n\n')
  return res.length ? res : 'No changes'
}

function humanizePath(path: string[]): string {
  return path.map((p) => underscoreToTitleCase(p)).join(': ')
}

function underscoreToTitleCase(str: string): string {
  if (typeof str !== 'string') return `[${str}]`
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
