import React, { createContext, useContext, useEffect, useState } from 'react'

export interface Job {
  name: string
  task: () => IterableIterator<Promise<any>> | AsyncGenerator<any>
  blockUI?: boolean
}

interface TaskRunnerApi {
  jobs: Job[]
  addJob: (t: Job) => void
  removeJob: (t: Job) => void
}

const TaskRunnerCtx = createContext<TaskRunnerApi>({ jobs: [], addJob: () => {}, removeJob: () => {} })

export function TaskRunnerProvider({ children }: React.PropsWithChildren<any>) {
  const [jobs, setJobs] = useState<Job[]>([])

  useEffect(() => {
    if (jobs.length > 0) {
      const handleUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault()
        event.returnValue = ''
      }
      window.addEventListener('beforeunload', handleUnload)
      return () => window.removeEventListener('beforeunload', handleUnload)
    }
  }, [jobs])

  const addJob = (t: Job) => {
    setJobs((prev) => [...prev, t])
  }

  const removeJob = (t: Job) => {
    setJobs((prev) => prev.filter((p) => p.name !== t.name))
  }

  return <TaskRunnerCtx.Provider value={{ jobs, addJob, removeJob }}>{children}</TaskRunnerCtx.Provider>
}

export function useTaskRunnerApi() {
  const api = useContext(TaskRunnerCtx)
  return api
}
