import React from 'react'
import {
  Text as NText,
  Box as NBox,
  Stack as NStack,
  Square as NSquare,
  VStack as NVStack,
  HStack as NHStack,
  Icon as NIcon,
  CheckCircleIcon as NCheckCircleIcon,
  InfoIcon as NInfoIcon,
  WarningIcon as NWarningIcon,
  WarningTwoIcon as NWarningTwoIcon,
} from 'native-base'
import useResponsiveColor from 'ui/hooks/use-responsive-color'
import { ColorType } from 'native-base/lib/typescript/components/types'

interface ColorProps {
  color?: ColorType
  bg?: ColorType
  backgroundColor?: ColorType
  bgColor?: ColorType
  borderColor?: ColorType
  borderLeftColor?: ColorType
  [key: string]: any
}

export const makeResponsiveColorComp =
  <P extends object>(Child: React.FC<P>) =>
  ({ color, bg, backgroundColor, bgColor, borderColor, borderLeftColor, ...rest }: P & ColorProps) => {
    const newColors = {
      color: useResponsiveColor(color),
      bg: useResponsiveColor(bg),
      backgroundColor: useResponsiveColor(backgroundColor),
      bgColor: useResponsiveColor(bgColor),
      borderColor: useResponsiveColor(borderColor),
      borderLeftColor: useResponsiveColor(borderLeftColor),
    }
    return <Child {...newColors} {...(rest as P)} />
  }

const Text = makeResponsiveColorComp(NText) as unknown as typeof NText
const Box = makeResponsiveColorComp(NBox) as unknown as typeof NBox
const Stack = makeResponsiveColorComp(NStack) as unknown as typeof NStack
const Square = makeResponsiveColorComp(NSquare) as unknown as typeof NSquare
const VStack = makeResponsiveColorComp(NVStack) as unknown as typeof NVStack
const HStack = makeResponsiveColorComp(NHStack) as unknown as typeof NHStack
const Icon = makeResponsiveColorComp(NIcon) as unknown as typeof NIcon
const CheckCircleIcon = makeResponsiveColorComp(NCheckCircleIcon) as unknown as typeof NCheckCircleIcon
const InfoIcon = makeResponsiveColorComp(NInfoIcon) as unknown as typeof NInfoIcon
const WarningIcon = makeResponsiveColorComp(NWarningIcon) as unknown as typeof NWarningIcon
const WarningTwoIcon = makeResponsiveColorComp(NWarningTwoIcon) as unknown as typeof NWarningTwoIcon

export { Text, Box, Stack, Square, VStack, HStack, Icon, CheckCircleIcon, InfoIcon, WarningIcon, WarningTwoIcon }
