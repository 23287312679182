import { Apps, getAPIURL } from '../../../api_url'
import axios from 'axios'
import formatBackendErrors from '../../../common/util'

const URL = getAPIURL(Apps.USERS_API) + `/v5/is_resource_authorized`
const cache = new Map()

export const ResourceAuthorizationAPI = {
  isResourceAuthorized: (
    resource_type: string,
    resource_id: string,
    role: string,
    invalidate = false
  ): Promise<{ is_authorized: boolean }> => {
    if (invalidate || !cache.has(`isResourceAuthorized${resource_type}_${resource_id}_${role}`)) {
      return isResourceAuthorized(resource_type, resource_id, role)
    } else {
      console.log('isResourceAuthorized invoked from cache')
      return Promise.resolve(cache.get(`isResourceAuthorized${resource_type}_${resource_id}_${role}`))
    }
  },
  isAuthorized: (role: string, invalidate = false): Promise<{ is_authorized: boolean }> => {
    if (invalidate || !cache.has(`isAuthorized$${role}`)) {
      return isAuthorized(role)
    } else {
      console.log('isAuthorized invoked from cache')
      return Promise.resolve(cache.get(`isResourceAuthorized${role}`))
    }
  },
}

const isResourceAuthorized = (
  resource_type: string,
  resource_id: string,
  role: string
): Promise<{ is_authorized: boolean }> => {
  return axios
    .post(URL, { resource_type, resource_id, role })
    .then((res) => {
      cache.set(`isResourceAuthorized${resource_type}_${resource_id}_${role}`, res.data)
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const isAuthorized = (role: string): Promise<{ is_authorized: boolean }> => {
  return axios
    .post(URL, { role })
    .then((res) => {
      cache.set(`isAuthorized${role}`, res.data)
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}
