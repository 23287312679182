import { getAPIURL, Apps } from '../../../api_url'
import axios from 'axios'
import { AccessGroup, AccessGroupResource, PaginationParams, ResourceCount, ResourceType } from '../model'
import { PaginationResponse } from 'app/types'
import formatBackendErrors from '../../../common/util'

const AG_URL = getAPIURL(Apps.USERS_API) + `/v5/access_group`

const DEFAULT_PAGINATION = {
  page: 1,
  per_page: 15,
}

export const create = (params: Omit<AccessGroup, 'id' | 'parent' | 'children'>): Promise<AccessGroup> => {
  return axios
    .post(AG_URL, { ...params, parent_access_group_id: params.parent_id })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

export const merge = (
  params: Pick<AccessGroup, 'name' | 'description'> & { access_group_ids: number[]; parent_access_group_id: number }
): Promise<AccessGroup> => {
  return axios
    .post(AG_URL + '/merge', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

export const move_access_group = (params: { src_ag_ids: number[]; dest_ag_id: number }): Promise<any> => {
  return axios
    .post(AG_URL + '/move_access_groups', { ...params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const getAccessGroup = (id: number): Promise<AccessGroup> => {
  return axios
    .get(AG_URL + `/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const searchAccessGroups = (
  pagination: PaginationParams = DEFAULT_PAGINATION,
  search_term: string = '',
  ag_role: string = '',
  parent_id: number = 0
): Promise<PaginationResponse<AccessGroup>> => {
  const search = {
    ...(search_term !== '' && { search_term }),
    ...(parent_id !== 0 && { parent_id }),
    ...(ag_role !== '' && { ag_role }),
    page: pagination.page,
    per_page: pagination.per_page,
  }
  return axios
    .post(AG_URL + '/search', search)
    .then((res) => {
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const getStats = (id: number): Promise<ResourceCount[]> => {
  return axios
    .get(AG_URL + `/${id}/get_stats`)
    .then((res) => {
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const getResourceInfo = (
  resourceType: ResourceType,
  ag_id: number,
  search_term: string,
  pagination: PaginationParams = DEFAULT_PAGINATION
): Promise<PaginationResponse<AccessGroupResource>> => {
  const search = {
    ...(search_term !== '' && { search_term }),
    page: pagination.page,
    per_page: pagination.per_page,
  }

  return axios
    .post(AG_URL + `/${ag_id}/${resourceType}`, search)
    .then((res) => {
      return res.data
    })
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const deleteAccessGroups = (access_group_ids: number[]): Promise<AccessGroup[]> => {
  return axios
    .post(AG_URL + '/delete', { access_group_ids })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const removeAgResources = (
  resource_type: ResourceType,
  resource_ids: string[],
  access_group_id: number
): Promise<AccessGroupResource[]> => {
  return axios
    .post(AG_URL + '/remove_resources', { resource_type, resource_ids, access_group_id })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const moveAgResouces = (
  resource_type: ResourceType,
  resource_ids: string[],
  access_group_id: number
): Promise<AccessGroupResource[]> => {
  return axios
    .post(AG_URL + '/move_resources', { resource_type, resource_ids, access_group_id })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const addAgResource = (
  resource_type: ResourceType,
  resource_id: string,
  access_group_id: number
): Promise<AccessGroupResource[]> => {
  return axios
    .post(AG_URL + '/add_resource', { resource_type, resource_id, access_group_id })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const getResourceAccessGroups = (
  resource_type: string,
  resource_id: string
): Promise<PaginationResponse<AccessGroupResource & { access_group_name: string }>> => {
  return axios
    .post(AG_URL + '/get_resource_groups', { resource_id, resource_type, per_page: 50 })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

const updateAccessGroup = (id: number, name: string, description: string): Promise<AccessGroup> => {
  return axios
    .put(AG_URL + `/${id}`, { name, description })
    .then((res) => res.data)
    .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
}

export const AccessGroupApis = {
  getStats: (id: number, invalidate: boolean = false): Promise<ResourceCount[]> => {
    return getStats(id)
  },

  get: (id: number, invalidate = false): Promise<AccessGroup> => {
    return getAccessGroup(id)
  },

  search: (
    pagination: PaginationParams = DEFAULT_PAGINATION,
    search_term: string = '',
    ag_role: string = '',
    parent_id: number = 0,

    invalidate = false
  ): Promise<PaginationResponse<AccessGroup>> => {
    return searchAccessGroups(pagination, search_term, ag_role, parent_id)
  },

  getResourceInfo: (
    resourceType: ResourceType,
    ag_id: number,
    searchQuery: string,
    pagination: PaginationParams = DEFAULT_PAGINATION,
    invalidate = false
  ): Promise<PaginationResponse<AccessGroupResource>> => {
    return getResourceInfo(resourceType, ag_id, searchQuery, pagination)
  },

  updateAccessGroup,
  deleteAccessGroups,
  removeAgResources,
  moveAgResouces,
  addAgResource,
  getResourceAccessGroups,
}
