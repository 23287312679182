import { ITextProps } from 'native-base'
import { Text } from './responsive-primitives'

export const H1 = (props: ITextProps) => (
  <Text fontSize="3xl" fontWeight="bold" color="base.800$" lineHeight="sm" {...props} />
)
export const H2 = (props: ITextProps) => (
  <Text fontSize="2xl" fontWeight="bold" color="base.700$" lineHeight="sm" {...props} />
)
export const H3 = (props: ITextProps) => (
  <Text fontSize="xl" fontWeight="bold" color="base.600$" lineHeight="sm" {...props} />
)
export const H4 = (props: ITextProps) => (
  <Text fontSize="lg" fontWeight="bold" color="base.900$" lineHeight="sm" {...props} />
)
export const Lead = (props: ITextProps) => (
  <Text fontSize="2xl" fontWeight="light" color="base.600$" lineHeight="sm" {...props} />
)
export const P = (props: ITextProps) => <Text fontSize="md" color={props.bold ? 'base.700$' : 'base.900$'} {...props} />

export const Small = (props: ITextProps) => (
  <Text fontSize="xs" color={props.bold ? 'base.700$' : 'base.900$'} {...props} />
)
