import React, { useState } from 'react'
import { Factory, ISelectItemProps, ISelectProps, useTheme, ChevronDownIcon, IInputProps } from 'native-base'
import { ControlledField, ControlledFieldProps } from './fields'
import { Box } from 'ui'
import { transparentize } from 'native-base/src/theme/tools'

function BaseInput({
  style,
  dataSet,
  onValueChange,
  selectedValue,
  defaultIsChecked,
  accessibilityLabel,
  isInvalid,
  errorLabel,
  onChange,
  testID,
  isDisabled,
  ...props
}: any) {
  const _onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(ev.target.value)
  }
  return (
    <input
      {...props}
      data-testid={testID}
      onChange={_onChange}
      readOnly={isDisabled}
      style={{ ...style, boxSizing: 'border-box', WebkitAppearance: 'none', appearance: 'none', outline: 'none' }}
    />
  )
}

const NativeInputFactory = Factory(BaseInput, {
  baseStyle: {
    bg: 'transparent',
    p: 2,
    px: 3,
    h: 9,
    fontSize: 'sm',
    borderWidth: 0,
    borderColor: 'transparent',
    lineHeight: 'xs',
  },
})

export const NativeInput = (props: IInputProps) => {
  const [focused, setFocused] = useState(false)
  const theme = useTheme()
  return (
    <Box
      // @ts-ignore
      style={{ boxShadow: focused ? `0 0 0 1px ${theme.colors.primary[600]}` : 'none' }}
      borderRadius="sm"
      _light={{ bg: focused ? '#E8E8F8' : 'base.50', borderColor: focused ? 'primary.600' : 'base.300' }}
      _dark={{ bg: focused ? 'base.700' : 'base.700', borderColor: focused ? 'primary.500' : 'base.600' }}
      borderStyle="solid"
      borderWidth={1}>
      <NativeInputFactory
        _light={{ color: 'base.900' }}
        _dark={{ color: 'base.50' }}
        {...props}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
      />
    </Box>
  )
}

const Input = ControlledField(NativeInput) as typeof NativeInput &
  ((props: IInputProps & ControlledFieldProps) => JSX.Element)

export default Input
