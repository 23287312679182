import React, { PropsWithChildren, useState } from 'react'
import { HStack, VStack, Text, Image, Center, Hidden, Stack, Box, PresenceTransition } from 'native-base'

import { GuestAction, LoggedInUser, LoginMethod } from '../model'
import SignInForm from './sign-in'
import logo from 'assets/zuppler-chef.svg'
import AcceptInviteForm from './accept-invite'
import SignUpForm from './sign-up'
import ForgetPasswordForm from './forget-password'
import { useAuthAPI } from '../../authentication'
import { Navigate } from '../../router'

export default function GuestWelcome(props: {
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
  action: GuestAction
}): JSX.Element {
  const [screen, setScreen] = useState(props.action)
  const authApi = useAuthAPI()

  if (authApi.isLoggedIn()) {
    return <Navigate to="/" />
  }
  return (
    <Box flex={1}>
      <Box safeAreaTop _light={{ bg: 'primary.900' }} _dark={{ bg: 'coolGray.900' }} />
      <Center
        px={{ base: 0, md: 5 }}
        _dark={{ bg: 'coolGray.800' }}
        _light={{ bg: { base: 'coolGray.800', md: 'coolGray.200' } }}
        flex="1">
        <Stack
          flexDirection={{ base: 'column', md: 'row' }}
          w="100%"
          maxW={{ md: '1016px' }}
          flex={{ base: '1', md: 'none' }}>
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5">
              <VStack justifyContent="center" alignItems="center">
                <Text color="base.400" fontSize="2xl" mt="4" mb="-12px">
                  welcome to
                </Text>
                <HStack alignItems="flex-end">
                  <Image size="xl" alt="NativeBase Startup+ " resizeMode={'contain'} source={logo} />
                  <Text color="base.100" fontSize="2xl" fontWeight="bold" alignSelf="center" mt="7" mx="3">
                    Chef
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              w="50%"
              flex="1"
              _light={{ bg: 'coolGray.900' }}
              _dark={{ bg: 'coolGray.700' }}
              borderTopLeftRadius={{ base: '0', md: 'xl' }}
              borderBottomLeftRadius={{ base: '0', md: 'xl' }}>
              <VStack justifyContent="center" alignItems="center">
                <Text color="base.400" fontSize="2xl" mb="-4">
                  welcome to
                </Text>
                <HStack alignItems="flex-end">
                  <Image size="xl" alt="NativeBase Startup+ " resizeMode={'contain'} source={logo} />
                  <Text color="base.100" fontSize="2xl" fontWeight="bold" alignSelf="center" mt="7" mx="3">
                    Chef
                  </Text>
                </HStack>
              </VStack>
            </Center>
          </Hidden>
          {screen == GuestAction.sign_in && <SignInForm {...props} onChangeAction={setScreen} />}
          {screen == GuestAction.accept_invite && <AcceptInviteForm {...props} />}
          {screen == GuestAction.sign_up && <SignUpForm {...props} onChangeAction={setScreen} />}
          {screen == GuestAction.forget_password && <ForgetPasswordForm {...props} onChangeAction={setScreen} />}
        </Stack>
      </Center>
    </Box>
  )
}

export function AnimateAndRender(props: PropsWithChildren) {
  return (
    <PresenceTransition
      visible={true}
      initial={{
        opacity: 0,
        translateX: -20,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 250,
          delay: 100,
        },
      }}>
      {props.children}
    </PresenceTransition>
  )
}
