import React, { createContext, useContext, useRef, useState } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { useBreakpointValue, ITheme, Box } from 'native-base'

type BoxProps = React.ComponentProps<typeof Box>

type BaseStyle = StyleProp<TextStyle> & StyleProp<ViewStyle>

type GridProps = BoxProps & {
  vertical?: boolean
  cols?: number
  style?: BaseStyle
  debug?: boolean
}

const GridCtx = createContext({colSize: 1, vertical: false, debug: false})

export const Grid: React.FC<GridProps> = ({ vertical = false, cols = 12, style, debug = false, ...props }) => {
  const colSize = 100 / cols

  return (
    <GridCtx.Provider value={{colSize, vertical, debug}}>
      <Box
        {...props}
        style={[
          {
            display: 'flex',
            flexDirection: vertical ? 'column' : 'row',
            flexWrap: 'wrap'
          },
          style
        ]}
      />
    </GridCtx.Provider>
  )
}

type Span = {
  [key in keyof ITheme['breakpoints']]?: number;
} | number[] | number;

type CellProps = BoxProps & {
  span?: Span
  style?: BaseStyle
}

export const Cell: React.FC<CellProps> = ({span = 1, style, children, ...props}) => {
  const { colSize, vertical, debug } = useContext(GridCtx)
  const spanProp = useBreakpointValue(span as any)
  const cols = (spanProp || 1) as number
  return (
    <Box
      {...props}
      style={[
        style,
        {margin: 0},
        !vertical && { width: `${colSize * cols}%`},
        vertical && { height: `${colSize * cols}%`}
      ]}
    >
      { debug && spanProp }
      {children}
    </Box>
  )
}
Cell.displayName = 'Cell';