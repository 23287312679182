import React, { createContext, PropsWithChildren, useContext } from 'react'

export interface Services {
  me_url: string
}

export interface API {
  getServiceURL: (svc: keyof Services) => string
}

const Ctx = createContext<API | null>(null)

export function ConfigService(props: PropsWithChildren<{ services: Services }>) {
  const api = {
    getServiceURL: (svc: keyof Services) => props.services[svc],
  }
  return <Ctx.Provider value={api}>{props.children}</Ctx.Provider>
}

export function useAppConfig(): API {
  const api = useContext(Ctx)
  if (!api) throw 'Please setup app config first'
  return api as API
}

declare var process: {
  env: {
    ME_URL: string
  }
}

export function buildFromEnv(): Services {
  return { me_url: process.env.ME_URL }
}
