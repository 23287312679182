import React, { useRef, useState } from 'react'
import { Button, HStack, VStack, Text, Divider, FormControl, IconButton, Icon, Input, useToast, Box } from 'native-base'

import { MaterialIcons } from '@expo/vector-icons'

import { Controller, useForm } from 'react-hook-form'
import { AuthenticationApis } from '../api/service'
import { GuestAction, LoggedInUser, LoginMethod } from '../model'
import { AnimateAndRender } from './index'
import { Ionicons } from '@expo/vector-icons'

export default function ForgetPasswordForm(props: {
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
  onChangeAction: (action: GuestAction) => void
}) {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [tokenSent, setTokenSent] = useState(false)

  const formMethods = useForm()

  const toggleTokenSent = () => setTokenSent(!tokenSent)

  const onSubmit = (params: any) => {
    setLoading(true)
    AuthenticationApis.forget_password({ ...params, email: params.email.trim().toLowerCase() })
      .then(() => setTokenSent(true))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const validation = {
    email: {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: 'must be a valid email address',
      },
    },
  }

  if (tokenSent) {
    return <ResetPassword email={formMethods.getValues('email')} {...props} toggleTokenSent={toggleTokenSent} />
  }

  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '40px', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '40px', md: '0' }}>
      <AnimateAndRender>
        <VStack space="7">
          <HStack space="1">
            <IconButton
              borderRadius="full"
              colorScheme="secondary"
              _icon={{
                as: Ionicons,
                name: 'arrow-back-outline',
              }}
              onPress={() => props.onChangeAction(GuestAction.sign_in)}
            />
            <Text bold _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="xl" mt="1">
              Retrieve your account!
            </Text>
          </HStack>
          <VStack>
            <VStack space="3">
              <VStack space={{ base: '7', md: '4' }}>
                <FormControl isRequired isInvalid={'email' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        placeholder="Email"
                        InputLeftElement={
                          <Icon as={<MaterialIcons name="email" />} size={4} mx="2" color="coolGray.400" />
                        }
                        autoFocus
                        _dark={{
                          borderColor: 'coolGray.700',
                          placeholderTextColor: 'coolGray.400',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="email"
                    rules={validation.email}
                  />
                  <FormControl.ErrorMessage>{formMethods.formState.errors.email?.message}</FormControl.ErrorMessage>
                </FormControl>
              </VStack>

              {error && (
                <Text fontWeight="bold" color="error.700">
                  <Icon as={Ionicons} size="md" name={'warning'} color="error.700" />
                  {error}
                </Text>
              )}

              <Button
                mt="5"
                size="md"
                borderRadius="4"
                isLoading={loading}
                isLoadingText="loading"
                _text={{
                  fontWeight: 'medium',
                }}
                onPress={formMethods.handleSubmit(onSubmit)}>
                Continue
              </Button>
              <HStack mt="5" space="2" mb={{ base: 6, md: 7 }} alignItems="center" justifyContent="center">
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                <Text fontWeight="medium" _light={{ color: 'coolGray.300' }} _dark={{ color: 'coolGray.500' }}>
                  or
                </Text>
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <HStack
          mb="4"
          space="1"
          safeAreaBottom
          alignItems="center"
          justifyContent="center"
          mt={{ base: 'auto', md: '8' }}>
          <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
            Don't have an account?
          </Text>
          <Button
            paddingLeft="-1"
            variant="link"
            _text={{
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            _light={{
              _text: {
                color: 'primary.900',
              },
            }}
            _dark={{
              _text: {
                color: 'primary.500',
              },
            }}
            _hover={{
              _text: {
                color: 'warning.600',
              },
            }}
            onPress={() => props.onChangeAction(GuestAction.sign_up)}>
            Sign up
          </Button>
        </HStack>
      </AnimateAndRender>
    </VStack>
  )
}

function ResetPassword(props: {
  email: string
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
  onChangeAction: (action: GuestAction) => void
  toggleTokenSent: () => void
}) {
  const [error, setError] = useState<string | null>(null)
  const [showPass, setShowPass] = React.useState(false)

  const [loading, setLoading] = useState(false)
  const formMethods = useForm()
  const toast = useToast()
  const password = useRef({})
  password.current = formMethods.watch('password', undefined)

  const onSubmit = (params: any) => {
    setLoading(true)
    AuthenticationApis.reset_password({ ...params, email: props.email })
      .then(() => {
        props.onChangeAction(GuestAction.sign_in)
        showToast('Password reset successful, Please login with new credentials')
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const showToast = (msg: string | null): void => {
    toast.show({
      render: () => {
        return (
          <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            {msg}
          </Box>
        )
      },
    })
  }

  const validation = {
    token: {
      required: 'Confirmation code is required',
    },
    password: {
      required: 'Password is required',
      minLength: { value: 6, message: 'Minimun 6 characters' },
    },
    confirm_password: {
      validate: (value: string) => {
        if (!value && !password.current) return null
        return value === password.current || 'Passwords do not match'
      },
    },
  }

  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '2xl', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '2xl', md: '0' }}>
      <AnimateAndRender>
        <VStack space="7">
          <HStack space="1">
            <IconButton
              borderRadius="full"
              colorScheme="secondary"
              _icon={{
                as: Ionicons,
                name: 'arrow-back-outline',
              }}
              onPress={() => props.toggleTokenSent()}
            />
            <Text bold _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="xl" mt="1">
              Create New Password
            </Text>
          </HStack>
          <VStack>
            <VStack space="3">
              <VStack space={{ base: '7', md: '4' }}>
                <FormControl isRequired isInvalid={'token' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        placeholder="Confirmation code"
                        autoFocus
                        _dark={{
                          borderColor: 'coolGray.700',
                          placeholderTextColor: 'coolGray.400',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="token"
                    rules={validation.token}
                  />
                  <FormControl.ErrorMessage>{formMethods.formState.errors.token?.message}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={'password' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Password"
                        InputRightElement={
                          <IconButton
                            variant="unstyled"
                            icon={
                              <Icon
                                size="4"
                                color="coolGray.400"
                                as={MaterialIcons}
                                name={showPass ? 'visibility-off' : 'visibility'}
                              />
                            }
                            onPress={() => {
                              setShowPass(!showPass)
                            }}
                          />
                        }
                        _dark={{
                          borderColor: 'coolGray.700',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="password"
                    rules={validation.password}
                  />
                  <FormControl.ErrorMessage>{formMethods.formState.errors.password?.message}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={'confirm_password' in formMethods.formState.errors}>
                  <Controller
                    control={formMethods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="md"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        _dark={{
                          borderColor: 'coolGray.700',
                        }}
                        _light={{
                          borderColor: 'coolGray.300',
                        }}
                      />
                    )}
                    name="confirm_password"
                    rules={validation.confirm_password as any}
                  />
                  <FormControl.ErrorMessage>
                    {formMethods.formState.errors.confirm_password?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>

              {error && (
                <Text fontWeight="bold" color="error.700">
                  <Icon as={Ionicons} size="md" name={'warning'} color="error.700" />
                  {error}
                </Text>
              )}

              <Button
                mt="5"
                size="md"
                borderRadius="4"
                isLoading={loading}
                isLoadingText="Updating"
                _text={{
                  fontWeight: 'medium',
                }}
                onPress={formMethods.handleSubmit(onSubmit)}>
                Update Password
              </Button>
              <HStack mt="5" space="2" mb={{ base: 6, md: 7 }} alignItems="center" justifyContent="center">
                {/* <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                <Text fontWeight="medium" _light={{ color: 'coolGray.300' }} _dark={{ color: 'coolGray.500' }}>
                  or
                </Text>
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider> */}
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <HStack
          mb="4"
          space="1"
          safeAreaBottom
          alignItems="center"
          justifyContent="center"
          mt={{ base: 'auto', md: '8' }}>
          {/* <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
            Don't have an account?
          </Text>
          <Button
            paddingLeft="-1"
            variant="link"
            _text={{
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            _light={{
              _text: {
                color: 'primary.900',
              },
            }}
            _dark={{
              _text: {
                color: 'primary.500',
              },
            }}
            _hover={{
              _text: {
                color: 'warning.600',
              },
            }}
            onPress={() => props.onChangeAction(GuestAction.sign_up)}>
            Sign up
          </Button> */}
        </HStack>
      </AnimateAndRender>
    </VStack>
  )
}
