import React, { createContext, ReactChild, useContext, useRef } from 'react'

import { Box, Text } from 'native-base'

type BoxProps = React.ComponentProps<typeof Box>
interface ListProps {
  type?: 'bullets' | 'numbers' | 'clean'
  separators?: boolean
}

const ListCtx = createContext({ type: 'bullets', separators: false })

export const List: React.FC<ListProps & BoxProps> = ({
  type = 'bullets',
  separators = false,
  children,
  ...rest
}) => {
  return (
    <ListCtx.Provider value={{ type, separators }}>
      <Box {...rest}>{children}</Box>
    </ListCtx.Provider>
  )
}

interface ListItemProps {
  label?: string | ReactChild
}

export const ListItem: React.FC<ListItemProps & BoxProps> = ({ label, children, ...rest }) => {
  const { type, separators } = useContext(ListCtx)

  return (
    <Box
      px="4"
      py="2"
      borderBottomWidth={separators ? 1 : 0}
      _light={{ borderColor: 'base.200' }}
      _dark={{ borderColor: 'base.700' }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      {...rest}>
      {label && (
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          {type === 'bullets' && <Bullet />}
          <Text>{label}</Text>
        </Box>
      )}
      {children}
    </Box>
  )
}

export const CardContent = (props: BoxProps) => <Box padding="4" {...props} />

const Bullet = () => <Box size={2} bg="base.400" borderRadius="sm" mr={2} />
