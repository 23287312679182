import { useTheme, createVariant, createRestyleComponent, VariantProps, useResponsiveProp } from '@shopify/restyle';
import React from 'react';

import Text from './text'
import Box from './box'
import Icon from './icon'

import { Theme } from '../theme';

const variant = createVariant({themeKey: 'tagVariants'})

const TagBox = createRestyleComponent<
  VariantProps<Theme, 'tagVariants'> & React.ComponentProps<typeof Box>,
  Theme
>([variant], Box)


interface Props {
  label: string
  style?: any
  iconLeft?: string
  iconRight?: string
  active?: boolean
}

export type TagProps = React.ComponentProps<typeof TagBox> & Props

const iconColors = {
  normal: "base200",
  primary: "brand100",
  outlineNormal: "base600",
  outlinePrimary: "brand700"
}

const Tag = ({
  label,
  iconLeft,
  iconRight,
  variant,
  active,
  ...rest
}: TagProps) => {
  const theme = useTheme<Theme>();

  const variantProp = useResponsiveProp(variant) as keyof typeof iconColors
  const iconColor = variantProp ? iconColors[variantProp] : iconColors["normal"]

  return (
    <TagBox flexDirection="row" alignItems="center" justifyContent="center" borderRadius="l" paddingHorizontal="s" fontSize={11} fontWeight="bold" height={24} {...{variant}} {...rest}>
      { iconLeft && <Icon size={16} name={iconLeft} color={iconColor as keyof Theme['colors']} style={{marginRight: 3}}/> }
      <Text lineHeight={24}>
        {label}
      </Text>
      { iconRight && <Icon size={16} name={iconRight} color={iconColor as keyof Theme['colors']} style={{marginLeft: 3}}/> }
    </TagBox>
  );
};

export default Tag