import React, { useEffect, useRef, useState } from 'react'
import { Popover, Button, Input, FormControl, Icon, Hidden, useBreakpointValue } from 'native-base'
import { MaterialIcons } from '@expo/vector-icons'
import { set } from 'ramda'

export default function Search({
  onSearch,
  initialValue,
  placeholder,
  shouldCollapseOnPhone = true,
}: {
  onSearch: (value: string) => void
  initialValue?: string
  placeholder?: string
  shouldCollapseOnPhone?: boolean
}) {
  const [value, setValue] = useState('')
  const [searchedValue, setSearchedValue] = useState('')
  const isCollapsed = useBreakpointValue({ base: shouldCollapseOnPhone, md: false })

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  const search = (): void => {
    onSearch(value)
    setSearchedValue(value)
  }

  const onClear = () => {
    setValue('')
    onSearch('')
    setSearchedValue('')
  }

  const showClearButton = value !== '' && searchedValue == value

  return (
    <>
      {!isCollapsed && (
        <Input
          // _dark={{ placeholderTextColor: 'base.400', borderColor: 'transparent', bg: 'base.900' }}
          testID="page-search-input"
          placeholder={placeholder || 'search...'}
          value={value}
          onChangeText={(text) => setValue(text)}
          onKeyPress={(e) => e.nativeEvent.key == 'Enter' && search()}
          InputRightElement={
            showClearButton ? (
              <Icon onPress={(e) => onClear()} as={<MaterialIcons name="clear" />} size={5} mr="2" color="base.400" />
            ) : (
              <Icon onPress={(e) => search()} as={<MaterialIcons name="search" />} size={5} mr="2" color="base.400" />
            )
          }
        />
      )}
      {isCollapsed && (
        <Popover
          trigger={(triggerProps) => (
            <Button
              leftIcon={<Icon as={MaterialIcons} size="md" name="search" color="base.400" />}
              size="sm"
              variant="ghost"
              colorScheme="base"
              {...triggerProps}
            />
          )}>
          <Popover.Content width="56">
            <Popover.Arrow />
            <Popover.CloseButton />
            <Popover.Header>Search for anything</Popover.Header>
            <Popover.Body>
              <FormControl>
                <Input
                  rounded="sm"
                  fontSize="xs"
                  value={value}
                  onChangeText={(text) => setValue(text)}
                  onKeyPress={(e) => e.nativeEvent.key == 'Enter' && search()}
                  InputRightElement={
                    showClearButton ? (
                      <Icon
                        onPress={(e) => onClear()}
                        as={<MaterialIcons name="clear" />}
                        size={5}
                        mr="2"
                        color="base.400"
                      />
                    ) : (
                      <Icon
                        onPress={(e) => search()}
                        as={<MaterialIcons name="search" />}
                        size={5}
                        mr="2"
                        color="base.400"
                      />
                    )
                  }
                />
              </FormControl>
            </Popover.Body>
          </Popover.Content>
        </Popover>
      )}
    </>
  )
}
