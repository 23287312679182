import React from 'react'
import { useTheme, ITheme } from 'native-base'
import { Platform } from 'react-native'

export default function InjectCSSVars() {
  const { colors } = useTheme()

  if (Platform.OS !== 'web') return null

  const { base, primary } = colors

  const css = ['base', 'primary']
    .map((scheme: any, i) => {
      return Object.keys(colors[scheme])
        .map((key, i) => {
          return `--color-${scheme}-${key}: ${colors[scheme][key]};`
        })
        .join('\n')
    })
    .join('\n')

  return <style dangerouslySetInnerHTML={{ __html: `:root {${css}}` }}></style>
}
