import * as R from 'ramda'
import React from 'react'
import { TableProps, Table } from './table'

const objExistsBy = (getObjValue, obj) => R.any(R.eqBy(getObjValue, obj))

const toggleObjBy = R.curry((getObjValue, list, obj) =>
  R.ifElse(objExistsBy(getObjValue, obj), R.reject(R.eqBy(getObjValue, obj)), R.append(obj))(list)
)

type TableSelectableProps<T> = TableProps & {
  data: T[]
  getValue: (entry: any) => any
  onSelectionChange: (s: T[]) => void
  initialSelection?: T[]
  tableHeader: JSX.Element
  renderRow: (rowData: T, isSelected: boolean, onPress: () => void) => React.ReactElement
}

export default function TableSelectable<T>({
  data,
  getValue = R.prop('id'),
  onSelectionChange,
  tableHeader,
  renderRow,
  initialSelection,
  ...props
}: TableSelectableProps<T>) {
  const [selection, setSelection] = React.useState<any[]>(initialSelection || [])

  const toggleSelection = (v: T) => {
    const newSelection = toggleObjBy(getValue, selection, v) as any[]
    setSelection(newSelection)
    onSelectionChange(newSelection)
  }

  return (
    <Table {...props}>
      {tableHeader}
      {data.map((v) => {
        const isSelected = objExistsBy(getValue, v)(selection)
        return renderRow(v, isSelected, () => {
          toggleSelection(v)
        })
      })}
    </Table>
  )
}
