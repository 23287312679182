import axios from 'axios'

export function compose<T = any>(fns: ((data: T) => T)[], data: T) {
  return fns.reduce((res: any, fn: (h: any) => any) => fn(res), data)
}

export const addAuthorization =
  (token: string) =>
  (headers: any): any => ({ ...headers, Authorization: `Bearer ${token}` })

export function addContentType(headers: any): any {
  return { ...headers, 'content-type': 'application/json' }
}

export function addHeadersToAxios(token: string) {
  axios.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${token}`
      request.headers.Authorisation = `Bearer ${token}`
      request.headers['Content-TYpe'] = 'application/json'
      // request.headers['X-CSRF-Token'] = csrfToken,
    }
    return request
  })
}
