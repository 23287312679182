import React from "react";

interface DimensionsType {
  width: number;
  height: number;
}

export const DimensionsCtx = React.createContext<DimensionsType>({
  width: 0,
  height: 0,
})