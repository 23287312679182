import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Apps, getAPIURL } from './api_url'
import * as Security from './authentication/index'
import React, { useContext } from 'react'

const RestaurantsGraphqlClientCtx = React.createContext<ApolloClient<any> | null>(null)
const OrdersGraphqlClientCtx = React.createContext<ApolloClient<any> | null>(null)

export const RestaurantsGraphqlClient = (props: React.PropsWithChildren<any>) => {
  const api = Security.useAuthAPI()

  const httpLink = createHttpLink({
    uri: `${getAPIURL(Apps.RESTAURANTS_API)}/graphql`,
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: { ...headers, ...api.authorize() },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  })

  return (
    <RestaurantsGraphqlClientCtx.Provider value={client}>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </RestaurantsGraphqlClientCtx.Provider>
  )
}

export const OrdersGraphqlClient = (props: React.PropsWithChildren<any>) => {
  const api = Security.useAuthAPI()

  const httpLink = createHttpLink({
    uri: `${getAPIURL(Apps.ORDERS_V5)}/graphql`,
  })

  console.log('ORDER API URL:', Apps.ORDERS_V5)

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: { ...headers, ...api.authorize() },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  })

  return (
    <OrdersGraphqlClientCtx.Provider value={client}>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </OrdersGraphqlClientCtx.Provider>
  )
}

export function useRestaurantsGraphqlClient(): ApolloClient<any> {
  const api = useContext(RestaurantsGraphqlClientCtx)
  return api as ApolloClient<any>
}

export function useOrdersGraphqlClient(): ApolloClient<any> {
  const api = useContext(OrdersGraphqlClientCtx)
  return api as ApolloClient<any>
}
