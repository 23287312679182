import * as R from 'ramda'
import React, { FC, useContext } from 'react'
import {
  Box,
  HStack,
  Stack,
  Text,
  VStack,
  IBoxProps,
  Pressable,
  Menu,
  Icon,
  Button,
  useBreakpointValue,
} from 'native-base'
import useBreakpoint from 'ui/hooks/use-breakpoint'
import { flattenChildren } from './utils'
import { Breakpoint } from 'ui/types'
import { Entypo } from '@expo/vector-icons'
import { Link } from '../../router'
import { LinkProps } from 'react-router-dom'

function isText(value: any) {
  return typeof value === 'string' || typeof value === 'number'
}

const TableCtx = React.createContext({})

const alignItems: { [key: string]: string } = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

type Size = number | string | undefined
type Align = 'left' | 'center' | 'right'

type TdProps = IBoxProps & {
  align?: Align
  size?: Size
  omit?: Breakpoint[]
  style?: {}
  label?: string | React.ReactElement<any>
  [key: string]: any
}

const getSizeStyle = (size: Size) => {
  if (typeof size === 'number') {
    return { flexGrow: 0, flexShrink: 0, flexBasis: size }
  }
  return { flex: 1 }
}

export const Td: FC<TdProps> = ({ children, size, align = 'left', omit, label, ...rest }) => {
  const { _td } = useContext<TableProps>(TableCtx)
  const bp = useBreakpoint()
  const sizeStyle = useBreakpointValue({
    base: {},
    md: getSizeStyle(size),
  })

  if (omit && omit.includes(bp)) return null

  const alignStyle = !!align && bp !== 'phone' ? alignItems[align] : 'flex-start'

  const _isText = isText(children)
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={alignStyle}
      testID="table-cell"
      {...sizeStyle}
      {..._td}
      {...rest}>
      {_isText && `${bp === 'phone' && label ? label + ': ' : ''}${children}`}
      {!_isText && children}
    </Box>
  )
}
Td.displayName = 'Td'

interface TrAction {
  label: string
  onPress: () => void
}

type TrProps = IBoxProps & {
  index?: number
  onPress?: () => void

  _link?: LinkProps

  actions?: TrAction[]
  selected?: boolean
  children?: React.ReactNode
}

export const Tr: FC<TrProps> = ({ children, onPress, _link, index = 0, selected = false, actions, ...rest }) => {
  const { stripped } = useContext<Partial<TableProps>>(TableCtx)

  const isOdd = stripped && index % 2 !== 0

  const Row = (
    <Box
      aria-label="Tr"
      display="flex"
      flexDirection="row"
      _light={{ bg: selected ? 'info.100' : isOdd ? 'base.200:alpha.20' : 'transparent' }}
      _dark={{ bg: selected ? 'info.900' : isOdd ? 'base.500:alpha.10' : 'transparent' }}
      py={[2, 2, 0]}
      testID="table-row"
      {...rest}>
      <Box display="flex" flexDirection={['column', null, 'row']} flex={1}>
        {children}
      </Box>
      {!!actions && actions.length > 0 && (
        <Menu
          placement="left top"
          trigger={(triggerProps) => {
            return (
              <Box size={6} alignSelf="center">
                <Button
                  leftIcon={<Icon as={Entypo} size={4} name="dots-three-vertical" />}
                  size="sm"
                  variant="ghost"
                  colorScheme="base"
                  {...triggerProps}
                />
              </Box>
            )
          }}>
          {actions.map((action, i) => (
            <Menu.Item key={i} onPress={action.onPress}>
              {action.label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </Box>
  )

  if (typeof onPress === 'function') {
    return (
      <Pressable {...{ onPress }} _hover={{ _light: { bg: 'info.100' }, _dark: { bg: 'info.900' } }}>
        {Row}
      </Pressable>
    )
  } else if (_link) {
    return (
      <Link style={{ textDecoration: 'none' }} {..._link}>
        <Pressable _hover={{ _light: { bg: 'info.100' }, _dark: { bg: 'info.900' } }}>{Row}</Pressable>
      </Link>
    )
  }
  return Row
}
Tr.displayName = 'Tr'

export type TableProps = IBoxProps & {
  stripped?: boolean
  _td?: {}
  style?: {}
}

export const Table: FC<TableProps> = ({ stripped = false, _td, children, ...rest }) => {
  const bp = useBreakpoint()

  const defaultTdStyle = useBreakpointValue({
    base: { px: 4, py: 0 },
    md: { px: 4, py: 2 },
  })
  const tdStyle = _td || defaultTdStyle

  if (Array.isArray(children) && children.length === 0) return null

  const tableChildren = flattenChildren(children)
  const firstRow = tableChildren.find(
    (child) => R.path(['type', 'displayName'], child) === 'Tr'
  ) as React.ReactElement<any>

  if (typeof firstRow !== 'object') {
    return <Text color={'red.500'}>Direct child of a StyledTable should be a Tr</Text>
  }

  const hasActions = !!firstRow.props.actions && firstRow.props.actions.length > 0

  const headers = flattenChildren(firstRow.props.children)
    .filter(
      (child) =>
        typeof child === 'object' &&
        typeof child.props === 'object' &&
        R.path(['type', 'displayName'], child) === 'Td' &&
        R.path(['props'], child) &&
        (!child.props.omit || !child.props.omit.includes(bp))
    )
    .map((child) => {
      const c = child as React.ReactElement<any, string | React.JSXElementConstructor<any>>
      return {
        label: c.props.label,
        size: c.props.size,
        align: c.props.align,
      }
    })
    .filter((h) => !!h.label)

  return (
    <TableCtx.Provider
      value={{
        stripped,
        _td: tdStyle,
      }}>
      <Box display="flex" flexDirection="column" {...rest}>
        {bp !== 'phone' && headers.length > 0 && (
          <Tr
            pt="2"
            _light={{ bg: 'base.100', borderBottomColor: 'base.300' }}
            _dark={{ bg: 'base.800', borderBottomColor: 'base.700' }}
            borderBottomWidth="1"
            index={-1}>
            {headers.map(({ label, size, align }, i) => (
              <Td key={i} size={size} align={align} {..._td}>
                <Text fontWeight="bold" fontSize="xs" _light={{ color: 'base.600' }} _dark={{ color: 'base.400' }}>
                  {label}
                </Text>
              </Td>
            ))}
            {hasActions && <Box size={6}></Box>}
          </Tr>
        )}
        {tableChildren.map((trchild: number | string | React.ReactElement<TrProps>, trindex: number) => {
          if (!!trchild && R.path(['type', 'displayName'], trchild) === 'Tr') {
            return React.cloneElement(trchild, {
              index: trindex,
            })
          } else return trchild
        })}
      </Box>
    </TableCtx.Provider>
  )
}
Table.displayName = 'Table'
