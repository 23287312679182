import { useState, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

export default function usePersistentState<T>(key: string, defaultValue: T): [T, (v: T) => void, boolean] {
  const [value, setValue] = useState<T>(defaultValue)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    AsyncStorage.getItem(key)
      .then((savedValue: string | null) => {
        if (savedValue) {
          setValue(JSON.parse(savedValue))
        }
      })
      .finally(() => setReady(true))
  }, [])

  const setValueEx = (v: T) => {
    AsyncStorage.setItem(key, JSON.stringify(v))
    setValue(v)
  }
  return [value, setValueEx, ready]
}
