import React, { useRef } from 'react'
import { Box, HStack, ScrollView, Text } from 'native-base'
import { ScrollView as ScrollViewT } from 'react-native'
import { LinkButton } from 'ui'

export interface TabDefinition {
  path: string
  label: string
}

export default function TabNavigation(props: { tabs: TabDefinition[] }) {
  const ref = useRef<ScrollViewT>()

  const scrollToButton = (x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
    if (ref.current) {
      ref.current.scrollTo({ x: pageX, y: 0, animated: true })
    }
  }

  return (
    <Box position="relative" zIndex={10} _light={{ shadow: 1 }} _dark={{ shadow: 4 }}>
      <ScrollView
        _light={{ bg: 'base.50' }}
        _dark={{ bg: 'base.900' }}
        flex={1}
        keyboardShouldPersistTaps="handled"
        horizontal
        showsHorizontalScrollIndicator={false}
        ref={ref}>
        <HStack>
          {props.tabs.map((n, i) => (
            <LinkButton
              key={i}
              to={n.path}
              colorScheme="base"
              bg="transparent"
              onActivate={scrollToButton}
              _hover={{
                bg: 'transparent',
              }}
              _focus={{
                bg: 'transparent',
              }}
              _pressed={{
                bg: 'transparent',
              }}
              _light={{ _text: { fontSize: 'sm', fontWeight: '600', color: 'base.500' } }}
              _dark={{ _text: { fontSize: 'sm', fontWeight: '600', color: 'base.400' } }}
              _active={{
                bg: 'transparent',
                borderBottomColor: 'primary.500',
                borderBottomWidth: '2px',
                borderRadius: '0px',
                _light: { _text: { color: 'base.900', fontWeight: '600' } },
                _dark: { _text: { color: 'base.200', fontWeight: '600' } },
              }}>
              {n.label}
            </LinkButton>
          ))}
        </HStack>
      </ScrollView>
    </Box>
  )
}
