export default function formatBackendErrors(errObj: { message: string } | any): string {
  let errString = ''
  if (typeof errObj == 'string') {
    errString = errObj
  } else if ('message' in errObj) {
    errString = errObj.message
  } else if ('response' in errObj) {
    errString = errObj.response
  } else {
    const { errors } = errObj
    Object.keys(errors).map((key) => {
      errors[key].map((err: string) => (errString += ''.concat(key, ': ', err, '. ')))
    })
  }
  return errString
}
