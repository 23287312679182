import { Maybe } from 'app/types'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import { BankConnection, BankConnectionForm, Organizable, usesManualConnection } from './model'
import { useBankConnectionAPI } from './service'

import PageHeader from 'ui/components/page-header'
import { Stack, ScrollView, Box, Button, Text, Icon, Center, Spinner, HStack, Toast, useToast } from 'native-base'

import { Feather, FontAwesome, AntDesign, MaterialCommunityIcons } from '@expo/vector-icons'
import Tabs from 'ui/macros/tabs'
import Card, { CardContent, CardTitle } from 'ui/components/card'
import BankConnectionEditor from './form'
import { AxiosResponse } from 'axios'
import { Cell, Grid } from 'ui/components/grid'
import { AuditResource, useAuditResourceService } from '../audit/service'
import useCabbagePayListener from './cabbage-gateway'
import { RestrictResource, SecureResourceAccess } from '../access_groups/contexts/secure_resource_access'
import { AG_ROLES } from '../access_groups/constants/ag-roles'

export function BankConnectionUI(): JSX.Element {
  const params = useParams<{ id: string }>()
  return (
    <AuditResource resourceType="bank_connection" resourceId={String(params.id)}>
      <SecureResourceAccess
        view_role={AG_ROLES.BANK_CONNECTIONS}
        admin_role={AG_ROLES.BANK_CONNECTIONS_ADMIN}
        resource_type={'bank_connection'}
        resource_id={String(params.id)}
      >
        <BankConnectionImpl />
      </SecureResourceAccess>
    </AuditResource>
  )
}
function BankConnectionImpl(): JSX.Element {
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const api = useBankConnectionAPI()
  const [bankConnection, setBankConnection] = useState<Maybe<BankConnection>>(null)
  const [editMode, setEditMode] = useState(false)
  const auditResourceAPI = useAuditResourceService()

  useEffect(() => {
    if (params.id) {
      api.loadBankConnection(parseInt(params.id)).then((res) => setBankConnection(res.data))
    }
  }, [params.id])

  if (!bankConnection) {
    return (
      <Center flex={1}>
        <Spinner accessibilityLabel="Loading..." />
      </Center>
    )
  }

  const tabNavs = [
    { path: 'overview', label: 'General Info' },
    { path: 'audit', label: 'History' },
  ]

  const onEdit = () => {
    setEditMode(true)
  }

  const onSave = (bankConnection: Maybe<Partial<Organizable<BankConnectionForm>>>): Promise<BankConnection> => {
    if (bankConnection) {
      return api
        .save(bankConnection)
        .then((data: AxiosResponse<BankConnection>) => {
          auditResourceAPI.createLogWithObj('update', data.data, bankConnection)
          setEditMode(false)
          setBankConnection(data.data)
          navigate(`../${data.data.id}`)
          return Promise.resolve(data.data)
        })
        .catch((error) => {
          setEditMode(true)
          return Promise.reject(error)
        })
    } else {
      setEditMode(false)
      return Promise.reject('')
    }
  }

  return (
    <>
      <Box flex={1}>
        <Box>
          <PageHeader
            renderAction={
              <RestrictResource role={AG_ROLES.BANK_CONNECTIONS_ADMIN}>
                <Button onPress={onEdit} size={'sm'} variant={'subtle'} rightIcon={<Icon as={Feather} name={'edit'} />}>
                  Edit
                </Button>
              </RestrictResource>
            }
            title={bankConnection.name}
            onBack={() => navigate('..')}
            breadcrumbs={[{ label: 'All Bank Connections', path: '/bank-connections' }, { label: bankConnection.name }]}
          />
        </Box>
        <Stack>
          <Tabs tabs={tabNavs} />
          <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="handled">
            <Outlet />
          </ScrollView>
        </Stack>
      </Box>
      {editMode && <BankConnectionEditor bankConnection={bankConnection} onClose={onSave} />}
    </>
  )
}

export enum MoveCabbageErrors {
  ExpiredBankToken = '5401',
}

export function Overview(): JSX.Element {
  const params = useParams<{ id: string }>()
  const api = useBankConnectionAPI()
  const navigate = useNavigate()
  const toast = useToast()
  const [bankConnection, setBankConnection] = useState<Maybe<BankConnection>>(null)

  useEffect(() => {
    if (params.id) {
      api.loadBankConnection(parseInt(params.id)).then((res) => setBankConnection(res.data))
    }
  }, [params.id])

  const cbl = useCabbagePayListener(api, bankConnection, setBankConnection)

  if (!bankConnection) {
    return (
      <Center flex={1}>
        <Spinner accessibilityLabel="Loading..." />
      </Center>
    )
  }

  const shouldReconnect = bankConnection.error_code && bankConnection.error_code == MoveCabbageErrors.ExpiredBankToken

  const getConnectMessage = (bc: BankConnection) => {
    if (!bc.has_token) {
      return 'Please connect to your bank account'
    }
    if (shouldReconnect) {
      return 'Bank token is expired. Please reconnect to your account!'
    }
    if (!bc.healthy) {
      return 'There is a problem with your bank account!'
    }
    return 'Everything is good. We will deposit all the checks into your bank account!'
  }

  const onDelete = () => {
    if (confirm('Are you sure you want to remove this bank connection?')) {
      api
        .delete(bankConnection)
        .then(() =>
          toast.show({
            id: 'delete-success',
            title: 'Remove Bank Account',
            variant: 'subtle',
            description: `${bankConnection.name} was removed`,
            duration: 3000,
            onCloseComplete: () => navigate('../..'),
          })
        )
        .catch((err) => {
          if (err.response) {
            toast.show({
              id: 'delete-error',
              title: 'Remove Bank Account',
              variant: 'subtle',
              description: err.response.data.message,
              duration: 5000,
            })
          }
        })
    }
  }

  return (
    <Box>
      <Box p="2">
        <HStack justifyContent="left">
          <RestrictResource role={AG_ROLES.BANK_CONNECTIONS_ADMIN}>
            <Button
              onPress={onDelete}
              variant="outline"
              colorScheme={'danger'}
              leftIcon={<Icon as={Feather} name={'trash'} />}
            >
              Delete
            </Button>
          </RestrictResource>
        </HStack>
      </Box>
      <Card colorScheme="primary" mb="4">
        <CardTitle>
          <Text variant="2" fontWeight="800">
            {bankConnection.name} ({bankConnection.uuid})
          </Text>
        </CardTitle>
        <CardContent>
          <Grid cols={2} m="4">
            <Label>Contact Name</Label>
            <Value>{`${bankConnection.contact_first_name} ${bankConnection.contact_first_name}`}</Value>
            <Label>Contact Email</Label>
            <Value>{bankConnection.contact_email}</Value>
            <Label>Contact Phone</Label>
            <Value>{bankConnection.contact_phone}</Value>
            <Label>Address</Label>
            <Value>{bankConnection.address}</Value>
            {bankConnection.has_token && (
              <>
                <Label>Bank Name</Label>
                <Value>{bankConnection.bank_name}</Value>
                <Label>Bank Account Last 4 digits</Label>
                <Value>{bankConnection.bank_account_last_four}</Value>
              </>
            )}
            {(bankConnection.error_message || bankConnection.error_code) && (
              <>
                <Label>Error</Label>
                <Value>
                  <Text highlight>
                    {bankConnection.error_code || 'n/a'} - {bankConnection.error_message || 'n/a'}
                  </Text>
                </Value>
              </>
            )}
            {usesManualConnection(bankConnection) && (
              <>
                <Label>Routing Number</Label>
                <Value>{bankConnection.routing_number || 'N/A'}</Value>
                <Label>Account Number</Label>
                <Value>{bankConnection.account_number || 'N/A'}</Value>
              </>
            )}
          </Grid>
          {!usesManualConnection(bankConnection) && (
            <Center>
              <Box>
                <Text fontSize={'xl'}>
                  <Icon
                    name={bankConnection.has_token && bankConnection.healthy ? 'check' : 'warning'}
                    as={AntDesign}
                    size="lg"
                    color="primary.600"
                    colorScheme={'warning'}
                  />{' '}
                  {getConnectMessage(bankConnection)}
                </Text>
              </Box>
              {(!bankConnection.has_token || shouldReconnect) && (
                <Button
                  onPress={cbl.startConnection}
                  colorScheme={cbl.ready ? 'secondary' : 'muted'}
                  size="lg"
                  disabled={!cbl.ready}
                  mt="4"
                  leftIcon={<Icon as={MaterialCommunityIcons} name={!cbl.ready ? 'bank-off-outline' : 'bank'} />}
                >
                  {shouldReconnect ? 'Reconnect' : 'Connect'}
                </Button>
              )}
              {bankConnection.has_token && (
                <Button
                  onPress={cbl.startConnection}
                  colorScheme={cbl.ready ? 'secondary' : 'muted'}
                  disabled={!cbl.ready}
                  size={'sm'}
                  mt="4"
                  leftIcon={<Icon as={MaterialCommunityIcons} name={!cbl.ready ? 'bank-off-outline' : 'bank'} />}
                >
                  Connect to a different bank account
                </Button>
              )}
            </Center>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export function Label(props: PropsWithChildren<any>) {
  return <Cell p="2">{props.children}</Cell>
}

export function Value(props: PropsWithChildren<any>) {
  return <Cell p="2">{props.children}</Cell>
}
