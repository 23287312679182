import React, { useEffect, useState } from 'react'
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Divider,
  FormControl,
  IconButton,
  Icon,
  Hidden,
  Input,
  PresenceTransition,
  ScrollView,
} from 'native-base'

import { BaseForm, Error } from 'ui'

import { MaterialIcons } from '@expo/vector-icons'

import { Controller, useForm } from 'react-hook-form'
import { AuthenticationApis } from '../api/service'
import { GuestAction, LoggedInUser, LoginMethod } from '../model'
import { Ionicons } from '@expo/vector-icons'
import { AnimateAndRender } from './index'

export default function SignInForm(props: {
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
  onChangeAction: (action: GuestAction) => void
}) {
  const [showPass, setShowPass] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = (params: any) => {
    setLoading(true)
    AuthenticationApis.signIn({ ...params, email: params.email.trim().toLowerCase() })
      .then((u) => props.onLoginInSuccess(u, LoginMethod.SignIn))
      .catch(setError)
      .finally(() => setLoading(false))
  }
  const validation = {
    email: {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: 'must be a valid email address',
      },
    },
    password: {
      required: 'Password is required',
    },
  }
  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '40px', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '40px', md: '0' }}>
      <ScrollView px="1">
        <AnimateAndRender>
          <BaseForm onSubmit={formMethods.handleSubmit(onSubmit)}>
            <VStack space="7">
              <Text bold _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="xl">
                Sign In
              </Text>

              <VStack>
                <VStack space="3">
                  <VStack space={{ base: '4', md: '4' }}>
                    <FormControl isRequired isInvalid={'email' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="Email"
                            size="md"
                            py="3"
                            InputRightElement={
                              <Icon size="5" mx="2.5" color="coolGray.400" as={MaterialIcons} name="email" />
                            }
                            autoFocus
                            _dark={{
                              borderColor: 'coolGray.700',
                              placeholderTextColor: 'coolGray.400',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="email"
                        rules={validation.email}
                      />
                      <FormControl.ErrorMessage>{formMethods.formState.errors.email?.message}</FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={'password' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size="md"
                            py="3"
                            type={showPass ? 'text' : 'password'}
                            placeholder="Password"
                            InputRightElement={
                              <IconButton
                                variant="unstyled"
                                rounded="full"
                                icon={
                                  <Icon
                                    size="5"
                                    color="coolGray.400"
                                    as={MaterialIcons}
                                    name={showPass ? 'visibility-off' : 'visibility'}
                                  />
                                }
                                onPress={() => {
                                  setShowPass(!showPass)
                                }}
                              />
                            }
                            _dark={{
                              borderColor: 'coolGray.700',
                              placeholderTextColor: 'coolGray.400',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="password"
                        rules={validation.password}
                      />
                      <FormControl.ErrorMessage>
                        {formMethods.formState.errors.password?.message}
                      </FormControl.ErrorMessage>
                    </FormControl>
                  </VStack>
                  <Button
                    variant="unstyled"
                    ml="auto"
                    py="1"
                    _text={{
                      fontSize: 'xs',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                    }}
                    _light={{
                      _text: {
                        color: 'primary.700',
                      },
                    }}
                    _dark={{
                      _text: {
                        color: 'primary.400',
                      },
                    }}
                    onPress={() => props.onChangeAction(GuestAction.forget_password)}>
                    Forgot password?
                  </Button>

                  {error && <Error>{error}</Error>}

                  <Button
                    mt="5"
                    size="md"
                    py="3"
                    borderRadius="4"
                    isLoading={loading}
                    isLoadingText="Signing In"
                    _text={{
                      fontWeight: 'medium',
                    }}
                    onPress={formMethods.handleSubmit(onSubmit)}>
                    Sign In
                  </Button>
                  <HStack mt="5" space="2" mb={{ base: 6, md: 7 }} alignItems="center" justifyContent="center">
                    <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                    <Text fontWeight="medium" _light={{ color: 'coolGray.300' }} _dark={{ color: 'coolGray.500' }}>
                      or
                    </Text>
                    <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                  </HStack>
                </VStack>
              </VStack>
            </VStack>
            <VStack mb="4" space="4" safeAreaBottom alignItems="center" justifyContent="center">
              <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
                Not a member yet?
              </Text>
              <Button
                px="16"
                variant="outline"
                colorScheme="primary"
                borderColor="primary.800"
                _text={{
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}
                _light={{
                  _text: {
                    color: 'primary.900',
                  },
                }}
                _dark={{
                  _text: {
                    color: 'primary.500',
                  },
                }}
                _hover={{
                  _text: {
                    color: 'warning.600',
                  },

                  background: 'warning.50',
                  borderColor: 'warning.400',
                }}
                onPress={() => props.onChangeAction(GuestAction.sign_up)}>
                Create an Account
              </Button>
            </VStack>
          </BaseForm>
        </AnimateAndRender>
      </ScrollView>
    </VStack>
  )
}
