import React, { useRef, useState } from 'react'
import {
  Button,
  HStack,
  VStack,
  Text,
  Divider,
  FormControl,
  IconButton,
  Icon,
  Input,
  Checkbox,
  Link,
  ScrollView,
} from 'native-base'
import { BaseForm, Error } from 'ui'

import { MaterialIcons } from '@expo/vector-icons'

import { Controller, useForm } from 'react-hook-form'
import { AuthenticationApis } from '../api/service'
import { GuestAction, LoggedInUser, LoginMethod } from '../model'
import { Ionicons, Octicons } from '@expo/vector-icons'
import { AnimateAndRender } from './index'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function SignUpForm(props: {
  onLoginInSuccess: (params: LoggedInUser, method: LoginMethod) => void
  onChangeAction: (action: GuestAction) => void
}) {
  const [showPass, setShowPass] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [unconfirmedAccountId, setUnconfirmedAccountId] = useState<number | null>(null)

  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
      name: '',
      company: '',
      phone: '',
    },
  })

  const password = useRef({})
  password.current = formMethods.watch('password', undefined)

  const onSubmit = (params: any) => {
    setLoading(true)
    AuthenticationApis.signUp({ ...params, email: params.email.trim().toLowerCase() })
      .then((res) => setUnconfirmedAccountId(res.id))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const validation = {
    name: {
      required: 'Name is required',
      minLength: { value: 3, message: 'Too few characters' },
    },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: 'must be a valid email address',
      },
    },
    company: {
      required: 'Business name is required',
      minLength: { value: 3, message: 'Too few characters' },
    },
    password: {
      required: 'Password is required',
      minLength: { value: 6, message: 'Minimun 6 characters' },
    },
    confirm_password: {
      validate: (value: string) => {
        if (!value && !password.current) return null
        return value === password.current || 'Passwords do not match'
      },
    },
    phone: {
      required: 'Contact number is required',
      validate: (data) => (isValidPhoneNumber(data) ? null : `must be a valid phone number`),
    },
    tnc: {
      required: 'Please Read and accept tnc',
    },
  }

  if (unconfirmedAccountId)
    return (
      <TokenConfirmation
        id={unconfirmedAccountId}
        email={formMethods.getValues('email')}
        password={formMethods.getValues('password')}
        company={formMethods.getValues('company')}
        onLoginInSuccess={(u) => props.onLoginInSuccess(u, LoginMethod.SignUp)}
        onChangeAction={props.onChangeAction}
      />
    )

  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '40px', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '40px', md: '0' }}>
      <ScrollView>
        <AnimateAndRender>
          <BaseForm onSubmit={formMethods.handleSubmit(onSubmit)}>
            <VStack>
              <Text bold _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="xl" my="2">
                Let's get started by creating your account.
              </Text>
              <VStack mx="1">
                <HStack mb="0" safeAreaBottom alignItems="center" justifyContent="space-between">
                  <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
                    Already have an account?
                  </Text>
                  <Button
                    paddingLeft="1"
                    variant="link"
                    _text={{
                      fontWeight: 'bold',
                    }}
                    _light={{
                      _text: {
                        color: 'primary.900',
                      },
                    }}
                    _dark={{
                      _text: {
                        color: 'primary.500',
                      },
                    }}
                    _hover={{
                      _text: {
                        color: 'warning.600',
                      },
                    }}
                    onPress={() => props.onChangeAction(GuestAction.sign_in)}>
                    Sign In
                  </Button>
                </HStack>
                <VStack space="3">
                  <VStack space="4">
                    <FormControl isInvalid={'email' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size="md"
                            placeholder="Email"
                            autoFocus
                            InputLeftElement={
                              <Icon as={<MaterialIcons name="email" />} size={5} mx="2" color="coolGray.400" />
                            }
                            _dark={{
                              borderColor: 'coolGray.700',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="email"
                        rules={validation.email}
                      />
                      <FormControl.ErrorMessage>{formMethods.formState.errors.email?.message}</FormControl.ErrorMessage>
                    </FormControl>

                    <HStack justifyContent="space-between" display="flex">
                      <FormControl isRequired isInvalid={'name' in formMethods.formState.errors} w="48%">
                        <Controller
                          control={formMethods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              size="md"
                              placeholder="Name"
                              InputLeftElement={
                                <Icon as={<MaterialIcons name="person" />} size={4} mx="2" color="coolGray.400" />
                              }
                              _dark={{
                                borderColor: 'coolGray.700',
                              }}
                              _light={{
                                borderColor: 'coolGray.300',
                              }}
                            />
                          )}
                          name="name"
                          rules={validation.name}
                        />

                        <FormControl.ErrorMessage>
                          {formMethods.formState.errors.name?.message}
                        </FormControl.ErrorMessage>
                      </FormControl>

                      <FormControl isRequired isInvalid={'phone' in formMethods.formState.errors} w="47%">
                        <Controller
                          control={formMethods.control}
                          render={({ field }) => (
                            <PhoneInput
                              defaultCountry="US"
                              placeholder="Phone"
                              inputComponent={Input}
                              value={field.value}
                              onChange={field.onChange}
                              flex="1"
                            />
                          )}
                          name="phone"
                          rules={validation.phone}
                        />
                        <FormControl.ErrorMessage>
                          {formMethods.formState.errors.phone?.message}
                        </FormControl.ErrorMessage>
                      </FormControl>
                    </HStack>

                    <FormControl isRequired isInvalid={'company' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size="md"
                            placeholder="Business Name (DBA)"
                            InputLeftElement={
                              <Icon size="4" color="coolGray.400" as={Octicons} name={'organization'} mx="2" />
                            }
                            _dark={{
                              borderColor: 'coolGray.700',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="company"
                        rules={validation.company}
                      />
                      <FormControl.ErrorMessage>
                        {formMethods.formState.errors.company?.message}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={'password' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size="md"
                            type={showPass ? 'text' : 'password'}
                            placeholder="Password"
                            InputRightElement={
                              <IconButton
                                variant="unstyled"
                                icon={
                                  <Icon
                                    size="4"
                                    color="coolGray.400"
                                    as={MaterialIcons}
                                    name={showPass ? 'visibility-off' : 'visibility'}
                                  />
                                }
                                onPress={() => {
                                  setShowPass(!showPass)
                                }}
                              />
                            }
                            _dark={{
                              borderColor: 'coolGray.700',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="password"
                        rules={validation.password}
                      />
                      <FormControl.ErrorMessage>
                        {formMethods.formState.errors.password?.message}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={'confirm_password' in formMethods.formState.errors}>
                      <Controller
                        control={formMethods.control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            size="md"
                            type={showPass ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            _dark={{
                              borderColor: 'coolGray.700',
                            }}
                            _light={{
                              borderColor: 'coolGray.300',
                            }}
                          />
                        )}
                        name="confirm_password"
                        rules={validation.confirm_password as any}
                      />
                      <FormControl.ErrorMessage>
                        {formMethods.formState.errors.confirm_password?.message}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl>
                      <HStack justifyContent="start">
                        <Controller
                          control={formMethods.control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              accessibilityLabel="I agree terms & conditions"
                              marginTop="1"
                              marginRight="3"
                              borderColor={formMethods.formState.errors.tnc && 'red.600'}
                            />
                          )}
                          name="tnc"
                          rules={validation.tnc}
                        />

                        <Text fontWeight={500} fontSize="sm" pt="0.5" color="base.500" _dark={{ color: 'base.400' }}>
                          {`I agree to Zuppler's `}
                          <Link href="http://secure.zuppler.com/terms-en.html" isExternal>
                            <Text _dark={{ color: 'primary.500' }}>Terms of use</Text>
                          </Link>
                          {` and I acknowledge I have read the `}
                          <Link href="https://www.zuppler.com/privacy-policy" isExternal>
                            <Text _dark={{ color: 'primary.500' }}>Privacy Policy</Text>
                          </Link>
                        </Text>
                      </HStack>
                    </FormControl>
                  </VStack>

                  {error && <Error>{error}</Error>}

                  <Button
                    mt="5"
                    size="md"
                    borderRadius="4"
                    isLoading={loading}
                    isLoadingText="Signing Up"
                    _text={{
                      fontWeight: 'medium',
                    }}
                    onPress={formMethods.handleSubmit(onSubmit)}>
                    Create My Account
                  </Button>
                </VStack>
              </VStack>
            </VStack>
          </BaseForm>
        </AnimateAndRender>
      </ScrollView>
    </VStack>
  )
}

function TokenConfirmation(props: {
  id: number
  email: string
  password: string
  company: string
  onLoginInSuccess: (params: LoggedInUser) => void
  onChangeAction: (action: GuestAction) => void
}) {
  const [token, setToken] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  console.log({ props })

  const onSubmit = () => {
    setLoading(true)
    AuthenticationApis.confirmAccount({ id: props.id, token, company: props.company.trim() })
      .then(() => AuthenticationApis.signIn({ email: props.email, password: props.password }))
      .then((res) => props.onLoginInSuccess(res))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  return (
    <VStack
      flex={1}
      px="6"
      py="9"
      _light={{ bg: 'white' }}
      _dark={{ bg: 'coolGray.900' }}
      space="3"
      justifyContent="space-between"
      borderTopRightRadius={{ base: '2xl', md: 'xl' }}
      borderBottomRightRadius={{ base: '0', md: 'xl' }}
      borderTopLeftRadius={{ base: '2xl', md: '0' }}>
      <AnimateAndRender>
        <VStack space="7">
          <VStack>
            <Text bold _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="xl">
              Confirm Email Address
            </Text>
            <Text _light={{ color: 'base.700' }} _dark={{ color: 'primary.100' }} fontSize="sm" my="2">
              Please enter the code sent to you mailbox. Check your spam folder if you did not receive an email.
            </Text>
          </VStack>
          <VStack>
            <VStack space="3">
              <VStack space={{ base: '7', md: '4' }}>
                <Input
                  onChange={(t) => setToken(t.target.value)}
                  size="md"
                  placeholder="Email code"
                  autoFocus
                  _dark={{
                    borderColor: 'coolGray.700',
                  }}
                  _light={{
                    borderColor: 'coolGray.300',
                  }}
                />
              </VStack>

              {error && (
                <Text fontWeight="bold" color="error.700">
                  <Icon as={Ionicons} size="md" name={'warning'} color="error.700" />
                  {error}
                </Text>
              )}

              <Button
                mt="5"
                size="md"
                borderRadius="4"
                isLoading={loading}
                isLoadingText="Verifying"
                _text={{
                  fontWeight: 'medium',
                }}
                isDisabled={token.length < 6}
                onPress={(_data) => onSubmit()}>
                Verify
              </Button>
              <HStack mt="5" space="2" mb={{ base: 6, md: 7 }} alignItems="center" justifyContent="center">
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
                <Text fontWeight="medium" _light={{ color: 'coolGray.300' }} _dark={{ color: 'coolGray.500' }}>
                  or
                </Text>
                <Divider w="30%" _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }}></Divider>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
        <HStack mb="4" safeAreaBottom alignItems="center" justifyContent="center" mt={{ base: 'auto', md: '8' }}>
          <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }}>
            Already have an account?
          </Text>
          <Button
            paddingLeft="1"
            variant="link"
            _text={{
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            _light={{
              _text: {
                color: 'primary.900',
              },
            }}
            _dark={{
              _text: {
                color: 'primary.500',
              },
            }}
            _hover={{
              _text: {
                color: 'warning.600',
              },
            }}
            onPress={() => props.onChangeAction(GuestAction.sign_in)}>
            Sign in
          </Button>
        </HStack>
      </AnimateAndRender>
    </VStack>
  )
}
