import React from 'react'

type IconProps = React.PropsWithChildren<React.SVGProps<SVGSVGElement>>

export const Home = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.305 17.598H3.75V10.25H1L10.152 2l9.153 8.25h-2.75v7.348H12v-5.5H8.305v5.5z" fill="currentColor" />
  </svg>
)

export const POS = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.58 6.8a5.32 5.32 0 0 1-5.61 1.23A5.32 5.32 0 0 1 16.8 1.2c.7 1.9.29 4.1-1.23 5.62ZM11.2 15.58a5.32 5.32 0 0 0 5.6 1.23 5.32 5.32 0 0 0-6.83-6.84c-.7 1.89-.3 4.1 1.22 5.61ZM1.2 16.8a5.32 5.32 0 0 0 6.83-6.83A5.32 5.32 0 0 0 1.2 16.8ZM2.42 6.8a5.32 5.32 0 0 0 5.61 1.23A5.32 5.32 0 0 0 1.2 1.2C.5 3.1.9 5.3 2.42 6.81Z"
      fill="currentColor"
    />
  </svg>
)

export const Menu = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.5 17C3.0875 17 2.73437 16.8531 2.44062 16.5594C2.14687 16.2656 2 15.9125 2 15.5V4.5C2 4.0875 2.14687 3.73438 2.44062 3.44063C2.73437 3.14688 3.0875 3 3.5 3H7.0625C7.17361 2.56944 7.40625 2.21181 7.76042 1.92708C8.11458 1.64236 8.52778 1.5 9 1.5C9.47222 1.5 9.88542 1.64236 10.2396 1.92708C10.5938 2.21181 10.8264 2.56944 10.9375 3H14.5C14.9125 3 15.2656 3.14688 15.5594 3.44063C15.8531 3.73438 16 4.0875 16 4.5V15.5C16 15.9125 15.8531 16.2656 15.5594 16.5594C15.2656 16.8531 14.9125 17 14.5 17H3.5ZM3.5 15.5H14.5V4.5H3.5V15.5ZM5 14H11V12.5H5V14ZM5 10.75H13V9.25H5V10.75ZM5 7.5H13V6H5V7.5ZM9 4C9.14444 4 9.26389 3.95278 9.35833 3.85833C9.45278 3.76389 9.5 3.64444 9.5 3.5C9.5 3.35556 9.45278 3.23611 9.35833 3.14167C9.26389 3.04722 9.14444 3 9 3C8.85556 3 8.73611 3.04722 8.64167 3.14167C8.54722 3.23611 8.5 3.35556 8.5 3.5C8.5 3.64444 8.54722 3.76389 8.64167 3.85833C8.73611 3.95278 8.85556 4 9 4Z"
      fill="currentColor"
    />
  </svg>
)

export const Category = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.99987 4L12.6999 6.7L9.99987 9.4L7.29987 6.7L9.99987 4Z" fill="currentColor" />
    <path d="M6.7 7.30002L9.4 10L6.7 12.7L4 10L6.7 7.30002Z" fill="currentColor" />
    <path d="M13.3 7.30002L16 10L13.3 12.7L10.6 10L13.3 7.30002Z" fill="currentColor" />
    <path d="M9.99987 10.6L12.6999 13.3L9.99987 16L7.29987 13.3L9.99987 10.6Z" fill="currentColor" />
  </svg>
)
export const Item = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.94975 5L14.8995 9.94975L9.94975 14.8995L5 9.94975L9.94975 5Z" fill="currentColor" />
  </svg>
)

export const ItemSize = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 6.66667C10.1772 6.66667 10.3256 6.60278 10.4455 6.475C10.5653 6.34722 10.6252 6.18889 10.6252 6C10.6252 5.81111 10.5653 5.65278 10.4455 5.525C10.3256 5.39722 10.1772 5.33333 10 5.33333C9.82285 5.33333 9.67435 5.39722 9.55452 5.525C9.43468 5.65278 9.37476 5.81111 9.37476 6C9.37476 6.18889 9.43468 6.34722 9.55452 6.475C9.67435 6.60278 9.82285 6.66667 10 6.66667ZM11.7663 6.66667H12.8605C13.1731 6.66667 13.444 6.77778 13.6733 7C13.9025 7.22222 14.0432 7.49444 14.0953 7.81667L14.9863 14.4833C15.0384 14.8833 14.942 15.2361 14.6971 15.5417C14.4522 15.8472 14.137 16 13.7514 16H6.24856C5.863 16 5.54777 15.8472 5.30288 15.5417C5.058 15.2361 4.96161 14.8833 5.01371 14.4833L5.90468 7.81667C5.95678 7.49444 6.09746 7.22222 6.32672 7C6.55597 6.77778 6.82691 6.66667 7.13953 6.66667H8.2337C8.20244 6.55556 8.17638 6.44722 8.15554 6.34167C8.1347 6.23611 8.12428 6.12222 8.12428 6C8.12428 5.44444 8.30664 4.97222 8.67137 4.58333C9.03609 4.19444 9.47897 4 10 4C10.521 4 10.9639 4.19444 11.3286 4.58333C11.6934 4.97222 11.8757 5.44444 11.8757 6C11.8757 6.12222 11.8653 6.23611 11.8445 6.34167C11.8236 6.44722 11.7976 6.55556 11.7663 6.66667Z"
      fill="currentColor"
    />
  </svg>
)

export const ModifierGroup = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.94516 8.15651C7.73574 8.15777 6.7939 7.20662 6.79267 6.08045C6.79144 4.95433 7.73114 4.00126 8.94052 4C10.1499 3.99874 11.0918 4.9499 11.093 6.07606C11.0942 7.20218 10.1545 8.15525 8.94516 8.15651ZM13.8522 14.3079C12.6427 14.3092 11.7009 13.358 11.6997 12.2318C11.6984 11.1057 12.6381 10.1527 13.8475 10.1514C15.0569 10.1501 15.9988 11.1013 16 12.2275C16.0012 13.3536 15.0615 14.3066 13.8522 14.3079ZM6.15249 15C4.94307 15.0013 4.00123 14.0501 4 12.9239C3.99877 11.7978 4.93847 10.8448 6.14785 10.8435C7.35727 10.8422 8.29911 11.7934 8.30034 12.9195C8.30157 14.0457 7.36187 14.9987 6.15249 15Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export const ModifierIngredient = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5 9.5C12.5 11.1569 11.1569 12.5 9.5 12.5C7.84315 12.5 6.5 11.1569 6.5 9.5C6.5 7.84315 7.84315 6.5 9.5 6.5C11.1569 6.5 12.5 7.84315 12.5 9.5Z"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
)

export const Link = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M13.516 6c.964 0 1.786.399 2.465 1.196C16.661 7.97 17 8.903 17 10c0 1.097-.34 2.044-1.019 2.841-.679.773-1.5 1.159-2.465 1.159H10.69v-1.533h2.826c.592 0 1.096-.237 1.512-.71A2.576 2.576 0 0 0 15.685 10c0-.673-.219-1.246-.657-1.72-.416-.498-.92-.747-1.512-.747H10.69V6h2.826Zm-6.31 4.785v-1.57h5.587v1.57H7.207ZM4.94 8.28c-.416.474-.624 1.047-.624 1.72s.208 1.259.624 1.757c.438.473.953.71 1.545.71H9.31V14H6.484c-.964 0-1.786-.386-2.465-1.159C3.339 12.044 3 11.097 3 10c0-1.097.34-2.031 1.019-2.804C4.698 6.4 5.519 6 6.484 6H9.31v1.533H6.484c-.592 0-1.107.249-1.545.747Z"
    />
  </svg>
)

export const PausedIcon = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="28" height="28" rx="14" fill="#DC2626" />
    <path d="M16.0156 7.01562H20V20.9844H16.0156V7.01562ZM8 20.9844V7.01562H11.9844V20.9844H8Z" fill="white" />
  </svg>
)

const Collapsed = () => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 3.50001L2 0V7L6 3.50001Z" fill="#9CA3AF" />
  </svg>
)

const Expanded = () => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.49999 6L7 2L0 2L3.49999 6Z" fill="#9CA3AF" />
  </svg>
)
