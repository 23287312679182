import React from 'react'
import { Button, Stack, Text } from 'native-base'
import { Link } from '../../router'

interface Item {
  path?: string
  label: string
}

type Props = React.ComponentProps<typeof Stack>

export function Item({ path, label }: Item) {
  if (!path) {
    return (
      <Text
        _light={{ color: 'base.500' }}
        _dark={{ color: 'base.400' }}
        fontWeight={'bold'}
        fontSize={'xs'}
        px="2"
        py="1">
        {label}
      </Text>
    )
  }
  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Button
        variant="ghost"
        colorScheme="base"
        px="2"
        py="1"
        _light={{ _text: { fontSize: 'xs', color: 'base.600', fontWeight: 'bold' } }}
        _dark={{ _text: { fontSize: 'xs', color: 'base.300', fontWeight: 'bold' } }}>
        {label}
      </Button>
    </Link>
  )
}

export default function Breadcrumb(props: Props): JSX.Element {
  return <Stack direction="row" alignItems={'center'} divider={<Text>/</Text>} {...props} />
}

Breadcrumb.Item = Item
