import React from 'react'
import useWindowDimensions from '../hooks/use-window-dimensions'
import { DimensionsCtx } from '../context'

export default function DimensionsProvider({
  children
}: {
  children: React.ReactNode
}) {
  const dimensions = useWindowDimensions();

  return (
    <DimensionsCtx.Provider value={dimensions}>
      { children }
    </DimensionsCtx.Provider>
  )
};