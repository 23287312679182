import React from 'react'

//type ReactChildArray = ReturnType<typeof React.Children.toArray>
type ReactChildExceptFragment =
  | string
  | number
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactPortal

export function flattenChildren(children: React.ReactNode): ReactChildExceptFragment[] {
  const childrenArray = React.Children.toArray(children)
  return childrenArray.reduce((flatChildren: ReactChildExceptFragment[], child) => {
    if ((child as React.ReactElement<any>).type === React.Fragment) {
      return flatChildren.concat(flattenChildren((child as React.ReactElement<any>).props.children))
    }
    flatChildren.push(child as ReactChildExceptFragment)
    return flatChildren
  }, [] as any)
}
