import { IBoxProps, IPressableProps } from 'native-base'
import React, { useState, Fragment, useRef } from 'react'
import { Box, Pressable } from 'ui'

type NodeType =
  | 'ItemOption'
  | 'ItemModifier'
  | 'ItemSize'
  | 'Item'
  | 'MenuItem'
  | 'Category'
  | 'Menu'
  | 'Root'
  | 'MenuItemPivot'
  | 'ModifierWithSizes'
  | 'POS'
  | 'Library'

export type Node<T> = T & {
  __typename: NodeType
  id: string | number
  children?: Node<T>[]
  isExpanded?: boolean
  active?: boolean
}

export interface PathPart {
  id: string | number
  __typename?: NodeType
}

interface TreeViewProps<T> {
  data: Node<T>[]
  expanded?: boolean
  renderNode: (
    node: Node<T>,
    path: PathPart[],
    expanded: boolean,
    isLast: boolean,
    isHovered: boolean,
    onToggle: () => void
  ) => React.ReactElement | null
  onPress: (node: Node<T>, path: PathPart[], expanded: boolean) => Promise<any>
  getNodeChildren?: (node: Node<T>) => Node<T>[]
  isNodeExpanded?: (node: Node<T>) => boolean | undefined
  _holder?: IBoxProps
  _pressable?: IPressableProps
}

function getNodeChildrenDefault<T>(node: Node<T>) {
  return node.children || []
}

function isNodeExpandedDefault<T>(node: Node<T>) {
  return node.isExpanded
}

export default function TreeView<T>({
  data,
  expanded = true,
  renderNode,
  onPress,
  getNodeChildren = getNodeChildrenDefault,
  isNodeExpanded = isNodeExpandedDefault,
  _holder = {},
  _pressable = {},
}: TreeViewProps<T>) {
  const [visibility, setVisibility] = useState<any>({})
  const lastId = useRef<string | number | null>(null)

  const isExpandedNode = (node: Node<T>) => visibility[node.id] ?? isNodeExpanded(node) ?? expanded

  const toggleNode = (node: Node<T>) => {
    const isExpanded = isExpandedNode(node)
    setVisibility({ ...visibility, [node.id]: !isExpanded })
  }

  const onNodePress = async (node: Node<T>, path: any) => {
    const resp = await onPress(node, path, isExpandedNode(node))
    //TODO: maybe do something with the response?
    const hasChildren = getNodeChildren(node).length > 0
    if (hasChildren && lastId.current === (node.__typename || '') + node.id) {
      toggleNode(node)
    } else if (!isExpandedNode(node)) {
      toggleNode(node)
    }
    lastId.current = (node.__typename || '') + node.id
  }

  const onToggle = (node: Node<T>) => () => {
    toggleNode(node)
  }

  const renderNodeInternal = (nodes: Node<T>[], path: PathPart[]) => {
    return nodes.map((node, i) => {
      const isExpanded = isExpandedNode(node)
      const nodeChildren = getNodeChildren(node)
      const shouldRenderChildren = nodeChildren && nodeChildren.length > 0 && isExpanded
      const isLast = i === nodes.length - 1
      const newPath = [...path, { id: node.id, __typename: node.__typename }]
      return (
        <Fragment key={node.id}>
          <Pressable
            onPress={() => {
              onNodePress(node, newPath)
            }}
            testID={`tree-nav-button`}
            {..._pressable}
            style={{
              // @ts-ignore
              cursor: 'pointer',
            }}>
            {({ isHovered }) => renderNode(node, newPath, isExpanded, isLast, isHovered, onToggle(node))}
          </Pressable>
          {shouldRenderChildren && renderNodeInternal(nodeChildren, newPath)}
        </Fragment>
      )
    })
  }

  return <Box {..._holder}>{renderNodeInternal(data, [])}</Box>
}

export { default as TreeButton } from './tree-button'
