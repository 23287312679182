import React, { useCallback, useContext, useEffect } from 'react'
import { PhoenixSocketContext } from './phoenix-channel'
import { QueryClient, QueryKey } from '@tanstack/react-query'

const ACTION = 'shout'

interface QuerySyncAPI {
  invalidateKeys: (keys: QueryKey[]) => void
}

const QuerySyncCtx = React.createContext<QuerySyncAPI>({ invalidateKeys: () => {} })

export function QuerySyncService({ queryClient, children }: React.PropsWithChildren<{ queryClient: QueryClient }>) {
  const { channel } = useContext(PhoenixSocketContext)

  useEffect(() => {
    if (!channel) return

    channel.on(ACTION, (payload) => {
      if (payload.invalidateKeys) {
        payload.invalidateKeys.forEach((queryKey: QueryKey) => {
          queryClient.invalidateQueries({ queryKey })
        })
      }
    })

    const mutationCache = queryClient.getMutationCache()

    const unsubscribeFromMutationCache = mutationCache.subscribe((event) => {
      // console.log('mutation success', event)
      if (event.type === 'updated' && event.mutation?.state.status === 'success') {
        if (event.mutation.options.meta?.invalidateKeys) {
          const allKeys = event.mutation.options.meta.invalidateKeys as QueryKey[]

          channel.push(ACTION, { invalidateKeys: allKeys })
        }
      }
    })

    return () => {
      unsubscribeFromMutationCache()
      channel.off(ACTION)
    }
  }, [channel])

  const invalidateKeys = useCallback(
    (keys: QueryKey[]) => {
      if (channel) channel.push(ACTION, { invalidateKeys: keys })
      keys.forEach((queryKey: QueryKey) => {
        queryClient.invalidateQueries({ queryKey })
      })
    },
    [channel]
  )

  return <QuerySyncCtx.Provider value={{ invalidateKeys }}>{children}</QuerySyncCtx.Provider>
}

export function useQuerySync() {
  return useContext(QuerySyncCtx)
}
