import { Remark } from 'react-remark'
import './markdown-styles.css'

export default function MarkDown({ children, ...props }: React.PropsWithChildren<any>) {
  return (
    <div className="markdown" {...props}>
      <Remark>{children}</Remark>
    </div>
  )
}
