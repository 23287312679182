const palette = {
  "gray100":   "#F7FAFC",
  "gray200":   "#EDF2F7",
  "gray300":   "#E2E8F0",
  "gray400":   "#CBD5E0",
  "gray500":   "#A0AEC0",
  "gray600":   "#718096",
  "gray700":   "#4A5568",
  "gray800":   "#2D3748",
  "gray900":   "#1A202C",

  "red100":    "#FFF5F5",
  "red200":    "#FED7D7",
  "red300":    "#FEB2B2",
  "red400":    "#FC8181",
  "red500":    "#F56565",
  "red600":    "#E53E3E",
  "red700":    "#C53030",
  "red800":    "#9B2C2C",
  "red900":    "#742A2A",

  "orange100": "#FFFAF0",
  "orange200": "#FEEBC8",
  "orange300": "#FBD38D",
  "orange400": "#F6AD55",
  "orange500": "#ED8936",
  "orange600": "#DD6B20",
  "orange700": "#C05621",
  "orange800": "#9C4221",
  "orange900": "#7B341E",

  "yellow100": "#FFFFF0",
  "yellow200": "#FEFCBF",
  "yellow300": "#FAF089",
  "yellow400": "#F6E05E",
  "yellow500": "#ECC94B",
  "yellow600": "#D69E2E",
  "yellow700": "#B7791F",
  "yellow800": "#975A16",
  "yellow900": "#744210",

  "green100":  "#F0FFF4",
  "green200":  "#C6F6D5",
  "green300":  "#9AE6B4",
  "green400":  "#68D391",
  "green500":  "#48BB78",
  "green600":  "#38A169",
  "green700":  "#2F855A",
  "green800":  "#276749",
  "green900":  "#22543D",

  "teal100":   "#E6FFFA",
  "teal200":   "#B2F5EA",
  "teal300":   "#81E6D9",
  "teal400":   "#4FD1C5",
  "teal500":   "#38B2AC",
  "teal600":   "#319795",
  "teal700":   "#2C7A7B",
  "teal800":   "#285E61",
  "teal900":   "#234E52",

  "blue100":   "#EBF8FF",
  "blue200":   "#BEE3F8",
  "blue300":   "#90CDF4",
  "blue400":   "#63B3ED",
  "blue500":   "#4299E1",
  "blue600":   "#3182CE",
  "blue700":   "#2B6CB0",
  "blue800":   "#2C5282",
  "blue900":   "#2A4365",

  "indigo100": "#EBF4FF",
  "indigo200": "#C3DAFE",
  "indigo300": "#A3BFFA",
  "indigo400": "#7F9CF5",
  "indigo500": "#667EEA",
  "indigo600": "#5A67D8",
  "indigo700": "#4C51BF",
  "indigo800": "#434190",
  "indigo900": "#3C366B",

  "purple100": "#FAF5FF",
  "purple200": "#E9D8FD",
  "purple300": "#D6BCFA",
  "purple400": "#B794F4",
  "purple500": "#9F7AEA",
  "purple600": "#805AD5",
  "purple700": "#6B46C1",
  "purple800": "#553C9A",
  "purple900": "#44337A",

  "pink100":   "#FFF5F7",
  "pink200":   "#FED7E2",
  "pink300":   "#FBB6CE",
  "pink400":   "#F687B3",
  "pink500":   "#ED64A6",
  "pink600":   "#D53F8C",
  "pink700":   "#B83280",
  "pink800":   "#97266D",
  "pink900":   "#702459",
  
}

export const palette2 = { 
  rose: {
    50:  '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
  },
  pink: {
    50:  '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
  },
  fuchsia: {
    50:  '#fdf4ff',
    100: '#fae8ff',
    200: '#f5d0fe',
    300: '#f0abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#c026d3',
    700: '#a21caf',
    800: '#86198f',
    900: '#701a75',
  },
  purple: {
    50:  '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
  },
  violet: {
    50:  '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
  },
  indigo: {
    50:  '#EEF2FF',
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
    800: '#3730A3',
    900: '#312E81',
  },
  blue: {
    50:  '#EFF6FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3B82F6',
    600: '#2563EB',
    700: '#1D4ED8',
    800: '#1E40AF',
    900: '#1E3A8A',
  },
  lightBlue: {
    50:  '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
  },
  cyan: {
    50:  '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63',
  },
  teal: {
    50:  '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a',
  },
  emerald: {
    50:  '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
  },
  green: {
    50:  '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
  },
  lime: {
    50:  '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314',
  },
  yellow: {
    50:  '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#EAB308',
    600: '#CA8A04',
    700: '#A16207',
    800: '#854D0E',
    900: '#713F12',
  },
  amber: {
    50:  '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
  },
  orange: {
    50:  '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
  },
  red: {
    50:  '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  warmGray: {
    50:  '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
  },
  trueGray: {
    50:  '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  gray: {
    50:  '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    800: '#27272a',
    900: '#18181b',
  },
  coolGray: {
    50:  '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
  },
  blueGray: {
    50:  '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
  }
}

export const Palette2 = typeof palette2
export type Palette = typeof palette
export default palette